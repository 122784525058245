import { MasterApi } from "src/api";
import { notify } from "src/components/notify";
import { setLoading, setStep } from "src/features/main/mainSlice";

export const cheeckFirstPayment =
  () => async (dispatch: any, getState: any) => {
    const res = await MasterApi({
      method: "get",
      url: "/payment/first",
      data: {},
    });
    if (res?.status?.code === 200) {
      // todo: delete
      // dispatch(setStep("access"));
      // return;
      // end;
      if (res?.data?.is_first_payment_done) {
        dispatch(setStep("access"));
      } else {
        dispatch(setStep("needChargeWallet"));
      }
    }
  };
export const activePayment =
  (callback: Function) => async (dispatch: any, getState: any) => {
    const res = await MasterApi({
      method: "get",
      url: "/payment",
      data: {},
    });
    if (res?.status?.code === 200) {
      callback && callback([res?.data] || []);
    }
  };
export const getTransaction = () => async (dispatch: any, getState: any) => {
  const res = await MasterApi({
    method: "get",
    url: "/payment",
    data: {},
  });
};
export const setPayment =
  (payment_uuid: number) => async (dispatch: any, getState: any) => {
    const res = await MasterApi({
      method: "post",
      url: "/payment",
      data: {
        payment_uuid,
        callback_url: "/",
        is_panel: true,
      },
    });
    dispatch(setLoading(false));
    if (res?.status?.code === 200) {
      window.location.replace(`${res?.data?.url}`);
      // dispatch(setStep("access"));
    }
  };

export const createPayment =
  (amount: number) => async (dispatch: any, getState: any) => {
    var formData = new FormData();
    formData.append("amount", amount.toString());

    const res = await MasterApi({
      method: "post",
      url: "/payment/create",
      // data: { amount },
      data: formData,
    });
    if (res && res?.status?.code === 200 && res?.data?.uuid) {
      dispatch(setPayment(res?.data?.uuid));
    } else {
      dispatch(setLoading(false));
      if (res?.status?.message) {
        notify.error(res?.status?.message);
      }
    }
  };
