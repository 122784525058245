import { MasterApi } from "src/api";
import {
  setBagMoneyList,
  setTransactionsList,
  setBagMoneyMeta,
  setTransactionsMeta,
} from "src/features/main/wallet/transactionsSlice";

export const getTransactions = () => async (dispatch, getState) => {
  const params = {
    params: { limit: 10, page: 1 },
  };
  const res = await MasterApi({
    method: "get",
    url: "/payment/list",
    data: params,
  });
  if (res?.status?.code === 200) {
    dispatch(setTransactionsList(res?.data?.data));
    dispatch(setTransactionsMeta(res?.data?.meta));
  }
};
export const getAuthorityTransaction = () => async (dispatch, getState) => {
  const params = {
    params: { authority: "3b5bb216-0b55-45e0-9f25-159de568c21e" },
  };
  const res = await MasterApi({
    method: "get",
    url: "/payment/transaction/",
    data: params,
  });
};
export const getPaymentTransactions =
  (payment_uuid) => async (dispatch, getState) => {
    const params = {
      params: { payment_uuid: payment_uuid, limit: 10, page: 1 },
    };
    const res = await MasterApi({
      method: "get",
      url: "payment/transactions",
      data: params,
    });
    const test = {
      status: { code: 200, message: "موفقیت آمیز" },
      data: {
        data: [
          {
            amount: 50000,
            uuid: "702228cf-fdb5-403c-b710-9cbfd2343cdb",
            created_at: "2023-04-10T15:28:24.000Z",
            status: "2",
            status_text: "paid",
          },
        ],
        meta: {
          total: 1,
          total_pages: 1,
          current_page: 1,
          limit: 10,
          has_prev_page: false,
          has_next_page: false,
          prev_page: null,
          next_page: null,
        },
      },
    };
  };
export const getBagMoneyLog =
  ({ direction, page }) =>
  async (dispatch, getState) => {
    const params = {
      params: { limit: 10, page, direction },
    };
    const res = await MasterApi({
      method: "get",
      url: "payment/bag-money/logs",
      data: params,
    });
    if (res?.status?.code === 200) {
      dispatch(setBagMoneyList(res?.data?.data));
      dispatch(setBagMoneyMeta(res?.data?.meta));
    }
  };
