import React, { useState } from "react";
import Modal from "./Modal";
import IconGif from "../../assets/gif/payment_modal.gif";
import { CircleInfoIcon } from "src/assets/icons";
import Num2persian from "num2persian";
import { useAppDispatch } from "src/app/hooks";
import { createPayment } from "src/controllers/WalletController";
import { selLoading, setLoading } from "src/features/main/mainSlice";
import { useSelector } from "react-redux";
import { Loading3dot } from "../loading/btnLoading";

const priceList: number[] = [50000, 200000, 500000];
export const ChargeWalletModal = (props: any) => {
  const { close } = props || {};
  const dispatch = useAppDispatch();
  const [price, setPrice] = useState<number>(priceList[0]);
  const loading = useSelector(selLoading);
  const handleChangePrice = (e: any) => {
    try {
      var dollarsAsFloat = parseFloat(
        e?.target?.value?.replace(/[^0-9-.]/g, "")
      );
      setPrice(dollarsAsFloat || 0);
    } catch (error) {
      console.error("😏 => error:", error);
    }
  };
  const handleSubmit = () => {
    dispatch(setLoading(true));
    dispatch(createPayment(price));
  };
  return (
    <Modal close={close}>
      <div className="py-6 px-20 text-center">
        <div className="w-32 h-40 m-auto">
          <video className="w-full h-full" autoPlay loop>
            <source src={IconGif} type="video/mp4" />
          </video>
        </div>
        <div className="mb-4 text-3xl font-bold text-indigo-900 mt-8">
          افزایش موجودی
        </div>
        <div className="flex items-center justify-center text-center mt-4">
          <CircleInfoIcon color={"#F89B2B"} />
          <div className="mx-2 text-zinc-600">
            جهت استفاده از تمام امکانات پنل، اعتبار کیف پول را افزایش دهید.
          </div>
        </div>
        <div className="">
          <div className="mt-8 flex items-center justify-center bg-gray-50 border border-gray-300 text-gray-900 text-2xl text-center rounded-lg w-full">
            <input
              id="price"
              name="price"
              type="text"
              className="grow bg-gray-50 p-2 rounded-lg text-center w-1/2 select-none focus:outline-none"
              value={price?.toLocaleString()}
              onChange={handleChangePrice}
            />
            <div className="bg-gray-200 w-0.5 h-10"></div>
            <div className="text-gray-500 text-sm mx-4">تومان</div>
          </div>
          {price ? (
            <div className="text-gray-700 text-center mt-4 max-w-sm">
              {Num2persian(price)} تومان
            </div>
          ) : (
            <div className="text-blue-900 text-center mt-4">
              مبلغ وارد شده به صورت دلخواه و بدون محدودیت می‌باشد.
            </div>
          )}
          <div className="flex items-center justify-center mt-4">
            {priceList?.map((item: any, index: number) => {
              const isSelect = price === item;
              return (
                <div
                  key={`item-price-${item}-${index}`}
                  className={`w-full mx-1 col-span-2 select-none rounded-lg text-center border py-1 cursor-pointer hover:bg-gray-50 ${
                    isSelect ? "bg-sky-50 text-sky-800 border-sky-500" : ""
                  }`}
                  onClick={() => setPrice(item)}
                >
                  {`${item?.toLocaleString() || 0} تومان`}
                </div>
              );
            })}
          </div>
          <button
            onClick={handleSubmit}
            className={`flex justify-center items-center m-auto w-2/3 mt-8 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-3 text-center ${
              price > 0
                ? "bg-indigo-800 hover:bg-indigo-900 focus:ring-blue-300 text-white"
                : "bg-gray-300 cursor-default text-gray-600"
            }`}
          >
            <div className="mx-2">پرداخت</div>
            {loading && <Loading3dot />}
          </button>
          <div className="text-center mt-6 cursor-pointer">نمایش تراکنش ها</div>
        </div>
      </div>
    </Modal>
  );
};
