import { setProfile } from "src/features/profile/storeSlice";
//{ lat: 35.68198003744061, lng: 51.33644946071224 }
export const getAddressByCoordinate =
  (positions: any) => async (dispatch: any, getState: any) => {
    if (positions.lat && positions.lng) {
      const res: any = await fetch(
        `https://api.parsimap.ir/geocode/reverse?key=p1e35220f589044556a90a0971ec5a8fe14d45670d&location=${positions.lng},${positions.lat}&local_address=false&approx_address=false&subdivision=false&plate=false&request_id=false`
      ).then((rr: any) => rr.text());
      const objResult = await JSON.parse(res);
      dispatch(
        setProfile({
          province_name: objResult.subdivision_prefix,
          address: objResult.address,
        })
      );
    }
  };
