import React, { useEffect, useState } from "react";

export const Checkbox = (props: any) => {
  const {
    name = "defaultCheckbox",
    checked = false,
    bgColor = "#238FF3",
    borderColor = "#5E6C84",
    onChange,
    size = 24,
    readonly = false,
  } = props;
  const [isChecked, setIsChecked] = useState<boolean>();
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);
  const onClick = (isChecked: boolean) => {
    if (readonly) return;
    setIsChecked(isChecked);
    onChange(name, isChecked);
  };

  return (
    <div className={readonly ? "" : "cursor-pointer"}>
      {isChecked ? (
        <svg
          width={size}
          height={size}
          fill="currentColor"
          viewBox="0 0 22 22"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => onClick(false)}
        >
          <rect fill="none" width="24" height="24"></rect>
          <path
            fill={readonly ? "#D1D5DB" : bgColor}
            fillRule="evenodd"
            d="M5.67,0h8.67C17.73,0,20,2.38,20,5.92v8.171C20,17.62,17.73,20,14.34,20H5.67C2.28,20,0,17.62,0,14.091V5.92C0,2.38,2.28,0,5.67,0ZM9.43,12.99l4.75-4.75A.877.877,0,1,0,12.94,7L8.81,11.13,7.06,9.38a.877.877,0,0,0-1.24,1.24L8.2,12.99a.881.881,0,0,0,1.23,0Z"
            transform="translate(2 2)"
          ></path>
        </svg>
      ) : (
        <svg
          width={size}
          height={size}
          fill="currentColor"
          viewBox="0 0 22 22"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => onClick(true)}
        >
          <rect fill="none" width="24" height="24"></rect>
          <path
            fill={readonly ? "#D1D5DB" : borderColor}
            fillRule="evenodd"
            d="M16.334,2H7.665C4.268,2,2,4.433,2,7.916v8.168C2,19.571,4.262,22,7.665,22h8.668C19.738,22,22,19.571,22,16.084V7.916C22,4.43,19.738,2,16.334,2ZM7.665,3.5h8.669c2.55,0,4.166,1.735,4.166,4.416v8.168c0,2.681-1.616,4.416-4.167,4.416H7.665c-2.55,0-4.165-1.735-4.165-4.416V7.916C3.5,5.239,5.121,3.5,7.665,3.5Z"
            transform="translate(0 0)"
          ></path>
        </svg>
      )}
    </div>
  );
};
