import { MasterApi } from "src/api";
import { notify } from "src/components/notify";
import { setMyStoreSettings } from "src/features/myStore/MyStoreSlice";

export const changeStoreStatus =
  (storeStatusId: number) => async (dispatch: any, getState: any) => {
    const res = await MasterApi({
      method: "PATCH",
      url: `/store/status?status=${storeStatusId}`,
      data: {
        // params: { status: storeStatusId },
      },
    });
    if (res?.status?.code === 200) {
      notify.info(res?.status?.message);
    }
  };
export const getStoreSetting = () => async (dispatch: any, getState: any) => {
  const res = await MasterApi({
    method: "get",
    url: "/store/my-store/shipping",
    data: {},
  });

  if (res?.status?.code === 200) {
    dispatch(setMyStoreSettings(res?.data));
  }
};
// todo: fake coordinates
// [
//     [51.35664148346402, 35.70875858869336],
//     [51.362306308902475, 35.69523680003519],
//     [51.338617038883456, 35.69098462972369],
//     [51.33458299652523, 35.705413205007616],
//     [51.35664148346402, 35.70875858869336],
// ],
export const putStoreSetting =
  (data: any) => async (dispatch: any, getState: any) => {
    const res = await MasterApi({
      method: "put",
      url: "/store/my-store/shipping",
      data: { ...data },
    });
    if (res?.status?.code === 200) {
      notify.def(res?.status?.message);
    }
  };

export const resetAccountPassword = (data) => async (dispatch, getState) => {
  const res = await MasterApi({
    method: "post",
    url: "/payment/seller/create",
    data: { ...data },
  });
  if (res?.status?.code === 200) {
    notify.info("رمز عبور با موفقیت تغییر کرد");
  }
};
