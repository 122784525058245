import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/hooks";
import {
  getDeliveryBikes,
  selectDeliveryBike,
} from "src/controllers/deliveryController";
import Modal from "./Modal";
import { selectDeliveryBikesList } from "src/features/main/delivery/deliveryBikesSlice";
import DropdownWithIcon from "../dropdown/DropdownWithIcon";
import { useNavigate } from "react-router-dom";
import { AvatarIcon } from "src/assets/icons";
import { SocketContext } from "src/api/socketContext";
import { updateNotificatoin } from "src/features/main/notificationSlice";

export const SelectDeliveryModal = (props: any) => {
  const { close, data } = props || {};
  const dispatch = useAppDispatch();
  const BikesList = useSelector(selectDeliveryBikesList);
  const navigate: any = useNavigate();
  const [selectBike, setSelectBike] = useState<any>();
  const ws: any = useContext(SocketContext);

  useEffect(() => {
    dispatch(getDeliveryBikes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChangeBike = (e: any) => {
    setSelectBike(e);
  };
  const handleSubmit = () => {
    if (selectBike?.uuid === undefined) return;
    dispatch(
      selectDeliveryBike(
        {
          invoice_uuid: data.uuid,
          bike_identification: selectBike?.uuid,
        },
        afterSubmit
      )
    );
  };
  const afterSubmit = (res: boolean) => {
    if (res) {
      close();
      ws.sendMessage("send_invoice_with_status", 1, 1000, 6);
      dispatch(updateNotificatoin({ key: "sending", value: 1 }));
    }
  };
  const STATUS_STORE = BikesList.map((item) => ({
    uuid: item.bike_identification,
    name: `${item.first_name} ${item.last_name} - ${item.national_code}`,
    icon: AvatarIcon,
  }));
  const handleNavigate = (routeName: string) => {
    navigate(routeName || "/");
    close();
  };

  return (
    <Modal close={close}>
      <div className="text-center text-xl text-black-2 w-96">انتخاب پیک</div>
      <div className="text-gray-700 text-sm p-1 mt-4"></div>

      <div className="relative h-fit cursor-pointer w-full text-sm font-bold mt-3">
        <DropdownWithIcon
          name="state"
          label={"پیک را انتخاب کنید"}
          list={STATUS_STORE}
          select={selectBike}
          onclick={handleOnChangeBike}
          className=""
          FooterComponent={null}
        />
      </div>
      <div
        className="text-blue-700 p-3 flex justify-end items-center cursor-pointer w-full"
        onClick={() => handleNavigate("/delivery")}
      >
        <div className="pt-1 text-base">+</div>
        <div className="px-1">افزودن پیک</div>
      </div>
      <div
        onClick={handleSubmit}
        className="bg-green-500 px-6 py-2 text-center w-fit text-white text-lg rounded-lg mt-6 m-auto cursor-pointer hover:bg-green-600"
      >
        تایید پیک
      </div>
    </Modal>
  );
};
