import axios from "axios";
import { store } from "src/app/store";

export const ROOT_DOMAIN = process.env.REACT_APP_SERVER_URL;
export const BASE_URL = `${ROOT_DOMAIN}${process.env.REACT_APP_SERVER_URL_VERSION}`;
const defaultOptions = {
  baseURL: BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    // timeout: 240000,
  },
};
let instance = axios.create(defaultOptions);
instance.interceptors.request.use(function (config) {
  const { token } = store.getState().main;
  if (token) {
    config.headers.Authorization = token;
  }
  config.headers["Cache-Controll"] = "no-cache,private";
  return config;
});
export default instance;
