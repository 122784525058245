import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/hooks";
import { DropdownTopLabel } from "src/components/dropdown/DropdownTopLabel";
import {
  getStoreSetting,
  putStoreSetting,
} from "src/controllers/myStoreSettingsController";
import { selStoreSetting } from "./MyStoreSlice";
import { PolygonMap } from "src/components/maps/polygonMap";
import { SelectTimeComponent } from "./SelectTimeComponent";
import DInput from "src/components/input/input";
import { notify } from "src/components/notify";

export const StoreSetting = () => {
  // const [coordinates, setCoordinates] = useState([]);
  const [errorSlogan, setErrorSlogan] = useState(false);
  const [form, setForm] = useState<any>({
    expire_time_setting: "",
    prepared_time_setting: "",
    shipping_scope_setting: null,
    store_business_hours_setting: {
      is_24_hour_store: false,
      hours: [
        {
          start: "8:00",
          end: "22:00",
        },
      ],
    },
    store_slogan_setting: "",
    workTime: [],
    bike_delivery_cost_setting: 0,
  });

  const settings = useSelector(selStoreSetting);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getStoreSetting());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var newObj = {};
    settings.map((i) => {
      return Object.keys(i).map((key) => {
        return (newObj[key] = i[key]);
      });
    });
    setForm((prev: any) => ({ ...prev, ...newObj }));
  }, [settings]);

  const handleOnChangeWorkHours = (params: any) => {
    setForm((prev) => ({
      ...prev,
      workTime: params.map((i) => [
        i.from?.selectTimeIndex,
        i.to?.selectTimeIndex,
      ]),
    }));
  };
  const handleOnChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "store_slogan_setting" && value.length > 3) {
      setErrorSlogan(false);
    }
    setForm((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleOnChangeRadio = (checked: boolean) => {
    setForm((prev: any) => ({
      ...prev,
      store_business_hours_setting: {
        ...prev.store_business_hours_setting,
        is_24_hour_store: checked,
      },
    }));
  };
  const handleSubmit = () => {
    setErrorSlogan(false);
    if (form.store_slogan_setting.length < 3) {
      notify.warning("شعار فروشگاه باید بیشتر ااز 3 حرف باشد");
      setErrorSlogan(true);
      return;
    }
    function time_convert(num: number) {
      // const hours = Math.floor(num / 60);
      // const minutes = num % 60;
      // return `${hours}:${minutes}`;
      if (num < 10) {
        return `0${num}:00`;
      } else {
        return `${num}:00`;
      }
    }
    const params = {
      invoices_expired_time: form.expire_time_setting,
      invoices_prepared_time: form.prepared_time_setting,
      shipping_scopes: form.shipping_scope_setting,
      bike_delivery_cost: form.bike_delivery_cost_setting,
      business_hours: {
        is_24_hour_store: form.store_business_hours_setting.is_24_hour_store,
        hours: form.store_business_hours_setting.is_24_hour_store
          ? []
          : form?.workTime?.map((time: any) => ({
              start: time_convert(time[0]),
              end: time_convert(time[1]),
            })),
      },
      slogan: form.store_slogan_setting || "",
    };

    dispatch(putStoreSetting(params));
  };
  const handleOnChangeCoordinatesCallback = (coors: any) => {
    setForm((prev: any) => ({
      ...prev,
      shipping_scope_setting: coors || [],
    }));
  };
  const handleReset = () => {
    setForm((prev: any) => ({
      ...prev,
      shipping_scope_setting: null,
    }));
    // dispatch(putStoreSetting({ ...form, shipping_scopes: [] }));
  };
  const iSEnableSubmit = form?.shipping_scope_setting?.length > 2;

  return (
    <div>
      <div className="text-blue-900 py-4 px-2 text-xl font-bold">
        تنظیمات ارسال سفارش
      </div>
      <div className="flex">
        <div className="w-2/3 grid grid-cols-3 grid-rows-2 gap-4 mt-4">
          <div className="relative w-full rounded-lg border border-gray-300 flex items-center justify-center">
            <DropdownTopLabel
              name="expire_time_setting"
              label="مدت اعتبار فاکتورها"
              list={[
                { uuid: 15, name: "۱۵ دقیقه" },
                { uuid: 30, name: "۳۰ دقیقه" },
                { uuid: 45, name: "۴۵ دقیقه" },
                { uuid: 60, name: "۱ ساعت" },
                { uuid: 120, name: "۲ ساعت" },
              ]}
              selectId={form.expire_time_setting}
              onChange={handleOnChange}
            />
          </div>
          <div className="relative w-full rounded-lg border border-gray-300 flex items-center justify-center">
            <DropdownTopLabel
              name="prepared_time_setting"
              label={"مدت زمان ارسال"}
              list={[
                { uuid: 15, name: "۱۵ دقیقه" },
                { uuid: 30, name: "۳۰ دقیقه" },
                { uuid: 45, name: "۴۵ دقیقه" },
                { uuid: 60, name: "۱ ساعت" },
                { uuid: 120, name: "۲ ساعت" },
              ]}
              selectId={form.prepared_time_setting}
              onChange={handleOnChange}
            />
          </div>
          <div className="relative w-full rounded-lg border border-gray-300 flex items-center justify-center">
            <DInput
              name={"bike_delivery_cost_setting"}
              label={"هزینه پیک موتوری"}
              color={"gray"}
              onChange={handleOnChange}
              value={form.bike_delivery_cost_setting}
              type={"text"}
              className="w-full"
            />
            {/* <DropdownTopLabel
              name="bike_delivery_cost_setting"
              label={"هزینه پیک موتوری"}
              list={[
                { uuid: 0, name: "رایگان" },
                { uuid: 5000, name: "5,000 تومان" },
                { uuid: 7500, name: "7,500 تومان" },
                { uuid: 10000, name: "10,000 تومان" },
                { uuid: 15000, name: "15,000 تومان" },
              ]}
              selectId={form.bike_delivery_cost_setting}
              onChange={handleOnChange}
            /> */}
          </div>
          <div className="relative col-span-3 w-full rounded-lg flex items-center justify-center">
            <DInput
              name={"store_slogan_setting"}
              label={"شعار فروشگاه"}
              color={errorSlogan ? "red" : "gray"}
              onChange={handleOnChange}
              value={form.store_slogan_setting}
              type={"text"}
              className="w-full"
            />
          </div>

          <div className="col-span-3 h-96 bg-gray-100 rounded-xl">
            <PolygonMap
              data={form?.shipping_scope_setting}
              coordinatesCallback={handleOnChangeCoordinatesCallback}
            />
          </div>
          <div className="col-span-3 flex justify-end items-end">
            <div
              onClick={handleReset}
              className="w-44 bg-gray-200 hover:bg-gray-300 rounded-lg py-2 px-3 text-center mx-1 cursor-pointer"
            >
              انصراف
            </div>
            <div
              onClick={iSEnableSubmit ? handleSubmit : () => null}
              className={`w-44 text-white rounded-lg py-2 px-3 text-center mx-1 ${
                iSEnableSubmit
                  ? "bg-blue-800 hover:bg-blue-900 cursor-pointer"
                  : "bg-gray-500 cursor-not-allowed"
              }`}
            >
              ثبت
            </div>
          </div>
        </div>
        <div className="w-1/3 rounded-lg border border-gray-200 mx-3 mt-3 p-4 ">
          <div className="text-blue-800">ساعت کاری در روزهای عادی:</div>
          <div className="flex items-center my-4">
            <input
              id="default-radio-1"
              type="radio"
              name="is_24_hour_store"
              className="w-4 h-4 border-gray-300 focus:ring-blue-500 focus:ring-2"
              checked={form?.store_business_hours_setting?.is_24_hour_store}
              onChange={() => handleOnChangeRadio(true)}
            />
            <label
              htmlFor="default-radio-1"
              className="mx-2 text-sm font-medium text-gray-700"
            >
              فروشگاه 24 ساعت باز است
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="default-radio-2"
              type="radio"
              name="is_24_hour_store"
              className="w-4 h-4 border-gray-300 focus:ring-blue-500 focus:ring-2"
              checked={!form?.store_business_hours_setting?.is_24_hour_store}
              onChange={() => handleOnChangeRadio(false)}
            />
            <label
              htmlFor="default-radio-2"
              className="mx-2 text-sm font-medium text-gray-700"
            >
              فروشگاه برای ساعات انتخابی باز است
            </label>
          </div>
          {form?.store_business_hours_setting?.is_24_hour_store ? (
            <></>
          ) : (
            <SelectTimeComponent
              onChangeListener={handleOnChangeWorkHours}
              data={form?.store_business_hours_setting?.hours}
            />
          )}
        </div>
      </div>
    </div>
  );
};
