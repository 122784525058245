import React from "react";
import ICON from "src/assets/images/empty.svg";
export const EmptyComponent = (props) => {
  const { title } = props || {};
  return (
    <div className="w-full h-fit flex items-center justify-center">
      <div className="my-20 opacity-70">
        <img className="m-auto" src={ICON} alt={title} />
        <div className="text-gray-400 text-center mt-4">{title}</div>
      </div>
    </div>
  );
};
