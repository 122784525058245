/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/hooks";
import { PhoneIconSVG, TrashIconSVG } from "src/assets/icons";
import { Checkbox } from "src/components/checkbox/Checkbox";
import { ConfirmModal } from "src/components/modals/ConfirmModal";
import Modal from "src/components/modals/Modal";
import {
  deleteDeliveryBike,
  getDeliveryBikes,
  searchDeliveryBikes,
  setAddDeliveryBike,
} from "src/controllers/deliveryController";
import { selectDeliveryBikesList } from "./deliveryBikesSlice";
import MainLayout from "../mainLayout";
import { ItemBikeObject } from "../constants";
import { removePreCodeInNumber } from "src/utils";
import { AddFishoppingDeliveryBikeModalComponent } from "src/components/modals/AddFishoppingDeliveryBikeModalComponent";
import { AddStoreDeliveryBikeModalComponent } from "src/components/modals/AddStoreDeliveryBikeModalComponent";

const DeliveryPage = () => {
  const dispatch = useAppDispatch();
  const list = useSelector(selectDeliveryBikesList);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectItemForDelete, setSelectItemForDelete] = useState<string>("");
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [checkboxState, setCheckboxState]: any = useState<any>({
    storeBikes: true,
  });
  useEffect(() => {
    dispatch(getDeliveryBikes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toggleOpenModalDialog = () => {
    //checkboxState
    setIsOpenModal((prev) => !prev);
  };
  const handleCheckboxChange = (name: string, value: boolean) => {
    setCheckboxState({
      storeBikes: false,
      [name]: value,
    });
  };
  const handleDeleteBike = (id: string) => {
    dispatch(deleteDeliveryBike(id));
    handleCloseConfirmModal();
  };
  const handleOpenConfirmModal = (id: string) => {
    setSelectItemForDelete(id);
    setIsOpenConfirmModal(true);
  };
  const handleCloseConfirmModal = () => {
    setSelectItemForDelete("");
    setIsOpenConfirmModal(false);
  };
  const isActiveAddDeliveryButton = checkboxState.storeBikes === true;
  const listLableText = checkboxState.storeBikes
    ? "پیک‌های فعال فروشگاه"
    : "لیست پیک های فعال";

  return (
    <MainLayout>
      <div className="bg-white rounded-lg">
        <div className="px-6 text-blue-900 py-3 text-xl">تنظیمات پیک</div>
        <div className="w-full h-0.5 bg-gray-100"></div>
        <div className="text-blue-900 mt-8 mx-5 text-lg">انتخاب نوع پیک</div>
        <div className="px-16">
          <div className="flex items-start justify-start mt-6">
            <Checkbox
              name="service"
              onChange={console.log}
              bgColor="#238FF3"
              readonly={true}
              checked={false}
            />
            <div className="mx-3">
              <div className="text-gray-800 text-base font-bold">
                سرویس خدماتی{" "}
              </div>
              <div className="text-gray-800 text-sm mt-1">
                ارسال سفارشات توسط سرویس‌های خدماتی ارائه دهنده ( اسنپ ، الوپیک
                و...)
              </div>
            </div>
          </div>
          <div className="flex items-start justify-start mt-6">
            <Checkbox
              name="fishappingBikes"
              onChange={console.log}
              bgColor="#238FF3"
              readonly={true}
              checked={false}
            />
            <div className="mx-3">
              <div className="text-gray-800 text-base font-bold">
                پیک فیشاپینگ{" "}
              </div>
              <div className="text-gray-800 text-sm mt-1">
                ارسال سفارشات توسط پیک‌های فیشاپینگ
              </div>
            </div>
          </div>
          <div className="flex items-start justify-start mt-6">
            <Checkbox
              name="storeBikes"
              onChange={handleCheckboxChange}
              bgColor="#238FF3"
              checked={checkboxState.storeBikes}
              readonly={false}
            />
            <div className="mx-3">
              <div className="text-gray-800 text-base font-bold">
                پیک فروشگاه
              </div>
              <div className="text-gray-800 text-sm mt-1">
                ارسال سفارشات توسط پیک‌های فروشگاه
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center px-4">
          <div className="text-blue-900 my-8  text-lg">{listLableText}</div>
          <div
            onClick={
              isActiveAddDeliveryButton ? toggleOpenModalDialog : () => null
            }
            className={`text-white text-center mx-16 px-8 py-2 rounded-lg ${
              isActiveAddDeliveryButton
                ? "bg-blue-800 hover:bg-blue-900 cursor-pointer"
                : "bg-gray-300"
            }`}
          >
            افزودن پیک
          </div>
        </div>
        <div className="overflow-auto px-20 pb-16">
          {list.map((item: ItemBikeObject) => {
            return (
              <div
                key={`delivery-item-${item.uuid}`}
                className="grid grid-cols-6 grid-rows-2 w-full relative px-8 py-3 my-1.5 rounded-lg bg-gray-50 border border-gray-200 hover:bg-gray-100"
              >
                <div className="col-span-4 row-span-2">
                  <div className="flex items-center justify-start h-1/2 py-1">
                    <div className="overflow-hidden relative w-6 h-6 bg-white border border-gray-200  rounded-full ml-4 flex items-center justify-center">
                      <svg
                        className="w-6 h-6 text-gray-500 mt-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        ></path>
                      </svg>
                    </div>
                    <div className="mx-1">{item.first_name}</div>
                    <div className="mx-1">{item.last_name}</div>
                    <div className="mx-2">-</div>
                    <div className="">{item.national_code}</div>
                  </div>
                  <div className="flex items-center justify-start h-1/2 py-1">
                    <PhoneIconSVG color="#6B7280" />
                    <div className="mx-4 text-gray-700">
                      {removePreCodeInNumber(item.phone)}
                    </div>
                  </div>
                </div>
                <div className="col-span-2 row-span-2 flex justify-end items-center">
                  <div className="bg-sky-100 text-blue-800 mx-4 rounded-xl px-4 py-1 text-sm">
                    5 سفارش در حال ارسال
                  </div>
                  <TrashIconSVG
                    color="#DE2B2B"
                    className="cursor-pointer"
                    onClick={() =>
                      handleOpenConfirmModal(item.bike_identification)
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {isOpenModal && checkboxState.storeBikes && (
        <AddFishoppingDeliveryBikeModalComponent
          close={toggleOpenModalDialog}
        />
      )}
      {/*isOpenModal && checkboxState.storeBikes && (
        <AddStoreDeliveryBikeModalComponent close={toggleOpenModalDialog} />
      )*/}
      {isOpenConfirmModal && (
        <ConfirmModal
          data={{
            title: "هشدار",
            description: "آیا مطمن به حذف پیک هستید؟",
            confirmText: "بله",
            cancelText: "خیر",
          }}
          close={handleCloseConfirmModal}
          confirm={() => handleDeleteBike(selectItemForDelete)}
        />
      )}
    </MainLayout>
  );
};

export default memo(DeliveryPage);
