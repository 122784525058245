import { createSlice } from "@reduxjs/toolkit";
import { deleteJsonItem, mergeListsBy } from "src/utils";
import { RootState } from "../../app/store";
type StateProps = {
  total_items: number;
  total_pages: number;
  current_page: number;
  products: any;
};
const initialState: StateProps = {
  total_items: 0,
  total_pages: 0,
  current_page: 1,
  products: [],
};
export const similarSlice = createSlice({
  name: "similar",
  initialState,
  reducers: {
    addSimilarTotalItem: (state, action) => {
      state.total_items = action.payload;
    },
    addSimilarTotalPage: (state, action) => {
      state.total_pages = action.payload;
    },
    addSimilarCurrentPage: (state, action) => {
      state.current_page = action.payload;
    },
    addSimilarProducts: (state, action) => {
      state.products = action.payload;
    },
    updateSimilarProducts: (state, action) => {
      const newList = action.payload;
      const oldList = state.products;
      state.products = mergeListsBy(oldList, newList, "uuid");
    },
    resetSimilarProducts: (state) => {
      state.products = initialState.products;
    },
  },
});

export const {
  addSimilarTotalItem,
  addSimilarTotalPage,
  addSimilarCurrentPage,
  addSimilarProducts,
  updateSimilarProducts,
  resetSimilarProducts,
} = similarSlice.actions;

export const selectorSimilarTotalItem = (state: RootState) =>
  state.similar.total_items;
export const selectorSimilarTotalPage = (state: RootState) =>
  state.similar.total_pages;
export const selectorSimilarCurrentPage = (state: RootState) =>
  state.similar.current_page;
export const selectorSimilarProducts = (state: RootState) =>
  state.similar.products;

export default similarSlice.reducer;
