import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import mainReducer from "../features/main/mainSlice";
import dashboardReducer from "../features/main/dashboard/dashboardSlice";
import transactionsReducer from "../features/main/wallet/transactionsSlice";
import notificationReducer from "../features/main/notificationSlice";
import similarReducer from "../features/main/siimilarSlice";
import deliveryBikesReducer from "../features/main/delivery/deliveryBikesSlice";
import storeReducer from "../features/profile/storeSlice";
import orderReducer from "../features/orders/ordersSlice";
import storeSettingsReducer from "../features/myStore/MyStoreSlice";
import chatReducer from "../features/chat/chatSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    main: mainReducer,
    store: storeReducer,
    order: orderReducer,
    notifications: notificationReducer,
    similar: similarReducer,
    deliveryBikes: deliveryBikesReducer,
    dashboard: dashboardReducer,
    storeSettings: storeSettingsReducer,
    transactions: transactionsReducer,
    chat: chatReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
