import React from "react";
export const EmptyPage = (props: any): JSX.Element => {
  const { label } = props || {};
  return (
    <div className="text-6xl font-bold w-screen h-screen flex items-center justify-center">
      {label}
    </div>
  );
};

export default EmptyPage;
