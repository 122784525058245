import React from "react";
import "./loading.css";

export const Loading3dot = (props) => {
  const { className = "" } = props || {};
  return (
    <div className={`spinner1 ${className}`}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};
