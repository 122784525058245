import React from "react";
import Modal from "./Modal";

export const ConfirmModal = (props: any) => {
  const { data, close, confirm } = props || {};
  const { title, description, confirmText, cancelText } = data || {};
  return (
    <Modal close={close}>
      <div className="mt-6 text-center text-red-700">{title}</div>
      <div className="mt-4 mx-6 text-center">{description}</div>
      <div className="mt-6 mb-4 w-full flex">
        <div
          onClick={close}
          className="w-full bg-gray-300 text-gray-900 rounded px-4 py-2 text-center cursor-pointer"
        >
          {cancelText}
        </div>
        <div className="w-6"></div>
        <div
          onClick={confirm}
          className="w-full bg-blue-500 text-white rounded px-4 py-2 text-center cursor-pointer"
        >
          {confirmText}
        </div>
      </div>
    </Modal>
  );
};
