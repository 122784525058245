import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ArrowIcon } from "src/assets/icons";
import Header from "src/components/Header";
import EditIMG from "src/assets/icons/edit-2.png";
import { ProfileModal } from "src/components/modals/ProfileModal";
import { FirstPaymentModal } from "src/components/modals/FirstPaymentModal";
import { useSelector } from "react-redux";
import {
  selectMenu,
  selectMenuItem,
  selectToken,
  selectUserAccess,
  setMenuItem,
  setStep,
} from "./mainSlice";
import { selectProfile } from "../profile/storeSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "src/app/hooks";
import { removePreCodeInNumber } from "src/utils";
import { deleteNotificatoin, selectNotifiication } from "./notificationSlice";
import { ShopIconSVG } from "src/assets/icons";
import { uplaodImage } from "src/controllers/ProfileController";
import { UploadFiles } from "src/components/uploadFiles/UploadFiles";
import { BASE_URL } from "src/api/masterAPI";

export function MainLayout(props: any) {
  const { pageName = "" } = useParams();
  const selUserAccess = useSelector(selectUserAccess);
  const selProfile = useSelector(selectProfile);
  const MenuData = useSelector(selectMenu);
  const menuItem = useSelector(selectMenuItem);
  const selNotification = useSelector(selectNotifiication);
  const token = useSelector(selectToken);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [list, setList] = useState<any[]>([]);
  const [storeImageUrl, setStoreImageUrl] = useState<string>();
  const { shop_name, shop_owner_name, shop_manager_name, phone, mobile, logo } =
    selProfile || {};
  // const ws: any = useContext(WebSocketContext);
  // useEffect(() => {
  // getRandomLocation(35.68198003744061, 51.33644946071224, 1);
  // new Array(10).fill(null).map((i, index) => {
  //   randomGeo({ lat: 35.68198003744061, lng: 51.33644946071224 }, 5000);
  // });
  // }, []);
  useEffect(() => {
    setStoreImageUrl(logo?.url);
  }, [logo?.url]);

  useEffect(() => {
    // ws.initWebSocket();
    if (!menuItem) {
      setList(MenuData);
      return;
    }
    if (menuItem.child && menuItem.child.length) {
      setList(menuItem.child);
    } else if (menuItem.id === undefined) {
      // back button
      setList(MenuData);
    } else {
      MenuData?.find((i: any) => {
        if (i.id === menuItem.id) {
          setList(MenuData);
        } else if (i?.child?.find((j: any) => j.id === menuItem.id)) {
          setList(i.child || []);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItem]);

  useEffect(() => {
    try {
      MenuData.find((i: any) => {
        if (i.key === pageName) {
          // name = i.name;
          dispatch(setMenuItem(i.id ? i : null));
        }
        if (i?.child?.length) {
          const keyInChild = i?.child?.find((j: any) => j.key === pageName);
          if (keyInChild) {
            dispatch(setMenuItem(keyInChild.id ? keyInChild : null));
            // name = keyInChild.name;
          }
        }
      });
    } catch (error) {
      console.error("😏 => error", error);
    }
  }, []);

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const handleClickMenu = (item: any) => {
    dispatch(deleteNotificatoin(item.key));
    dispatch(setMenuItem(item.id ? item : null));
    if (item.link) {
      item.link && navigate(`/${item.link}`);
    }
  };

  function resetFile() {
    // document.getElementsByName('file')[0].value = null;
  }
  const uploadImage = (e: any) => {
    dispatch(
      uplaodImage(
        token,
        e.target.files[0],
        `${BASE_URL}/attachment/store/logo`,
        console.log,
        setStoreImageUrl
      )
    );
  };
  // const handleClickMenu = (item: any) => {
  //   if (item.child && item.child.length) {
  //     setSelectParent(item);
  //     setShowChild(true);
  //   } else {
  //     if (item.id === undefined) {
  //       setSelectParent(null);
  //       setShowChild(false);
  //     } else {
  //       item.link && navigate(`/${item.link}`);
  //     }
  //   }
  // };

  return (
    <div>
      <Header />
      <div className="w-screen h-5/6 p-2 bg-zinc-100 select-none">
        <div>
          <div className="bg-white fixed top-0 bottom-0 right-0 w-1/4">
            <input
              type="file"
              id="upload"
              name="file"
              hidden
              accept="image/png, image/jpeg, image/jpg"
              onChange={uploadImage}
              multiple={false}
              autoComplete="off"
            />
            {storeImageUrl ? (
              <label
                htmlFor="upload"
                className="flex items-center justify-center mt-8 cursor-pointer"
              >
                <img
                  className="overflow-hidden relative w-16 h-16 bg-blue-400 rounded-full border-2 border-green-500 flex justify-center items-center object-cover"
                  id="upload"
                  src={storeImageUrl}
                  alt="store_image"
                />
                <div className="h-6 w-6 -mr-4 mt-12 z-10 border-2 border-white rounded-full bg-blue-500 text-white flex justify-center items-center text-center text-2xl pt-0.5">
                  +
                </div>
              </label>
            ) : (
              <>
                <label
                  htmlFor="upload"
                  className="flex items-center justify-center mt-8 cursor-pointer"
                >
                  <div className="overflow-hidden relative w-16 h-16 bg-blue-400 rounded-full border-4 border-blue-500 flex justify-center items-center">
                    <ShopIconSVG color="#fff" size={36} />
                  </div>
                  <div className="h-6 w-6 -mr-4 mt-12 z-10 border-2 border-white rounded-full bg-blue-500 text-white flex justify-center items-center text-center text-2xl pt-0.5">
                    +
                  </div>
                </label>
              </>
            )}
            <div className="flex items-center justify-center pt-4">
              <div className="font-normal text-lg text-blue-900">
                {shop_name}
              </div>
              <img
                src={EditIMG}
                onClick={() => dispatch(setStep("editProfile"))}
                alt="edit"
                className="h-6 w-6 mr-2 cursor-pointer rounded p-1 hover:bg-gray-100"
              />
            </div>
            <div className="text-black text-center pt-4">
              {removePreCodeInNumber(mobile)}
            </div>
            <div className="text-white bg-green-500 mx-6 rounded text-center mt-4 py-4 flex items-center justify-around">
              <div>0 امتیاز فروشگاه</div>
              <div>سطح 5</div>
            </div>
            <div className="p-6 w-full">
              <ul>
                {[...list]?.map((item: any) => {
                  const notifCount = selNotification[item.key] || null;
                  // const hasChild = item.child ? true : false;
                  const isSelect =
                    pageName === item.key ? true : menuItem?.id === item.id;

                  return (
                    <li
                      key={`main-menu-item-${item.id}`}
                      className={`${
                        isSelect
                          ? "bg-slate-200 hover:bg-slate-300 "
                          : "bg-transparent hover:bg-slate-200 "
                      } flex items-center justify-between my-0.5 py-3 px-4 cursor-pointer rounded`}
                      onClick={() => handleClickMenu(item)}
                    >
                      <div className="flex items-center">
                        {item.icon}
                        <div className="mx-2 truncate">{item.name}</div>
                      </div>
                      {notifCount ? (
                        <span
                          className="absolute left-16 rounded-full animate-pulse w-5 h-5 text-center text-white "
                          style={{
                            backgroundColor: "#F29423",
                          }}
                        >
                          {notifCount}
                        </span>
                      ) : (
                        <></>
                      )}
                      <ArrowIcon />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="absolute top-16 bottom-0 left-0 w-3/4 bg-gray-100 p-3">
            {props.children}
          </div>
        </div>
      </div>
      {selUserAccess === "needProfile" || selUserAccess === "editProfile" ? (
        <ProfileModal />
      ) : (
        <></>
      )}
      {selUserAccess === "needChargeWallet" ? <FirstPaymentModal /> : <></>}
    </div>
  );
}
export default MainLayout;
