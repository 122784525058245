import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import { ItemBikeObject } from "../constants";

type StateProps = {
  bikeslist: ItemBikeObject[];
};
const initialState: StateProps = {
  bikeslist: [],
};
export type NotificationNames = "new_invoice" | "all";
export const deliveryBikesSlice = createSlice({
  name: "deliveryBikes",
  initialState,
  reducers: {
    setDeliveryBikes: (state, action) => {
      state.bikeslist = action.payload;
    },
    addDeliveryBike: (state, action) => {
      state.bikeslist = [action.payload, ...state.bikeslist];
    },
    deleteDeliveryBikes: (state, action) => {
      state.bikeslist = state.bikeslist.filter(
        (bikes: ItemBikeObject) => bikes.bike_identification !== action.payload
      );
    },
  },
});

export const { setDeliveryBikes, deleteDeliveryBikes, addDeliveryBike } =
  deliveryBikesSlice.actions;

export const selectDeliveryBikesList = (state: RootState) =>
  state.deliveryBikes.bikeslist;

export default deliveryBikesSlice.reducer;
