import { LocationIconSVG } from "src/assets/icons";
import { LiveLocationMap } from "../maps/LiveLocationMap";
import Modal from "./Modal";

export const SimpleMapModal = (props: any) => {
  const { coordinates, close } = props || {};

  const handleSubmit = () => {
    close();
  };
  return (
    <Modal close={close} className="px-0 lg:px-0">
      <div className="flex px-3">
        <LocationIconSVG color={"#1F2937"} />
        <div className="text-gray-800 text-base mx-1">نمایش روی نقشه</div>
      </div>
      {/* <img
        src={"/assets/images/fake_2.png"}
        className="h-96 w-h600 my-6 bg-contain"
        alt="map"
      /> */}
      <div className="h-h500 w-h600 my-6 bg-contain">
        <LiveLocationMap coordinates={coordinates} />
      </div>
      <div
        onClick={handleSubmit}
        className="bg-green-500 text-white px-12 py-2 text-center w-fit text-lg rounded-lg m-auto cursor-pointer hover:bg-green-600"
      >
        تایید
      </div>
    </Modal>
  );
};
