import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useAppDispatch } from "src/app/hooks";
import { MyLocationSVG } from "src/assets/icons";
import { notify } from "../notify";

var myMap: any;
export function LiveLocationMap(props: any) {
  const { coordinates } = props || {};
  const mapRef: any = useRef();
  // const dispatch = useAppDispatch();
  const [lookingLocation, setLookingLocation] = useState(false);
  useEffect(() => {
    if (!myMap || coordinates.length === 0) return;
    const originLocation = coordinates[0];
    if (originLocation.lat && originLocation.lng) {
      myMap.flyTo({
        center: [originLocation.lng, originLocation.lat],
        essential: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  useLayoutEffect(() => {
    if (coordinates.length === 0) return;

    const originLocation = coordinates[0];
    myMap = new mapboxgl.Map({
      container: "multiLocationMap",
      style:
        "https://api.parsimap.ir/styles/parsimap-streets-v11?key=p1a8ac21f2cd70490e99eaee23936bb96334e6bd6f",
      center: [originLocation.lng, originLocation.lat],
      zoom: 15,
    });
    coordinates?.map((item: any, index: number) => {
      const coor: any = [item.lng, item.lat];
      const el = document.createElement("div");
      const width = 50;
      const height = 50;
      el.className = "marker";
      el.style.backgroundImage = index
        ? `url("../../assets/icons/3.png")`
        : `url("../../assets/icons/2.png")`;
      el.style.width = `${width}px`;
      el.style.height = `${height}px`;
      el.style.backgroundSize = "100%";

      el.addEventListener("click", () => {});
      new mapboxgl.Marker(el).setLngLat(coor).addTo(myMap);
      // new mapboxgl.Marker(mapRef).setLngLat(coor).addTo(myMap);
    });
    if (!myMap) return;
    myMap.on("load", () => {
      const coor = coordinates.map((item: any) => {
        return [item.lng, item.lat];
      });
      myMap.addSource("navigationLine", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [coor],
              },
            },
          ],
        },
      });

      myMap.addLayer({
        id: "park-boundary",
        type: "fill",
        source: "navigationLine",
        paint: {
          "fill-color": "#3fb1ce",
          //   "fill-opacity": 0.9,
        },
        filter: ["==", "$type", "Polygon"],
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCurrentLocation = async () => {
    if (lookingLocation) return;
    setLookingLocation(true);
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        const success = (position: any) => {
          setLookingLocation(false);

          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          myMap.flyTo({
            center: [lng, lat],
            essential: true,
          });
        };
        const error = (e: any) => {
          setLookingLocation(false);
          notify.info("مکان شما قابل دسترسی نیست");
        };
        navigator.geolocation.getCurrentPosition(success, error, {});
      });
  };
  return (
    <div
      ref={mapRef}
      id="multiLocationMap"
      className="relative w-full h-full bg-zinc-100 overflow-hidden"
    >
      {/* <Map
        ref={mapContainerRef}
        style="https://www.parsimap.ir/styles/street.json"
        containerStyle={{ height: "100vh", width: "100vw" }}
        center={center}
        zoom={[zoom]}
        onDragEnd={handleSetPosition}
      ></Map>*/}

      {/* <img
        src="https://cdn.parsimap.ir/icons/map-marker.png"
        className="absolute left-0 right-0 mx-auto top-0 bottom-0 my-auto text-center z-40 h-8 w-6 select-none"
        alt="location"
      /> */}
      {/* پیدا کردن لوکیشن فعلی */}
      <div
        className="absolute left-0 -bottom-2 text-center z-40 h-8 w-8 cursor-pointer"
        onClick={handleCurrentLocation}
      >
        <MyLocationSVG color={lookingLocation ? "#f00" : "#000"} size={20} />
      </div>
    </div>
  );
}
