import React, { useContext, useEffect, useState } from "react";
import { generateID } from "src/utils";
import {
  BackIconSVG,
  CategoryIconSVG,
  PositiveIconSVG,
  SendMessageIconSVG,
} from "src/assets/icons";
import { SocketContext } from "src/api/socketContext";
import { useDispatch, useSelector } from "react-redux";
import { selChatsList } from "src/features/chat/chatSlice";
import { timeStampToHoure } from "src/utils/time";
// sending - delivered - inQueue
const FAKE_CHAT = [
  {
    id: generateID(),
    isMine: true,
    title: "سلام، کی میرسید؟",
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
  {
    id: generateID(),
    isMine: false,
    title: "سلام، در مسیر هستم",
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
  {
    id: generateID(),
    isMine: false,
    title: "20 دقیقه دیگه می رسم",
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
  {
    id: generateID(),
    isMine: true,
    title: "رسیدین با من تماس بگیرین",
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
  {
    id: generateID(),
    isMine: true,
    title: "منتظرتون هستم.",
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
  {
    id: generateID(),
    isMine: false,
    title: "رسیدم درب منزل ...",
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
  {
    id: generateID(),
    isMine: true,
    title: "کارتخوان هم آوردید ؟",
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
  {
    id: generateID(),
    isMine: false,
    title: "بله",
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
  {
    id: generateID(),
    isMine: true,
    title: "مرسی الان میام پایین",
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
  {
    id: generateID(),
    isMine: true,
    title: "🙏",
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
  {
    id: generateID(),
    isMine: false,
    title: "👍", //'❤️',
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
  {
    id: generateID(),
    isMine: true,
    title:
      "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
  {
    id: generateID(),
    isMine: false,
    title:
      "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
    time: new Date(),
    sendStatus: "sending",
    isAttachment: false,
  },
];
const FAKE_TEMPLATE_MESSAGE = [
  { id: generateID(), message: "سلام، کی میرسید؟" },
  { id: generateID(), message: "منتظرتون هستم." },
  // { id: generateID(), message: 'لطفا تماس بگیرید.' },
  // { id: generateID(), message: 'نمیدونم شاید حق با تو باشه' },
];
export default function ChatModal(props) {
  const selChatList = useSelector(selChatsList);
  const ws: any = useContext(SocketContext) || {};
  const {
    answerCall,
    call,
    callAccepted,
    callEnded,
    leaveCall,
    myVoice,
    userVoice,
    getRecordPermission,
    sendMessage,
    callUser,
  } = ws || {
    answerCall: () => null,
    call: {},
    callAccepted: false,
  };
  const [invoice_uuid, set_invoice_uuid] = useState();
  const [text, setText] = useState("");
  const { close } = props || {};
  const dispatch = useDispatch();

  useEffect(() => {
    getRecordPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    sendMessage("get_messages", props.invoiceId);
    set_invoice_uuid(props.invoiceId);
  }, []);

  const handleSelectStore = (item) => {
    // sendMessage("get_customer_original_invoice", item.uuid);
    // sendMessage("get_invoice_changes_item_by_customer", item.uuid);
    sendMessage("get_messages", item.uuid);
    // sendMessage("message_templates", item.uuid);
    set_invoice_uuid(item.uuid);
    // dispatch(updateCurrentOrderData(item));
  };

  const handleSendMessage = () => {
    //text, setText
    // 'send_message',(invoice_uuid, content, content_type)
    sendMessage("send_message", invoice_uuid, text, 1);
    // sendMessage("send_message", invoice_uuid, text, 1);
    setTimeout(() => {
      handleSelectStore({ uuid: invoice_uuid });
    }, 1000);
    setText("");
  };
  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };
  const calling = () => {
    callUser(text);
  };
  const pushVoice = () => {};
  const stopVoice = () => {};

  return (
    <div className="relative w-h550 px-3">
      <div className="flex justify-end items-center text-black-text w-full cursor-pointer">
        <div className="flex items-center mx-4">
          <div
            className="_animate-bounce bg-green-500 w-8 h-8 rounded-full flex items-center justify-center hover:bg-green-600 cursor-pointer"
            onMouseDown={pushVoice}
            onMouseUp={stopVoice}
            onClick={calling}
          >
            <div className="h-2/5 bg-white w-0.5 rounded-lg mr-0.5"></div>
            <div className="h-3/5 bg-white w-0.5 rounded-lg mr-0.5"></div>
            <div className="h-1/5 bg-white w-0.5 rounded-lg mr-0.5"></div>
            <div className="h-2/5 bg-white w-0.5 rounded-lg mr-0.5"></div>
            <div className="h-1/5 bg-white w-0.5 rounded-lg mr-0.5"></div>
          </div>
        </div>
        {/* <BackIconSVG color={"gray"} /> */}
        <span className="text-2xl text-gray-400" onClick={close}>
          &#8676;
        </span>
      </div>
      <section className=" mt-4 px-4 pb-14 bg-gray-50 w-full h-h500 max-h-h500 overflow-y-auto overflow-x-hidden">
        <div className="w-fit m-auto mt-4 bg-gray-100 text-center text-xs px-4 py-2 rounded-3xl">
          9 آذر 1401 . 13:45
        </div>
        {/* <div className="text-center my-2 ">
          پیک در حال حرکت به سمت مقصد است...
        </div> */}
        {selChatList?.map((msgItem: any, index: number) => {
          console.log("😏 => msgItem:", msgItem);
          //   const megTime = msgItem.time.getHours() + ":" + msgItem.time?.getMinutes();
          const isMine = msgItem.is_mine;
          const deliveryIcon =
            msgItem.status === 1 ? (
              <div className="text-green-600">&#10004;</div>
            ) : msgItem.status === 2 ? (
              <div className="text-sky-600">&#10004;</div>
            ) : (
              <div className="text-sky-600">&#10004; &#10004;</div>
            );
          return (
            <div
              key={`item-chat-index-${index}-${msgItem.uuid}`}
              className={`w-full bg-transparent flex items-center py-0.5 ${
                isMine ? "justify-start" : "justify-end"
              }`}
            >
              <div
                className={`w-fit self-center content-center h-fit px-2.5 py-0.5 rounded-2xl ${
                  isMine
                    ? "rounded-br-none bg-sky-100 text-blue-light"
                    : "rounded-bl-none bg-gray-100 text-black-text"
                }`}
                style={{ minWidth: 100, maxWidth: "75%" }}
              >
                <div className="py-3 px-2 whitespace-pre-wrap w-full">
                  {msgItem.content}
                </div>
                <div
                  className={`flex w-full  items-center text-micro ${
                    isMine ? "justify-end" : "justify-start"
                  }`}
                >
                  {isMine && (
                    <div
                      className={`text-sm mx-1 ${
                        msgItem.status === 1 ? "text-green-600" : "text-sky-600"
                      }`}
                    >
                      {deliveryIcon}
                    </div>
                  )}
                  <div className="mx-0">
                    {timeStampToHoure(new Date(msgItem?.created_at), "hh:mm")}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </section>
      <div className="bg-gray-50 p-2 w-h550 overflow-x-auto overflow-y-hidden flex">
        {FAKE_TEMPLATE_MESSAGE?.map((tempItem) => {
          return (
            <div
              key={tempItem.id}
              className="bg-white mx-0.5 px-3 py-2 rounded-xl w-fit whitespace-nowrap cursor-pointer hover:bg-sky-100 hover:border-sky-800"
              onClick={() => setText(tempItem.message)}
            >
              {tempItem.message}
            </div>
          );
        })}
        <div className="flex justify-center items-center bg-white mx-0.5 px-3 py-2 rounded-xl w-fit cursor-pointer hover:bg-sky-100 hover:border-sky-800">
          <CategoryIconSVG color="#555" size={18} />
          <div className="truncate w-32 mr-2 whitespace-nowrap">
            سایر پیام‌های آماده
          </div>
        </div>
      </div>
      <div className="my-8 flex items-center justify-between border border-gray-200 rounded-lg bg-gray-50 px-4">
        <PositiveIconSVG color="#bbb" />
        <input
          type="text"
          className="w-full mx-2 bg-gray-50 py-3 outline-none"
          placeholder="پیام خود را وارد کنید ..."
          value={text}
          onChange={(e) => {
            sendMessage("typing_message", invoice_uuid);
            setText(e.target.value);
          }}
          onKeyDown={_handleKeyDown}
        />
        <SendMessageIconSVG
          color="#888"
          className="cursor-pointer"
          onClick={handleSendMessage}
        />
      </div>
      <section className="call-modal">
        {call.isReceivingCall && !callAccepted && (
          <div className="bg-white p-2 rounded-xl">
            <div className="grid grid-cols-2 grid-rows-2 gap-2">
              <h1 className="col-span-2 text-center">مشتری میخواد حرف بزنه</h1>
              <div
                onClick={answerCall}
                className="px-2 py-1 bg-green-700 text-white cursor-pointer rounded-lg w-full text-center hover:bg-green-800"
              >
                جواب دادن
              </div>
              <div
                onClick={leaveCall}
                className="px-2 py-1 bg-red-700 text-white cursor-pointer rounded-lg w-full text-center hover:bg-red-800"
              >
                رد کردن
              </div>
            </div>
          </div>
        )}
        <div className="hidden w-0 h-0">
          <audio ref={userVoice} autoPlay></audio>
          <audio ref={myVoice} muted autoPlay></audio>
        </div>
      </section>
    </div>
  );
}
