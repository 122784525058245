/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAllQueryParams, queryParams } from "src/utils";
import {
  selectUserAccess,
  setStep,
  setToken,
} from "src/features/main/mainSlice";
import { cheeckFirstPayment } from "./controllers/WalletController";
import { getProfile } from "./controllers/ProfileController";
import { getLocalStorage, setLocalStorage } from "./utils/localstorage";
import Loading from "./components/loading/Loading";
import { setProfile } from "./features/profile/storeSlice";

function RequireAuth(props: any) {
  const dispatch: any = useDispatch();
  const selUserAccess = useSelector(selectUserAccess);
  var token: string = queryParams.token;
  var phone: string = queryParams.phone;

  useEffect(() => {
    async function initApp() {
      let isFirstLogin = false;
      if (token && phone) {
        isFirstLogin = true;
      }

      //
      if (token) {
        await setLocalStorage("TOKEN", token);
      } else {
        token = await getLocalStorage("TOKEN");
      }
      //
      if (phone) {
        await setLocalStorage("PHONE", phone);
      } else {
        phone = await getLocalStorage("PHONE");
      }
      //
      if (!phone || !token) {
        dispatch(setStep("block"));

        return;
      }
      dispatch(setToken(token));
      dispatch(setProfile({ mobile: phone }));
      dispatch(
        getProfile((isCompleteProfile: boolean) => {
          isCompleteProfile && dispatch(cheeckFirstPayment());
        })
      );
      if (isFirstLogin) {
        deleteAllQueryParams();
      }
    }
    initApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (selUserAccess === "block") {
    window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}`);
    return <></>;
  } else if (selUserAccess === "guest") {
    return <Loading color="fill-green-700" />;
  } else if (selUserAccess === "access") {
    return <div dir="rtl">{props.children} </div>;
  } else {
    return <div dir="rtl">{props.children} </div>;
  }
}

export default RequireAuth;
