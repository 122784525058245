/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect, useState } from "react";
import { useAppDispatch } from "src/app/hooks";
import { CircleInfoIcon } from "src/assets/icons";
import { getAddressByCoordinate } from "src/controllers/mapController";
import { activePayment, setPayment } from "src/controllers/WalletController";
import { setStep } from "src/features/main/mainSlice";
import { randomGeo } from "src/utils";
import { notify } from "../notify";
import Modal from "./Modal";
import IconGif from "../../assets/gif/payment_modal.gif";
// import IconGif from "./src/assets/gif/payment_modal.gif";

type PriceState = {
  uuid: string;
  amount: number;
};
// const PRICE_RANGE = [
//   { uuid: 1, amount: 100000 },
//   { uuid: 2, amount: 200000 },
//   { uuid: 3, amount: 500000 },
// ];
export const FirstPaymentModal = () => {
  const [price, setPrice] = useState<any>({
    uuid: "",
    amount: 0,
  });
  const [priceList, setPriceList] = useState<any>([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(activePayment(setPriceList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = () => {
    if (price?.uuid) {
      dispatch(setPayment(price?.uuid));
    } else {
      if (priceList.length) {
        dispatch(setPayment(priceList[0].uuid));
        // notify.info("مبلغ اول به صورت پیشفرض انتخاب شد");
      } else {
        notify.info("یک مبلغ برای پرداخت را انتخاب کنید");
      }
    }
  };
  const handleCloseModal = () => {
    dispatch(setStep("access"));
  };

  return (
    <Modal close={() => notify.info("حتما باید پرداخت اولیه داشته باشید")}>
      <div className="p-10 text-center">
        <div className="w-32 h-40 m-auto">
          <video className="w-full h-full" autoPlay loop>
            <source src={IconGif} type="video/mp4" />
          </video>
        </div>
        <div className="mb-4 text-3xl font-bold text-indigo-900 mt-10">
          فعال سازی فروشگاه
        </div>
        <div className="flex items-center justify-center text-center mt-8">
          <CircleInfoIcon color={"#DE2B2B"} />
          <div className="mx-2 text-zinc-600">
            جهت فعالسازی فروشگاه باید مبلغ 100,000 تومان اعتبار داشته باشید.
          </div>
        </div>
        <div className="">
          <div className="my-2">
            {/* <div className="bg-gray-50 border border-gray-300 text-gray-700 text-xl text-center rounded-lg focus:ring-blue focus:border-blue-900 block w-full p-2.5">
              {`${price?.amount?.toLocaleString() || 0} تومان`}
            </div> */}
            {/* <input
              id="price"
              name="price"
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-2xl text-center rounded-lg focus:ring-blue focus:border-blue-900 block w-full p-2.5"
              value={`تومان ${price?.amount?.toLocaleString()}`}
            /> */}
          </div>
          {/* <div className="col-span-6 text-blue-900 text-center">
            مبلغ وارد شده باید بین 100,000 تا 500,000 تومان باشد.
          </div> */}
          <div className="flex items-center justify-center mt-8">
            {false &&
              priceList?.map((item: PriceState, index: number) => {
                const isSelect =
                  price.uuid === item.uuid ? true : index === 0 ? true : false;
                return (
                  <div
                    key={`${item.uuid}`}
                    className={`w-8/12 col-span-2 select-none rounded-lg text-center border py-2  ${
                      isSelect ? "bg-sky-50 text-sky-800 border-sky-500" : ""
                    }`}
                    onClick={() => setPrice(item)}
                  >
                    {`${item?.amount?.toLocaleString() || 0} تومان`}
                  </div>
                );
              })}
          </div>
          <button
            onClick={handleSubmit}
            className="w-8/12 mt-4 text-white bg-indigo-800 hover:bg-indigo-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center"
          >
            پرداخت
          </button>
        </div>
      </div>
    </Modal>
  );
};
