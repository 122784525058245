import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";

const initialState = {
  list: [],
  meta: {
    total: 0,
    total_pages: 1,
    current_page: 1,
    limit: 10,
    has_prev_page: false,
    has_next_page: false,
    prev_page: null,
    next_page: null,
  },
  bagMoneyList: [],
  bagMoneyMeta: {},
};
const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setTransactionsList: (state, action) => {
      state.list = action.payload;
    },
    setTransactionsMeta: (state, action) => {
      state.meta = action.payload;
    },
    setBagMoneyList: (state, action) => {
      state.bagMoneyList = action.payload;
    },
    setBagMoneyMeta: (state, action) => {
      state.bagMoneyMeta = action.payload;
    },
  },
});
export const {
  setTransactionsList,
  setTransactionsMeta,
  setBagMoneyList,
  setBagMoneyMeta,
} = transactionsSlice.actions;

export const selTransactionsList = (state: RootState) =>
  state.transactions.list;
export const selTransactionsMeta = (state: RootState) =>
  state.transactions.meta;
export const selBagMoneyList = (state: RootState) =>
  state.transactions.bagMoneyList;
export const selBagMoneyMeta = (state: RootState) =>
  state.transactions.bagMoneyMeta;

export default transactionsSlice.reducer;
