import React from "react";

import {
  ArrowIcon,
  BackIconSVG,
  BagIconSVG,
  BoxIconSVG,
  ChartStatusIconSVG,
  DeliveryIconSVG,
  HomeIconSVG,
  MessageTextIconSVG,
  MotorIconSVG,
  ReceptFactorIconSVG,
  ShopIconSVG,
  TicketIconSVG,
  WalletIconSVG,
} from "src/assets/icons";

export const MENU_STATIC_DATA = [
  {
    id: 1,
    name: "خلاصه وضعیت",
    icon: <HomeIconSVG color={"#292D32"} />,
    link: "dashboard",
    key: "dashboard",
  },
  {
    id: 2,
    name: "مدیریت سفارش‌ها",
    icon: <ReceptFactorIconSVG color={"#292D32"} />,
    key: "order",
    child: [
      {
        name: "مدیریت سفارش‌ها",
        icon: <BackIconSVG />,
      },
      {
        id: 11,
        name: "دریافت سفارش",
        icon: <ReceptFactorIconSVG color={"#292D32"} />,
        link: "orders/main",
        key: "main",
      },
      {
        id: 12,
        name: "تحویل‌ به‌ پیک",
        icon: <BoxIconSVG color={"#292D32"} />,
        link: "orders/inproccess",
        key: "acceptByUser",
      },
      {
        id: 13,
        name: "ارسال‌شده",
        icon: <MotorIconSVG color={"#292D32"} />,
        link: "orders/sending",
        key: "sending",
      },
      {
        id: 14,
        name: "گزارش سفارش‌ها",
        icon: <ChartStatusIconSVG color={"#292D32"} />,
        link: "orders/report",
        key: "rejectedByUser",
      },
    ],
  },
  {
    id: 8,
    name: "تنظیمات پیک",
    icon: <DeliveryIconSVG color={"#292D32"} />,
    link: "delivery",
  },
  { id: 3, name: "محصولات", icon: <TicketIconSVG color={"#292D32"} /> },
  {
    id: 4,
    name: "گزارش‌های فروش",
    icon: <ChartStatusIconSVG color={"#292D32"} />,
  },
  {
    id: 5,
    name: "کیف پول",
    icon: <WalletIconSVG color={"#292D32"} />,
    link: "wallet",
  },
  {
    id: 6,
    name: "دیدگاه و نظرات",
    icon: <MessageTextIconSVG color={"#292D32"} />,
  },
  {
    id: 7,
    name: "فروشگاه من",
    icon: <ShopIconSVG color={"#292D32"} size={22} />,
    link: "myStore",
  },
];
export const ORDER_MENU_STATIC_DATA = [
  {
    id: 1,
    label: "مشاهده‌ نشده",
    notif: 0,
    color: "#F29423",
    isShow: true,
    type: "sentToStore",
  },
  {
    id: 2,
    label: "مشاهده شده",
    notif: 0,
    color: "#F29423",
    isShow: true,
    type: "seenByStore",
  },
  {
    id: 3,
    label: "فاکتور تغییر کرده",
    notif: 0,
    color: "#F29423",
    isShow: false,
    type: "changedByStore",
  },
  {
    id: 4,
    label: "در انتظار تایید مشتری",
    notif: 0,
    color: "#F29423",
    isShow: true,
    type: "acceptedByStore",
  },
  {
    id: 5,
    label: "رد شده توسط خودم",
    notif: 0,
    color: "#F29423",
    isShow: false,
    type: "rejectedByStore",
  },
  {
    id: 6,
    label: "تائید شده توسط مشتری",
    notif: 0,
    color: "#F29423",
    isShow: false,
    type: "acceptByUser",
  },
  {
    id: 7,
    label: "رد شده توسط مشتری",
    notif: 0,
    color: "#F29423",
    isShow: true,
    type: "rejectedByUser",
  },
  {
    id: 11,
    label: "در حال ارسال",
    notif: 0,
    color: "#F29423",
    isShow: false,
    type: "sending",
  },
  {
    id: 8,
    label: "تایید فاکتور توسط بقیه فروشندگان",
    notif: 0,
    color: "#F29423",
    isShow: false,
    type: "rejectedByUserBecauseOtherSeller",
  },
];
export interface ItemBikeObject {
  first_name: string;
  last_name: string;
  national_code: string;
  country_code: string;
  phone: string;
  avatar?: any;
  uuid: string;
  in_hand_order_count: number;
  bike_identification: string;
}
export type ModalStatus = "close" | "open" | "error" | "success";

export interface ProfileState {
  shop_name: string;
  shop_owner_name: string;
  shop_manager_name: string;
  address: string;
  phone: string;
  mobile: string;
  lat: number;
  lng: number;
  bag_money: number;
  province_name: string;
  city_name: string;
  region_name: string;
  logo: any;
}

export interface StoreState {
  profile: ProfileState;
  storeIsOnlineStatus: number | undefined;
}
export interface CounterState {
  value: number;
  status: "idle" | "loading" | "failed";
}
export interface ProfileProps {
  children: React.ReactNode;
}
