import React from "react";

export const Tabs = (props) => {
  const { data, selectItem, onClick } = props || {};
  return (
    <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 ">
      {data?.map((item, index) => {
        const handleClick = () => {
          onClick(item);
        };
        const isSelect = selectItem
          ? selectItem.id === item.id
          : index === 0
          ? true
          : false;
        return (
          <li
            key={`tab-menu-item-${item.id}`}
            className="ml-2"
            onClick={handleClick}
          >
            <div
              className={`flex p-4 rounded-t-md ${
                isSelect
                  ? "active bg-gray-50 text-gray-600 border-b-2 border-blue-800"
                  : " cursor-pointer hover:text-gray-600 hover:bg-gray-50"
              }`}
            >
              <div>{item.label}</div>
              {item.notif ? (
                <span
                  className="rounded-full h-5 w-5 text-white mr-1 font-bold text-center"
                  style={{
                    backgroundColor: item.color,
                    fontSize: 10,
                  }}
                >
                  {item.notif}
                </span>
              ) : (
                <></>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
