import React, { useEffect, useLayoutEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useSelector } from "react-redux";
import { selectProfile, setProfile } from "src/features/profile/storeSlice";
import { MyLocationSVG } from "src/assets/icons";
import { notify } from "../notify";
import { useAppDispatch } from "src/app/hooks";
import { getAddressByCoordinate } from "src/controllers/mapController";
const mapStyle =
  "https://api.parsimap.ir/styles/parsimap-streets-v11?key=p1a8ac21f2cd70490e99eaee23936bb96334e6bd6f";
var myMap: any;
export function CircleMap(props: any) {
  const address = useSelector(selectProfile) || {};
  const [lookingLocation, setLookingLocation] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!myMap) return;
    if (address.lat && address.lng) {
      myMap.flyTo({
        center: [address.lng, address.lat],
        essential: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.lat, address.lng]);

  useLayoutEffect(() => {
    myMap = new mapboxgl.Map({
      container: "mapCircleLocation",
      style: mapStyle,
      center: [address.lng, address.lat],
      zoom: 15,
      attributionControl: false,
      interactive: false,
    });
    if (!myMap) return;

    myMap.scrollZoom.disable();
    myMap.dragRotate.disable();
    myMap.touchZoomRotate.disableRotation();

    myMap.on("load", () => {
      const coor: any = [address?.lng, address?.lat];
      const el = document.createElement("div");
      const width = 48;
      const height = 48;
      el.className = "marker";
      el.style.backgroundImage = `url("../../assets/icons/2.png")`;
      el.style.width = `${width}px`;
      el.style.height = `${height}px`;
      el.style.backgroundSize = "100%";
      new mapboxgl.Marker(el).setLngLat(coor).addTo(myMap);
    });
    myMap?.on("moveend", (e: any) => {
      dispatch(getAddressByCoordinate(e?.target?.transform?._center || {}));
      dispatch(
        setProfile({
          lat: e?.target?.transform?._center.lat,
          lng: e?.target?.transform?._center.lng,
        })
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCurrentLocation = async () => {
    if (lookingLocation) return;
    setLookingLocation(true);
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        const success = (position: any) => {
          setLookingLocation(false);

          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          myMap.flyTo({
            center: [lng, lat],
            essential: true,
          });
        };
        const error = (e: any) => {
          setLookingLocation(false);
          notify.info("مکان شما قابل دسترسی نیست");
        };
        navigator.geolocation.getCurrentPosition(success, error, {});
      });
  };
  return (
    <div
      id="mapCircleLocation"
      className="relative w-full h-full bg-zinc-100 rounded-xl overflow-hidden"
    >
      {/* <div className="absolute left-0 right-0 top-0 bottom-0 m-auto z-10 bg-sky-500 opacity-20 rounded-full h-32 w-32" /> */}
      {/* <img
        src={`../../assets/icons/3.png`}
        className="absolute left-0 right-0 top-0 bottom-0 m-auto z-20 h-12 bg-contain select-none"
        alt="location"
      /> */}
      {/* <div
        className="absolute left-0 -bottom-2 text-center z-40 h-8 w-8 cursor-pointer"
        onClick={handleCurrentLocation}
      >
        <MyLocationSVG color={lookingLocation ? "#f00" : "#000"} size={20} />
      </div> */}
    </div>
  );
}
