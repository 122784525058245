import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

export default function DInput(props: any) {
  const {
    name,
    label,
    color = "gray",
    value,
    type = "text",
    onChange,
    className = "",
  } = props || {};

  const handleOnChange = (e: any) => {
    // e?.target?.value
    // onChange({ name, value: e?.target?.value });
    onChange(e);
  };

  return (
    <div className={`relative ${className}`}>
      <input
        name={name}
        type={type}
        id={`floating_outlined-${name}`}
        className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-${color}-900 bg-transparent rounded-lg border appearance-none focus:outline-none focus:ring-0 focus:border-${color}-600 peer border-${color}-300`}
        placeholder=""
        // defaultValue={defaultValue}
        value={value}
        onChange={handleOnChange}
      />
      <label
        htmlFor={`floating_outlined-${name}`}
        className={`absolute right-2 text-base text-${color}-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-top-right bg-white rounded-full px-2 peer-focus:px-2 peer-focus:text-${color}-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
      >
        {label || ""}
      </label>
    </div>
  );
}
