import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useSelector } from "react-redux";
import { selectProfile } from "src/features/profile/storeSlice";
import { MyLocationSVG, TikIconSVG, TrashIconSVG } from "src/assets/icons";
import { notify } from "../notify";
// detect Locate the user
// myMap.addControl(
//   new mapboxgl.GeolocateControl({
//     positionOptions: {
//       enableHighAccuracy: true,
//     },
//     trackUserLocation: true,
//     showUserHeading: true,
//   })
// );

var myMap: any;
var markers: any = [];
const mapStyle =
  "https://api.parsimap.ir/styles/parsimap-streets-v11?key=p1a8ac21f2cd70490e99eaee23936bb96334e6bd6f";

export function PolygonMap(props: any = {}) {
  const { data = [], coordinatesCallback } = props || {};
  const address = useSelector(selectProfile);
  const [coordinates, setCoordinates] = useState([]);
  const [lookingLocation, setLookingLocation] = useState(false);
  const [isEnableSubmitBtn, setIsEnableSuubmitBtn] = useState(false);
  const isEnableResetBtn = coordinates?.length > 0;
  useEffect(() => {
    try {
      setCoordinates(data || []);
    } catch (e) {
      console.error(e);
    }
  }, [data]);

  useLayoutEffect(() => {
    // تزریق نقشه روی المنت صفحه
    myMap = new mapboxgl.Map({
      container: "mapPolygon",
      style: mapStyle,
      center: [address.lng, address.lat],
      zoom: 12,
    });

    myMap.on("load", () => {
      // نمایش ایکن فروشگاه روی نقشه
      const coor: any = [address?.lng, address?.lat];
      const el = document.createElement("div");
      const width = 32;
      const height = 32;
      el.className = "marker";
      el.style.backgroundImage = `url("../../assets/icons/2.png")`;
      el.style.width = `${width}px`;
      el.style.height = `${height}px`;
      el.style.backgroundSize = "100%";
      new mapboxgl.Marker(el).setLngLat(coor).addTo(myMap);
    });
    myMap.on("click", (e: any) => {
      setCoordinates((prev: any): any => [
        ...prev,
        [e?.lngLat?.lng, e?.lngLat?.lat],
      ]);

      addMarker([e?.lngLat?.lng, e?.lngLat?.lat]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // تنظیم نقشه روی مختصات مرکزی
    if (myMap && address?.lat && address?.lng) {
      myMap.flyTo({
        center: [address.lng, address.lat],
        essential: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (coordinates && coordinates?.length >= 3) {
      drawPolygon(coordinates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  // useLayoutEffect(() => {}, [coordinates]);

  const drawPolygon = async (coors: any) => {
    let loopCoors = coors;
    loopCoors = [...loopCoors, coors[0]];
    const maineSource = myMap.getSource("maine");
    if (maineSource) {
      // update map source
      maineSource?.setData({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [loopCoors],
        },
      });
    } else {
      // create map source
      createLayerAndSources(loopCoors);
    }
  };
  /**
   * create map source
   */
  const createLayerAndSources = (loopCoors = []) => {
    myMap?.addSource("maine", {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [loopCoors],
        },
      },
    });
    myMap.addLayer({
      id: "polyg",
      type: "fill",
      source: "maine", // reference the data source
      layout: {},
      paint: {
        "fill-color": "#0080ff", // blue color fill
        "fill-opacity": 0.1,
      },
    });
    // Add a black outline around the polygon.
    myMap.addLayer({
      id: "outline",
      type: "line",
      source: "maine",
      layout: {},
      paint: {
        "line-color": "#777",
        "line-width": 1,
      },
    });
  };
  const handleCurrentLocation = async () => {
    if (lookingLocation) return;
    setLookingLocation(true);
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        const success = (position: any) => {
          setLookingLocation(false);

          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          myMap.flyTo({
            center: [lng, lat],
            essential: true,
          });
        };
        const error = (e: any) => {
          setLookingLocation(false);
          notify.info("مکان شما قابل دسترسی نیست");
        };
        navigator.geolocation.getCurrentPosition(success, error, {});
      });
  };
  const addMarker = (coor: any) => {
    const el = document.createElement("div");
    const width = 16;
    const height = 16;
    el.className = "marker";
    el.style.backgroundImage = `url("../../assets/icons/1.png")`;
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
    el.style.backgroundSize = "100%";
    markers.push(new mapboxgl.Marker(el).setLngLat(coor).addTo(myMap));
    setIsEnableSuubmitBtn(true);
  };
  const handleResetPolygon = async () => {
    try {
      coordinatesCallback && coordinatesCallback([]);
      setCoordinates([]);
      markers.map((element: any) => element.remove());
      await myMap.removeSource("maine");
      await myMap.removeLayer("polyg");
      await myMap.removeLayer("outline");
      createLayerAndSources([]);
      setIsEnableSuubmitBtn(false);
    } catch (error) {}
  };
  const handleSubmitPolygon = () => {
    coordinatesCallback && coordinatesCallback([...coordinates]);
    setIsEnableSuubmitBtn(false);
  };

  return (
    <div
      // ref={mapRef}
      id="mapPolygon"
      className="relative w-full h-full bg-zinc-100 rounded-xl overflow-hidden"
    >
      <div
        className="absolute left-0 -bottom-2 text-center z-40 h-8 w-8 cursor-pointer"
        onClick={handleCurrentLocation}
      >
        <MyLocationSVG color={lookingLocation ? "#f00" : "#000"} size={20} />
      </div>
      {isEnableResetBtn && (
        <div
          className="absolute bottom-14 right-2 z-10 w-8 h-8 rounded-full flex items-center justify-center bg-red-500 hover:bg-red-700 cursor-pointer"
          onClick={handleResetPolygon}
        >
          <TrashIconSVG color="#fff" size="20" />
        </div>
      )}
      {isEnableSubmitBtn && (
        <div
          onClick={handleSubmitPolygon}
          className="absolute bottom-5 right-2 z-10 w-8 h-8 rounded-full flex items-center justify-center bg-green-600 hover:bg-green-700 text-white cursor-pointer"
        >
          <TikIconSVG color="#fff" size="20" />
        </div>
      )}
    </div>
  );
}
