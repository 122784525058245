export async function setLocalStorage(key, data) {
  asyncLocalStorage.setItem(key, JSON.stringify(data));
}
export async function getLocalStorage(key) {
  return await asyncLocalStorage.getItem(key).then((res) => {
    try {
      if (res) return JSON.parse(res);
    } catch (error) {
      return null;
    }
  });
}
export async function removeLocalStorage(key) {
  asyncLocalStorage.removeItem(key);
}
export async function removeAllLocalStorage() {
  await asyncLocalStorage.removeAll();
  // window.location.assign(window.location.origin);
  // document.location.reload();
}
const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
  removeItem: function (key) {
    return Promise.resolve().then(function () {
      try {
        return localStorage.removeItem(key);
      } catch (error) {
        return;
      }
    });
  },
  removeAll: function () {
    return Promise.resolve().then(function () {
      try {
        return localStorage.clear();
      } catch (error) {
        return;
      }
    });
  },
};
