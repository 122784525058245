import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useAppDispatch } from "src/app/hooks";
import { getAddressByCoordinate } from "src/controllers/mapController";
import { useSelector } from "react-redux";
import { selectProfile, setProfile } from "src/features/profile/storeSlice";
import { MyLocationSVG } from "src/assets/icons";
import { notify } from "../notify";

var myMap: any;
export function MapExample() {
  const mapRef: any = useRef();
  const address = useSelector(selectProfile) || {};
  const dispatch = useAppDispatch();
  const [lookingLocation, setLookingLocation] = useState(false);
  useEffect(() => {
    if (!myMap) return;
    if (address.lat && address.lng) {
      myMap.flyTo({
        center: [address.lng, address.lat],
        essential: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.lat, address.lng]);

  useLayoutEffect(() => {
    myMap = new mapboxgl.Map({
      container: "mapExample",
      style:
        "https://api.parsimap.ir/styles/parsimap-streets-v11?key=p1a8ac21f2cd70490e99eaee23936bb96334e6bd6f",
      center: [address.lng, address.lat],
      zoom: 15,
    });
    if (!myMap) return;
    myMap?.on("moveend", (e: any) => {
      dispatch(getAddressByCoordinate(e?.target?.transform?._center || {}));
      dispatch(
        setProfile({
          lat: e?.target?.transform?._center.lat,
          lng: e?.target?.transform?._center.lng,
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCurrentLocation = async () => {
    if (lookingLocation) return;
    setLookingLocation(true);
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        const success = (position: any) => {
          setLookingLocation(false);

          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          myMap.flyTo({
            center: [lng, lat],
            essential: true,
          });
        };
        const error = (e: any) => {
          setLookingLocation(false);
          notify.info("مکان شما قابل دسترسی نیست");
        };
        navigator.geolocation.getCurrentPosition(success, error, {});
      });
  };
  return (
    <div
      ref={mapRef}
      id="mapExample"
      className="relative w-full h-52 bg-zinc-100 rounded-xl overflow-hidden"
    >
      {/* <Map
        ref={mapContainerRef}
        style="https://www.parsimap.ir/styles/street.json"
        containerStyle={{ height: "100vh", width: "100vw" }}
        center={center}
        zoom={[zoom]}
        onDragEnd={handleSetPosition}
      ></Map>*/}
      <img
        src="https://cdn.parsimap.ir/icons/map-marker.png"
        className="absolute left-0 right-0 mx-auto top-0 bottom-0 my-auto text-center z-40 h-8 w-6 select-none"
        alt="location"
      />
      <div
        className="absolute left-0 -bottom-2 text-center z-40 h-8 w-8 cursor-pointer"
        onClick={handleCurrentLocation}
      >
        <MyLocationSVG color={lookingLocation ? "#f00" : "#000"} size={20} />
      </div>
    </div>
  );
}
