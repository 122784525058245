import React, { useEffect, useRef } from "react";
import { CloseIconSVG } from "src/assets/icons";
import useOutsideClick from "src/hooks/useOutSideClick";

const Modal = (props: any) => {
  const { children, close, className = "" } = props || {};
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, close);

  useEffect((): any => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);
  return (
    <div className="backdrop-blur backdrop-brightness-50 overflow-hidden fixed top-0 left-0 bottom-0 right-0 z-50 md:inset-0">
      <div className="relative flex justify-center items-center h-screen">
        <div
          className="relative bg-white rounded-lg shadow w-fit h-fit flex"
          ref={wrapperRef}
        >
          <button
            type="button"
            className="absolute top-3 left-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={close}
          >
            <CloseIconSVG />
          </button>
          <div className={`py-6 px-4 lg:px-6 ${className}`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
