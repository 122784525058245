import React, { useContext, useEffect, useState } from "react";
import {
  ArrowIcon,
  BagIconSVG,
  ChatLogoIconSVG,
  CircleInfoIcon,
  ClockIconSVG,
  EyeIconSVG,
  FactorSearchIconSVG,
  LocationIconSVG,
  LockBtnIcon,
  MessageTextIconSVG,
  ReceptFactorIconSVG,
} from "src/assets/icons";
import { FactoringModal } from "src/components/modals/FactoringModal";
import { Tabs } from "src/components/tabs/Tab";
import MainLayout from "../main/mainLayout";
import {
  countDownTimerPure,
  returnTimeColor,
  toStrDatetime,
} from "src/utils/time";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteItemOrderList,
  selectOrderList,
  setOrderActiveTab,
} from "./ordersSlice";
import { ConfirmPaymentModal } from "src/components/modals/ConfirmPaymentModal";
import {
  deleteNotificatoin,
  selectNotifiication,
} from "../main/notificationSlice";
import { SocketContext } from "src/api/socketContext";
import { getStoreStatus } from "src/utils";
import { selLoading, setLoading } from "../main/mainSlice";
import Loading from "src/components/loading/Loading";
import { useParams } from "react-router-dom";
import { getLocalStorage } from "src/utils/localstorage";
import { FullItemInList } from "../main/fullItemInOrderList";
import { ModalStatus, ORDER_MENU_STATIC_DATA } from "../main/constants";
import { TimerCountDown } from "src/components/timer/TimerCountDown";
import EmptyPage from "../empty/EmptyPage";
import { EmptyComponent } from "src/components/empty/emptyComponent";
import { BasketIcon } from "src/assets/icons";

export const Orders = () => {
  const { pageName } = useParams();
  const [tabData, setTabData] = useState<any>(ORDER_MENU_STATIC_DATA);
  const [selectTab, setSelectTab] = useState<any>(tabData[0]);
  const [list, setList] = useState<any>([]);
  const state = useSelector(selectOrderList);
  const selNotification = useSelector(selectNotifiication);
  const isLoading = useSelector(selLoading);
  const ws: any = useContext(SocketContext);
  const dispatch = useDispatch();
  const [isOpenFactoringModal, setIsOpenFactoringModal] = useState<any>();
  const [paymentModalStatus, setPaymentModalStatus] =
    useState<ModalStatus>("close");

  useEffect(() => {
    if (pageName === "main") {
      handleClickTab(tabData?.find((i: any) => i.type === "sentToStore"));
      // handleClickTab(tabData[0]);
    } else if (pageName === "inproccess") {
      handleClickTab(tabData?.find((i: any) => i.type === "acceptByUser"));
      // handleClickTab(tabData[5]);
    } else if (pageName === "sending") {
      handleClickTab(tabData?.find((i: any) => i.type === "sending"));
      // handleClickTab(tabData[7]);
    } else if (pageName === "report") {
      handleClickTab(tabData?.find((i: any) => i.type === "rejectedByStore"));
      // handleClickTab(tabData[4]);
    } else {
      // setSelectTab(tabData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName]);
  useEffect((): any => {
    setTimeout(() => {
      dispatch(deleteNotificatoin(selectTab.type));
    }, 1000);

    return () => dispatch(deleteNotificatoin(selectTab.type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTab]);

  useEffect(() => {
    try {
      setTabData((prev) =>
        prev.map((i) => ({ ...i, notif: selNotification[i.type] || 0 }))
      );
    } catch (error) {}
  }, [selNotification]);

  useEffect(() => {
    if (paymentModalStatus === "success" && isOpenFactoringModal) {
      return;
    }
    setList(state?.invoices);
    // if (selectTab?.id === 1) {
    //   setList(state?.invoices);
    //   return;
    // }
    // setList(
    //   state?.invoices.filter((item: any) => item?.status === selectTab?.id)
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.invoices]);

  const deleteItemList = (props: any) => {
    setList(
      (prev: any) => prev?.filter((i: any) => i?.uuid !== props?.uuid) || []
    );
    dispatch(deleteItemOrderList(props));
    dispatch(deleteNotificatoin("new_invoice"));
  };
  const handleMinusMoney = () => {
    setPaymentModalStatus("success");
    ws.sendMessage("minus_money", isOpenFactoringModal);
  };
  const handleOpenFactor = async (item: any) => {
    setIsOpenFactoringModal(item.uuid);
    const isHidePModal = await getLocalStorage("isHidePaymentModal");
    // need payment
    if (item.status === 1) {
      if (isHidePModal) {
        setPaymentModalStatus("success");
        ws.sendMessage("minus_money", item.uuid);
      } else {
        setPaymentModalStatus("open");
      }
    } else {
      //todo: fixbug
      ws.sendMessage("minus_money", item.uuid);
      setPaymentModalStatus("success");
    }
  };
  const handleClickTab = (item: any) => {
    // dispatch(setLoading(true));
    setList([]);
    setSelectTab(item);
    if (item.id === 1) {
      ws.sendMessage("get_invoice", 1, 100);
    } else {
      ws.sendMessage("send_invoice_with_status", 1, 1000, item.id);
    }
    switch (item.id) {
      case 1:
        dispatch(setOrderActiveTab("sentToStore"));
        break;
      case 2:
        dispatch(setOrderActiveTab("seenByStore"));
        break;
      case 3:
        dispatch(setOrderActiveTab("changedByStore"));
        break;
      case 4:
        dispatch(setOrderActiveTab("acceptedByStore"));
        break;
      case 5:
        dispatch(setOrderActiveTab("rejectedByStore"));
        break;
      case 6:
        dispatch(setOrderActiveTab("acceptByUser"));
        break;
      case 7:
        dispatch(setOrderActiveTab("rejectedByUser"));
        break;
      case 8:
        dispatch(setOrderActiveTab("rejectedByUserBecauseOtherSeller"));
        break;
      case 11:
        dispatch(setOrderActiveTab("sending"));
        break;
      default:
        break;
    }
  };
  const handleOpenFactorModalWithChat = (item) => {
    handleOpenFactor(item);
  };

  return (
    <MainLayout>
      {pageName === "main" ? (
        <Tabs
          data={tabData.filter((i) => i.isShow)}
          selectItem={selectTab}
          onClick={handleClickTab}
        />
      ) : (
        <></>
      )}
      <div
        className={`mt-2 w-full rounded p-4 pb-8 relative overflow-y-scroll h-full  ${
          list.length ? "bg-white" : "bg-gray-50"
        }`}
      >
        {selectTab.id === 1 && (
          <>
            <div className="flex items-center justify-start">
              <CircleInfoIcon color={"#F29423"} />
              <div className="mr-3 text-xs font-light truncate">
                با پذیرفتن هر سفارش مبلغ 1000 تومان از کیف پول کسر می گردد.
              </div>
            </div>
            <div className="h-2" />
          </>
        )}
        {list.length ? (
          list.map((item: any = {}) => {
            const sumReducer = (sum: any, val: any) => {
              return sum + val.price;
            };
            const price = item?.order?.order_products?.reduce(sumReducer, 0);
            const orderStatus = getStoreStatus(item?.status);
            const isUnreadFactor = item?.status === 1;
            //todo: true => item?.status === 2 || ...
            const isShowTimer =
              item?.status === 1 || item?.status === 2 || item?.status === 4;
            const expireTimer =
              item?.status === 1 || item?.status === 2
                ? item.expire_at
                : item?.status === 4
                ? item.change_expire_at
                : null;

            if (
              item.status === 4 ||
              item.status === 6 ||
              item.status === 7 ||
              item.status === 11
            ) {
              return (
                <FullItemInList
                  key={`item-in-full-list${item.uuid}`}
                  handleOpenFactor={() => handleOpenFactor(item)}
                  handleOpenChatSide={handleOpenFactorModalWithChat}
                  {...item}
                />
              );
            }
            return (
              <div
                className={`w-full h-32 border-2 rounded-md mt-2 pt-2 grid grid-rows-3  ${
                  isUnreadFactor
                    ? "bg-sky-50 border-sky-100 font-bold"
                    : "bg-white border-gray-100 font-normal"
                }`}
                key={`item-in-list${item.uuid}`}
              >
                <div className="row-span-2 flex text-xs">
                  <div className="flex-grow grid grid-cols-2 gap-0 grid-rows-2">
                    <div className="flex items-center justify-start mr-2">
                      <div className="">تاریخ سفارش: </div>
                      <div className="h-6 w-fit px-2 text-center mr-2 flex items-center justify-around">
                        {toStrDatetime(new Date(item?.created_at).getTime())}
                      </div>
                    </div>
                    <div className="flex items-center justify-start">
                      <ReceptFactorIconSVG color="#A1A1AA" size={20} />
                      <div className=" mx-2">اقلام فاکتور: </div>
                      <div className="text-blue-900 py-0.5 px-2 ">
                        {item?.quantity} عدد
                      </div>
                    </div>
                    <div className="flex items-center justify-start mr-2">
                      <div className="">شماره فاکتور: </div>
                      <div className="h-6 w-fit px-2  font-light text-center mr-2 flex items-center justify-around">
                        {item?.id}
                      </div>
                    </div>
                    <div className="flex items-center justify-start">
                      <ClockIconSVG color="#A1A1AA" />
                      <div className="mx-2">مبلغ فاکتور: </div>
                      <div className="text-blue-900 py-0.5 px-2 ">
                        {item.price?.toLocaleString()}تومان
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`row-span-1 flex items-center justify-end rounded-b-md ${
                    isUnreadFactor ? "bg-sky-100" : "bg-gray-100 "
                  }`}
                >
                  {item?.status === 1 && (
                    <div className="px-2">
                      <LockBtnIcon />
                    </div>
                  )}
                  {item?.status === 2 && (
                    <div className="px-2">
                      <EyeIconSVG />
                    </div>
                  )}
                  <div
                    className={`flex-grow text-xs ${orderStatus?.color} ${
                      isUnreadFactor ? "font-bold" : "font-normal"
                    }`}
                  >
                    {orderStatus?.name}
                  </div>
                  {item?.status === 2 && (
                    <div
                      onClick={() => handleOpenFactorModalWithChat(item)}
                      className="text-xs text-sky-700 px-2 flex justify-start items-center cursor-pointer"
                    >
                      <div>
                        <ChatLogoIconSVG color="#2563EB" size={24} />
                      </div>
                      <div>
                        <b>چت شاپینگ</b> ( ارسال پیام و پیگیری سفارش)
                      </div>
                    </div>
                  )}
                  <div className=" flex items-center justify-end">
                    {isShowTimer && (
                      <TimerCountDown
                        close={() => deleteItemList(item)}
                        expire_at={expireTimer}
                        className=""
                      />
                    )}
                    <div
                      onClick={() => handleOpenFactor(item)}
                      className={`py-1.5 px-2 rounded-md cursor-pointer  mx-1 w-28 flex items-center justify-around text-white ${
                        isUnreadFactor ? "bg-green-500" : "bg-sky-500 "
                      }`}
                    >
                      <div
                        className={`ml-2 text-xs truncate ${
                          isUnreadFactor ? "font-bold" : "font-normal"
                        }`}
                      >
                        {orderStatus?.btnTitle || "بررسی فاکتور"}
                      </div>
                      <ArrowIcon color="#fff" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : isLoading ? (
          <div className="flex items-center justify-center">
            {/* <Loading /> */}
          </div>
        ) : (
          <EmptyComponent title="سفارشی وجود ندارد ..." />
        )}
      </div>

      {paymentModalStatus === "open" ? (
        <ConfirmPaymentModal
          close={() => setPaymentModalStatus("close")}
          confirm={handleMinusMoney}
        />
      ) : (
        <></>
      )}
      {isOpenFactoringModal && paymentModalStatus === "success" ? (
        <FactoringModal
          close={() => {
            handleClickTab(selectTab);
            setIsOpenFactoringModal(false);
          }}
          data={state?.invoices?.find(
            (i: any) => i?.uuid === isOpenFactoringModal
          )}
        />
      ) : (
        <></>
      )}
    </MainLayout>
  );
};

export default Orders;
