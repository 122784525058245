import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { SocketContext } from "src/api/socketContext";
import {
  ArrowIcon,
  ChatLogoIconSVG,
  ClockIconSVG,
  DangerBtnIcon,
  FactorSearchIconSVG,
  LocationIconSVG,
  MessageTextIconSVG,
  MoneyIconSVG,
  ReceptFactorIconSVG,
} from "src/assets/icons";
import { RateModal } from "src/components/modals/RateModal";
import { ReadOnlyFactorModal } from "src/components/modals/ReadOnlyFactorModal";
import { SelectDeliveryModal } from "src/components/modals/SelectDeliveryModal";
import { SimpleMapModal } from "src/components/modals/SimpleMapModal";
import { TimerCountDown } from "src/components/timer/TimerCountDown";
import { getStoreStatus } from "src/utils";
import { toStrDatetime } from "src/utils/time";
import { selectProfile } from "src/features/profile/storeSlice";
import { useAppDispatch } from "src/app/hooks";
import { postFinishedOrder } from "src/controllers/orderController";
import { notify } from "src/components/notify";

export const FullItemInList = (props: any) => {
  const [isOpenSelectDelivery, setIsOpenSelectDelivery] = useState(false);
  const [isOpenRateModal, setIsOpenRateModal] = useState(false);
  const [isOpenSimpleMapModal, setIsOpenSimpleMapModal] = useState(false);
  const [isOpenFactorModal, setIsOpenFactorModal] = useState(false);
  const profile = useSelector(selectProfile);
  const dispatch = useAppDispatch();

  const {
    created_at,
    quantity,
    id,
    price,
    status,
    order,
    items,
    is_changed_price = false,
    is_changed_product = false,
    handleOpenFactor,
    change_expire_at,
    expire_at,
    bike_delivery,
    handleOpenChatSide,
  } = props || {};
  const ws: any = useContext(SocketContext);

  const {
    consumer = {},
    order_addresses = {},
    order_products = [],
  } = order || {};
  const coordinates = [
    { lat: profile.lat, lng: profile.lng },
    { lat: order_addresses.lat, lng: order_addresses.lng },
  ];
  //   const totlaPayment = order_products?.reduce(
  //     (sum: number, li: any) => sum + li.price * li.quantity,
  //     0
  //   );

  const isRejectByUser = status === 7;
  const isUserPending = status === 4;
  const isBike = status === 11;
  const orderStatus = getStoreStatus(status);
  const handleClick = () => {
    if (status === 6) {
      toggleOpenBikeDelivery();
    } else if (status === 4) {
      handleOpenFactor();
    } else if (status === 11) {
      dispatch(
        postFinishedOrder(props.uuid, (res) => {
          if (res) {
            ws?.sendMessage("send_invoice_with_status", 1, 1000, 11);
          } else {
            notify.error("لطفا مجددا تلاش کنید");
          }
        })
      );
      // setIsOpenRateModal(true);
    }
  };
  const toggleOpenBikeDelivery = () => {
    //todo: open simple map modal
    // setIsOpenSimpleMapModal((prev) => !prev);
    //todo: open select delivery byce
    setIsOpenSelectDelivery((prev) => !prev);
    //todo: open rate modal
    // setIsOpenRateModal((prev) => !prev);
  };
  const handleRefreshList = () => {};
  const handleOpenFactorModal = () => {
    setIsOpenFactorModal(true);
    ws.sendMessage("minus_money", props.uuid);
  };

  return (
    <div
      className={`w-full h-fit border-2 rounded-md mt-2 pt-2 grid grid-rows-3 grid-cols-12 gap-0 bg-white text-xs ${
        isRejectByUser ? "border-red-200 gap-1" : "border-gray-100"
      } ${isBike && "grid-rows-4"}`}
    >
      <div className="flex items-center justify-start col-span-2 mr-2">
        <div className="text-gray-500">شماره فاکتور: </div>
        <div className="h-6 w-fit px-2  font-light text-center mr-2 flex items-center justify-around">
          {id}
        </div>
      </div>
      <div className="flex items-center justify-start col-span-5">
        <div className="text-gray-500">تاریخ سفارش: </div>
        <div className="h-6 w-fit px-2 text-center mr-2 flex items-center justify-around">
          {toStrDatetime(new Date(created_at).getTime())}
        </div>
      </div>
      <div className="flex items-center justify-start col-span-5">
        <FactorSearchIconSVG color="#A1A1AA" />
        <div className=" mx-2 text-gray-500">وضعیت فاکتور:</div>
        {is_changed_price && (
          <div className="bg-sky-50 rounded-xl px-2 py-1 text-sky-800 mx-1">
            اصلاح قیمت
          </div>
        )}
        {is_changed_product && (
          <div className="bg-red-50 rounded-xl px-2 py-1 text-red-800 mx-1">
            تعویض کالا
          </div>
        )}
        {!is_changed_price && !is_changed_product && (
          <div className="bg-green-50 rounded-xl px-2 py-1 text-green-800 mx-1">
            بدون تغییر
          </div>
        )}
      </div>
      <div className="flex items-center justify-start col-span-2 mr-2">
        <div className="text-gray-500">شماره اشتراک :</div>
        <div className="mx-1">{0}</div>
      </div>
      <div className="flex items-center justify-start col-span-5">
        <div className="text-gray-500">مشتری :</div>
        <div className="flex mx-1">
          {consumer.first_name} {consumer.last_name}
        </div>
      </div>
      <div className="flex items-center justify-start col-span-2">
        <ReceptFactorIconSVG color="#A1A1AA" size={20} />
        <div className=" mx-2 text-gray-500">اقلام سفارش: </div>
        <div className="text-blue-900 py-0.5 px-2 ">{order.quantity} عدد</div>
      </div>
      <div className="flex items-center justify-start col-span-3">
        <ReceptFactorIconSVG color="#A1A1AA" size={20} />
        <div className=" mx-2 text-gray-500">اقلام فاکتور: </div>
        <div className="text-blue-900 py-0.5 px-2 ">{quantity} عدد</div>
      </div>
      <div className="flex items-center justify-start col-span-2 mr-2">
        <div className="text-gray-500">تلفن:</div>
        <div className="mx-1">{consumer?.telephone}</div>
      </div>
      <div className="flex items-center justify-start col-span-5">
        <div className="min-w-max text-gray-500">آدرس تحویل:</div>
        <div className="mx-1 truncate">{order_addresses?.description}</div>
      </div>
      <div className="flex items-center justify-start col-span-5">
        <MoneyIconSVG color="#A1A1AA" />
        <div className="mx-1 min-w-max text-gray-500">مبلغ سفارش: </div>
        <div className="text-blue-900 py-0.5 ml-3 ">
          {order?.price?.toLocaleString()}تومان
        </div>
        <MoneyIconSVG color="#A1A1AA" />
        <div className="mx-1 min-w-max text-gray-500">مبلغ فاکتور: </div>
        <div className="text-blue-900 py-0.5 px-2 ">
          {price?.toLocaleString()}تومان
        </div>
      </div>
      {isBike && (
        <div className="flex items-center justify-start col-span-3 w-full mr-2">
          <div className="flex items-center">
            <div className="text-gray-500">پیک موتوری:</div>
            <div className="mx-1">
              {bike_delivery?.bike_delivery_info?.first_name}
            </div>
            <div>{bike_delivery?.bike_delivery_info?.last_name}</div>
          </div>
        </div>
      )}
      {isBike && (
        <div className="flex items-center justify-start col-span-9 w-full">
          <div className="flex items-center">
            <div className="text-gray-500">تلفن:</div>
            <div className="mx-2">
              {bike_delivery?.bike_delivery_info?.user_info?.phone}
            </div>
          </div>
        </div>
      )}
      {/* <div className="flex items-center justify-start col-span-2">
        <MoneyIconSVG color="#A1A1AA" />
        <div className="mx-1 min-w-max">مبلغ فاکتور: </div>
        <div className="text-blue-900 py-0.5 px-2 ">
          {price?.toLocaleString()}تومان
        </div>
      </div> */}
      <div
        className={`flex items-center justify-between col-span-12 py-1 ${
          isRejectByUser ? "bg-red-100" : "bg-gray-100"
        }`}
      >
        {isRejectByUser || isUserPending ? (
          <div className="flex items-center justify-start text-eleven">
            <div
              className={`mx-2 flex items-center cursor-pointer ${
                isRejectByUser
                  ? "text-red-500"
                  : isUserPending
                  ? "text-blue-900"
                  : ""
              }`}
            >
              {isRejectByUser && <DangerBtnIcon color="#DE2B2B" size={16} />}
              {isUserPending && <ClockIconSVG color="#1E3A8A" size={16} />}
              <div className="mr-1 py-1">
                {isRejectByUser
                  ? "رد شده توسط مشتری"
                  : isUserPending
                  ? "در انتظار تایید مشتری"
                  : ""}
              </div>
            </div>
            <div
              onClick={() => handleOpenChatSide(props)}
              className="text-xs text-sky-700 px-2 flex justify-start items-center cursor-pointer"
            >
              <div>
                <ChatLogoIconSVG color="#2563EB" size={24} />
              </div>
              <div>
                <b>چت شاپینگ</b> ( ارسال پیام و پیگیری سفارش)
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-start text-eleven">
            <div
              className="mx-2 text-sky-500 flex items-center cursor-pointer"
              onClick={() => setIsOpenSimpleMapModal(true)}
            >
              <LocationIconSVG color="#0ea5e9" className="h-3 w-3" />
              <div className="mr-1">نمایش روی نقشه</div>
            </div>
            <div
              onClick={handleOpenFactorModal}
              className="mx-2 text-sky-900 flex items-center cursor-pointer"
            >
              <ReceptFactorIconSVG color="#0c4a6e" size={12} />
              <div className="mr-1">لیست سفارشات</div>
            </div>
            <div className="mx-2 text-green-600 flex items-center cursor-pointer">
              <MessageTextIconSVG color="#16a34a" size={12} />
              <div className="mr-1">ارسال پیام و پیگیری</div>
            </div>
          </div>
        )}
        {isRejectByUser ? (
          <div className="h-7"></div>
        ) : (
          <div className=" flex items-center justify-end">
            {isUserPending && (
              <TimerCountDown
                close={handleRefreshList}
                expire_at={change_expire_at}
                className=""
              />
            )}
            <div
              onClick={handleClick}
              className={`py-1.5 px-2 rounded-md cursor-pointer mx-1 w-28 flex items-center justify-around text-white  ${
                orderStatus?.bgBtnColor || "bg-sky-500"
              }`}
            >
              <div className={`ml-2 text-xs truncate font-normal`}>
                {orderStatus?.btnTitle || "بررسی فاکتور"}
              </div>
              {isBike ? <></> : <ArrowIcon color="#fff" />}
            </div>
          </div>
        )}
      </div>
      {isOpenFactorModal && (
        <ReadOnlyFactorModal
          data={props}
          close={() => setIsOpenFactorModal(false)}
        />
      )}
      {isOpenRateModal && <RateModal close={() => setIsOpenRateModal(false)} />}
      {isOpenSimpleMapModal && (
        <SimpleMapModal
          close={() => setIsOpenSimpleMapModal(false)}
          coordinates={coordinates || []}
        />
      )}
      {isOpenSelectDelivery && (
        <SelectDeliveryModal
          close={() => setIsOpenSelectDelivery(false)}
          data={props}
        />
      )}
    </div>
  );
};
