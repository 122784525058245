import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { CircleInfoIcon } from "src/assets/icons";
import { selectProfile, setProfile } from "src/features/profile/storeSlice";
import Modal from "./Modal";
import { putProfile } from "src/controllers/ProfileController";
import { notify } from "../notify";
import { cheeckFirstPayment } from "src/controllers/WalletController";
import { MapExample } from "../maps/mapExample";
import useOutsideClick from "src/hooks/useOutSideClick";
import { removePreCodeInNumber } from "src/utils";
import { selectUserAccess, setStep } from "src/features/main/mainSlice";

export const ProfileModal = (props: any) => {
  const dispatch: any = useDispatch();
  const selProfile: any = useSelector(selectProfile);
  const selUserAccess = useSelector(selectUserAccess);
  const [suggest, setSuggest] = useState([]);
  const [searchText, setSearchText] = useState("");
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setSuggest([]));

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length > 1) {
        handleSearch();
      } else if (searchText.length === 0) {
        // setList([]);
      }
    }, 1700);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const formik: any = useFormik({
    initialValues: {
      shop_name: "",
      register_id: "",
      mobile: "",
      phone: "",
      shop_manager_name: "",
      shop_owner_name: "",
      address: selProfile.address || "",
    },
    validationSchema: Yup.object().shape({
      shop_name: Yup.string().trim().required("وارد کردن نام فروشگاه الزامیست"),
      register_id: Yup.string().trim(),
      mobile: Yup.string().trim(),
      shop_manager_name: Yup.string()
        .trim()
        .required("وارد کردن این فیلد الزامیست"),
      shop_owner_name: Yup.string()
        .trim()
        .required("وارد کردن این فیلد الزامیست"),
      address: Yup.string().trim().required("وارد کردن این فیلد الزامیست"),
      phone: Yup.string()
        .trim()
        .required("وارد کردن این فیلد الزامیست")
        .length(11, "تعداد کاراکترها درست نیست")
        .matches(/^[0-9۰-۹]*$/, "لطفا از اعداد استفاده کنید"),
      // .test(
      //   "no-leading-zero",
      //   "Leading zero is not allowed",
      //   (value, context: any) => {
      //     const { originalValue = "" } = context || {};
      //     return originalValue && !originalValue.startsWith("0");
      //   }
      // ),
    }),
    onSubmit: (values) => {
      const resetAndClose = (result: boolean) => {
        if (result) {
          notify.info("با موفقیت آپدیت شد");
          dispatch(cheeckFirstPayment());
          formik.resetForm();
        } else {
          notify.error("خطا در عملیات");
        }
      };
      dispatch(putProfile(values, resetAndClose));
    },
  });

  useEffect(() => {
    Object.keys(selProfile).map((name) => {
      const value = selProfile[name];
      value && formik.setFieldValue(name, value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selProfile]);

  const handleCloseModal = () => {
    // dispatch(setStep("block"));
    if (selUserAccess === "editProfile") {
      dispatch(setStep("access"));
    } else if (selUserAccess === "needProfile") {
      notify.warning("تکمیل اطلاعات کاربر الزامی میباشد");
    }
  };

  const handleClickAddressList = async (item: any) => {
    setSuggest([]);
    formik.handleChange({
      target: { name: "address", value: item.description },
    });
    const { lat, lng } = item.geo_location.center;
    dispatch(setProfile({ lat, lng }));
  };

  const handleSearch = async () => {
    const res: any = await fetch(
      `https://api.parsimap.ir/geocode/forward?key=p1e35220f589044556a90a0971ec5a8fe14d45670d&search_text=${searchText
        .toString()
        .trim()}&district=10511133&only_in_district=true&subdivision=false&plate=false&request_id=false&search_precision=simple`
    ).then((rr: any) => rr.text());
    const objResult = await JSON.parse(res);
    //todo: set to redux
    setSuggest(objResult?.results || []);
  };

  return (
    <Modal close={handleCloseModal}>
      <div className="relative overflow-hidden py-6 h-fit max-h-screen md:w-h600">
        <h3 className="mb-4 text-xl font-medium text-indigo-900">
          اطلاعات فروشگاه
        </h3>
        <div className="flex items-center justify-start">
          <CircleInfoIcon color={"#F29423"} />
          <h4 className="mx-2 text-zinc-600">
            برای تکمیل اطلاعات ، مشخصات فروشگاه را ثبت نمایید.
          </h4>
        </div>
        <form
          className="grid grid-cols-6 gap-4 mt-4 max-h-h600"
          onSubmit={formik.handleSubmit}
        >
          <div className="col-span-3">
            <label
              htmlFor="shop_name"
              className="flex text-sm font-medium text-gray-900 "
            >
              <div>نام فروشگاه </div>
              <div className="text-red-500 mt-1 mx-2">*</div>
            </label>
            <input
              id="shop_name"
              name="shop_name"
              type="text"
              className={`bg-gray-50 border focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 ${
                formik.errors.shop_name ? "border-red-300" : "border-gray-300"
              }`}
              onChange={formik.handleChange}
              value={formik.values.shop_name}
            />
            {/* <span className="text-red-500 text-sm">
              {formik.errors.shop_name}
            </span> */}
          </div>
          <div className="col-span-3">
            <label
              htmlFor="register_id"
              className="flex text-sm font-medium text-gray-900 "
            >
              <div>شناسه ثبت</div>
              <div className="text-red-500 mt-1 mx-2"></div>
            </label>
            <input
              type="text"
              name="register_id"
              id="register_id"
              className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 ${
                formik.errors.register_id ? "border-red-300" : "border-gray-300"
              }`}
              onChange={formik.handleChange}
              value={formik.values.register_id}
            />
            {/* <span className="text-red-500 text-sm">
              {formik.errors.register_id}
            </span> */}
          </div>
          <div className="col-span-3">
            <label
              htmlFor="mobile"
              className="flex text-sm font-medium text-gray-900 "
            >
              <div>تلفن همراه</div>
              <div className="text-red-500 mt-1 mx-2">*</div>
            </label>
            <input
              type="text"
              name="mobile"
              id="mobile"
              disabled
              className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:outline-none block w-full p-2.5"
              value={removePreCodeInNumber(formik.values.mobile)}
            />
          </div>
          <div className="col-span-3">
            <label
              htmlFor="phone"
              className="flex text-sm font-medium text-gray-900 "
            >
              <div>شماره تلفن فروشگاه</div>
              <div className="text-red-500 mt-1 mx-2">*</div>
            </label>
            <input
              type="text"
              name="phone"
              id="phone"
              className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 ${
                formik.errors.phone ? "border-red-300" : "border-gray-300"
              }`}
              onChange={formik.handleChange}
              value={formik.values.phone}
              placeholder="021--------"
            />
            {/* <span className="text-red-500 text-sm">{formik.errors.phone}</span> */}
          </div>
          <div className="col-span-3">
            <label
              htmlFor="shop_manager_name"
              className="flex text-sm font-medium text-gray-900 "
            >
              <div>نام و نام خانوادگی مدیر فروشگاه</div>
              <div className="text-red-500 mt-1 mx-2">*</div>
            </label>
            <input
              type="text"
              name="shop_manager_name"
              id="shop_manager_name"
              className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 ${
                formik.errors.shop_manager_name
                  ? "border-red-300"
                  : "border-gray-300"
              }`}
              onChange={formik.handleChange}
              value={formik.values.shop_manager_name}
            />
            {/* <span className="text-red-500 text-sm">
              {formik.errors.shop_manager_name}
            </span> */}
          </div>
          <div className="col-span-3">
            <label
              htmlFor="shop_owner_name"
              className="flex text-sm font-medium text-gray-900 "
            >
              <div>نام و نام خانوادگی مالک فروشگاه</div>
              <div className="text-red-500 mt-1 mx-2">*</div>
            </label>
            <input
              type="text"
              name="shop_owner_name"
              id="shop_owner_name"
              className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 ${
                formik.errors.shop_owner_name
                  ? "border-red-300"
                  : "border-gray-300"
              }`}
              onChange={formik.handleChange}
              value={formik.values.shop_owner_name}
            />
            {/* <span className="text-red-500 text-sm">
              {formik.errors.shop_owner_name}
            </span> */}
          </div>

          <div className="col-span-6 relative">
            <label
              htmlFor="address"
              className="flex text-sm font-medium text-gray-900 "
            >
              <div>آدرس دقیق فروشگاه</div>
              <div className="text-red-500 mt-1 mx-2">*</div>
            </label>
            <input
              type="text"
              name="address"
              id="address"
              autoComplete="off"
              className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 ${
                formik.errors.address ? "border-red-300" : "border-gray-300"
              }`}
              onChange={(e) => {
                formik.handleChange(e);
                setSearchText(e.target.value);
              }}
              value={formik.values.address}
            />
            {/* <span className="text-red-500 text-sm">
              {formik.errors.address}
            </span> */}
            {/*
                //todo: change to public component
            */}
            {suggest.length ? (
              <div
                ref={wrapperRef}
                className="z-50 bg-white rounded-lg mt-2 shadow-lg fixed overflow-auto w-96 h-fit max-h-72 border border-zinc-300"
              >
                {suggest?.map((item: any) => {
                  return (
                    <div
                      onClick={() => handleClickAddressList(item)}
                      className="text-sm p-4 border-b border-zinc-300 cursor-pointer hover:bg-zinc-100"
                    >
                      {item.description}
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="col-span-6 h-fit relative">
            <MapExample />
            {/* <img
              src={FAKE_IMAGE}
              alt="fake"
              className="rounded-xl bg-contain h-40 w-full blur-sm"
            />
            <div className="absolute top-1/3 left-1/3 bg-white text-sky-600 rounded-lg border shadow border-zinc-200 cursor-pointer p-3 flex items-center justify-around">
              <LocationIconSVG color="#0EA5E9" />
              <div className="mx-2">انتخاب از روی نقشه</div>
            </div> */}
          </div>

          <button
            type="submit"
            className="col-start-2 col-span-4 my-1 w-full text-white bg-indigo-800 hover:bg-indigo-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            ثبت اطلاعات
          </button>
        </form>
      </div>
    </Modal>
  );
};
