import { useEffect, useState } from "react";
import { DropdownTopLabel } from "src/components/dropdown/DropdownTopLabel";
import { generateID } from "src/utils";

const allTimesInDay = (startIndex: number, endIndex: number) => {
  return new Array(endIndex - startIndex).fill(null).map((_, index) => ({
    uuid: index + startIndex,
    name: `00 : ${index + startIndex}`,
  }));
};
const allTimes = new Array(24)
  .fill(null)
  .map((_, index) => ({ uuid: index, name: `00 : ${index}` }));

const defaultList = {
  id: generateID(10),
  from: {
    selectTimeIndex: 1,
    allTimes: allTimes,
  },
  to: {
    selectTimeIndex: 1,
    allTimes: allTimes,
  },
};

export const SelectTimeComponent = (props: any) => {
  const { data = [], onChangeListener }: any = props || {};
  const [times, setTimes] = useState<any>([]);
  useEffect(() => {
    setTimes(
      data.map((_item) => {
        const { start, end } = _item || {};
        const startTime = start.split(":")[0];
        const endTime = end.split(":")[0];
        return {
          id: generateID(10),
          from: {
            selectTimeIndex: Number(startTime) || 1,
            allTimes: allTimes,
          },
          to: {
            selectTimeIndex: Number(endTime) || 1,
            allTimes: allTimes,
          },
        };
      })
    );
  }, [data]);

  useEffect(() => {
    onChangeListener(times);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times]);

  const handleAddRow = () => {
    setTimes([...times, { ...defaultList, id: generateID(10) }]);
  };
  const handleRemoveRow = (id: string) => {
    var filterTime = times.filter((t) => t.id !== id);
    setTimes(filterTime);
  };
  const handleOnChange = (name: string, event: any, t: any) => {
    //allTimesInDay(9,21)
    const { value } = event.target;
    const updateTime = times.map((item: any) => {
      if (item.id === t.id) {
        if (name === "from") {
          return {
            ...item,
            to: {
              selectTimeIndex: 0,
              allTimes: allTimesInDay(value + 1, 24),
            },
            [name]: { ...item[name], selectTimeIndex: value },
          };
        } else if (name === "to") {
          return {
            ...item,
            from: {
              ...item.from,
              allTimes: allTimesInDay(0, value - 1),
            },
            [name]: { ...item[name], selectTimeIndex: value },
          };
        }
      } else {
        return item;
      }
    });
    setTimes([...updateTime]);
  };
  return (
    <div className="py-6">
      {times?.map((time, index) => {
        const isShowRemoveBtn = index !== 0;
        return (
          <div
            key={`time-item-${time.id}`}
            className="w-full grid grid-cols-12 gap-2 py-2"
          >
            <div className="relative w-full col-span-5 rounded-lg border border-gray-300 flex items-center justify-center">
              <DropdownTopLabel
                name={"selectStartTime_" + time.id}
                label={"از ساعت"}
                list={time.from?.allTimes || []}
                selectId={time.from?.selectTimeIndex}
                onChange={(e) => handleOnChange("from", e, time)}
              />
            </div>
            <div className="relative w-full col-span-5 rounded-lg border border-gray-300 flex items-center justify-center">
              <DropdownTopLabel
                name={"selectEndTime_" + time.id}
                label={"تا ساعت"}
                list={time.to?.allTimes || []}
                selectId={time.to?.selectTimeIndex}
                onChange={(e) => handleOnChange("to", e, time)}
              />
            </div>
            {isShowRemoveBtn ? (
              <div
                onClick={() => handleRemoveRow(time.id)}
                className="col-span-2 h-6 w-6 bg-red-500 self-center hover:bg-red-600 font-bold text-white rounded-full cursor-pointer text-center"
              >
                -
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      })}
      <div
        className="text-sky-500 cursor-pointer hover:text-sky-600 px-3 py-2 flex items-center w-fit"
        onClick={handleAddRow}
      >
        <div className="ml-2 text-lg -mb-1">+</div>
        <div>افزودن زمان</div>
      </div>
    </div>
  );
};
