import React, { useEffect, useRef, useState } from "react";
import useOutsideClick from "src/hooks/useOutSideClick";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ClockIconSVG,
  HomeIconSVG,
} from "src/assets/icons";

function DropdownWithIcon(props) {
  let {
    name = "",
    label = "",
    list = [],
    select = null,
    onclick = Function,
    className = "",
    FooterComponent,
  } = props || {};
  const [isShow, setIsShow] = useState(false);
  const [selectItem, setSelectItem] = useState({});
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsShow(false));

  const handleSetData = (item) => {
    onclick(item);
    setSelectItem(item);
    setIsShow(false);
  };

  useEffect(() => {
    select && setSelectItem(select);
  }, [select]);

  return (
    <div className={`w-full relative p-3 border border-zinc-200 rounded-lg`}>
      <div
        className={`w-full h-full flex items-center justify-start cursor-pointer whitespace-nowrap ${className}`}
        onClick={() => setIsShow((prev) => !prev)}
      >
        <div> {selectItem.icon}</div>
        <div className="mx-2">{selectItem.name || label}</div>
        <div className="absolute left-2">
          {isShow ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div>
      </div>

      {isShow && (
        <div
          ref={wrapperRef}
          className={`absolute z-50 w-full bg-white border border-zinc-50 rounded-lg shadow-xl divide-y divide-gray-100 mt-4 -mr-2`}
        >
          <ul className="text-gray-500 mt-1">
            {list?.map((item) => {
              const isSelect = item.uuid === selectItem.uuid;
              return (
                <li
                  key={`dropdown-list-item-${name}-${item.uuid}`}
                  className={`flex items-center justify-start py-3 px-2  hover:bg-gray-100 w-full border-b border-zinc-200 cursor-pointer ${
                    isSelect ? "bg-gray-100" : "bg-white"
                  }`}
                  onClick={() => handleSetData(item)}
                >
                  <div>{item.icon}</div>
                  <div className="w-5/6 mr-2 whitespace-nowrap truncate">
                    {item.name}
                  </div>
                </li>
              );
            })}
            {FooterComponent || <></>}
          </ul>
        </div>
      )}
    </div>
  );
}

export default DropdownWithIcon;
