import { useState } from "react";
import { useAppDispatch } from "src/app/hooks";
import {
  searchDeliveryBikes,
  setAddDeliveryBike,
} from "src/controllers/deliveryController";
import { ItemBikeObject } from "src/features/main/constants";
import Modal from "./Modal";

export const AddFishoppingDeliveryBikeModalComponent = ({ close }: any) => {
  const [bikeIdentification, setBikeIdentification] =
    useState<string>("1678029157");
  const [bikeObject, setBikeObject] = useState<ItemBikeObject>();
  const [error, setError] = useState<boolean>(false);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const handlekeypress = (target: any) => {
    if (target.charCode === 13) {
      handleSearchBike();
    }
  };
  const onChangeCode = (e: any) => {
    setBikeIdentification(e.target.value);
    setError(e.target.value ? false : true);
    setIsValidPhone(e.target.value ? true : false);
  };
  const handleSearchBike = () => {
    if (bikeIdentification) {
      dispatch(searchDeliveryBikes(bikeIdentification, setBikeObject));
    }
  };
  const handleSubmitBike = () => {
    if (bikeObject?.bike_identification) {
      dispatch(
        setAddDeliveryBike(bikeObject?.bike_identification, (res: boolean) =>
          res ? close() : null
        )
      );
    }
  };

  return (
    <Modal close={close}>
      <div className="">
        {Object.keys(bikeObject || {}).length ? (
          <div className="px-10 pt-12 pb-2">
            <div className="relative border rounded-lg p-10 w-96">
              <div className="absolute -top-8 h-16 w-16 bg-white rounded-full left-0 right-0 m-auto border ">
                <img
                  src={require("src/assets/icons/profile-2.png")}
                  alt="profile"
                  className="w-full h-full p-3"
                />
              </div>
              <div className="flex items-center justify-between mt-4">
                <div>شناسه پیک :</div>
                <div>{bikeObject?.bike_identification}</div>
              </div>
              <div className="flex items-center justify-between mt-4">
                <div>نام و خانوادگی :</div>
                <div>
                  {bikeObject?.first_name} {bikeObject?.last_name}
                </div>
              </div>
              <div className="flex items-center justify-between mt-4">
                <div>تلفن همراه:</div>
                <div>{bikeObject?.phone}</div>
              </div>
              <div className="flex items-center justify-between mt-4">
                <div>کدملی:</div>
                <div>{bikeObject?.national_code}</div>
              </div>
            </div>
            <div
              onClick={handleSubmitBike}
              className="bg-green-500 text-white text-center w-fit px-6 py-2 mt-8 text-base m-auto rounded-md cursor-pointer hover:bg-green-600"
            >
              تایید پیک
            </div>
          </div>
        ) : (
          <>
            <div className="m-auto text-center">افزودن پیک</div>
            <div className="my-10">
              <section className="self-center ">
                <div
                  className={`flex items-center justify-between box-border bg-white  border-solid rounded-md border h-14 w-80 md:w-96 px-2 ${
                    error ? "border-rose-400" : "border-slate-400"
                  } ${isValidPhone ? " border-b-2 border-b-sky-500" : " "}`}
                >
                  <input
                    name="bike_identification"
                    value={bikeIdentification}
                    className="text-right flex-grow mr-3 outline-none font text-sm bg-white "
                    placeholder={"شناسه پیک"}
                    type="text"
                    onKeyPress={handlekeypress}
                    onChange={onChangeCode}
                  ></input>
                  {!error && (
                    <img
                      src={require("src/assets/icons/isValidPhone.png")}
                      width={16}
                      height={16}
                      alt="validation"
                    />
                  )}
                </div>
                {error && (
                  <div className="text-rose-400 text-xs flex items-center justify-center mt-2">
                    شماره وارد شده اشتباه است
                  </div>
                )}
              </section>
            </div>
            <div
              onClick={handleSearchBike}
              className="bg-sky-500 text-white text-center w-fit px-6 py-2 text-base m-auto rounded-md cursor-pointer hover:bg-sky-600"
            >
              تایید شناسه
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
