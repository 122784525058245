import React, { useState } from "react";
import { Tabs } from "src/components/tabs/Tab";
import MainLayout from "../main/mainLayout";
import { StoreInfo } from "./StoreInfo";
import { StoreSetting } from "./StoreSetting";
import { StoreChangePassword } from "./StoreChangePassword";

const MenuData = [
  {
    id: 1,
    label: "اطلاعات فروشگاه",
    notif: 0,
    color: "#F29423",
    isShow: true,
    type: "xxs",
  },
  {
    id: 2,
    label: "تنظیمات ارسال",
    notif: 0,
    color: "#F29423",
    isShow: true,
    type: "xxa",
  },
  {
    id: 3,
    label: "رمز عبور",
    notif: 0,
    color: "#F29423",
    isShow: true,
    type: "xxa",
  },
];
export const MyStore = () => {
  const [selectTab, setSelectTab] = useState<any>(MenuData[0]);
  const [tabData, setTabData] = useState(MenuData);
  const handleClickTab = (item: any) => {
    setSelectTab(item);
  };
  return (
    <MainLayout>
      <Tabs
        data={tabData.filter((i) => i.isShow)}
        selectItem={selectTab}
        onClick={handleClickTab}
      />
      <div className="bg-white rounded-lg px-4 py-4">
        {selectTab.id === 1 && <StoreInfo />}
        {selectTab.id === 2 && <StoreSetting />}
        {selectTab.id === 3 && <StoreChangePassword />}
      </div>
    </MainLayout>
  );
};
