import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { MENU_STATIC_DATA } from "./constants";

type UserSteps =
  | "guest"
  | "isValidateToken"
  | "needProfile"
  | "editProfile"
  | "needChargeWallet"
  | "block"
  | "access";
interface MainState {
  token: string;
  step: string & UserSteps;
  menu: any;
  menuItem: any;
  loading: Boolean;
}
interface PayloadState {
  payload: string & UserSteps;
}

const initialState: MainState = {
  token: "",
  step: "guest",
  menu: MENU_STATIC_DATA,
  menuItem: null,
  loading: false,
};
export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setStep: (state, action: PayloadState) => {
      state.step = action.payload;
    },
    setMenuItem: (state, action: PayloadState) => {
      state.menuItem = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setToken, setStep, setMenuItem, setLoading } = mainSlice.actions;

export const selectToken = (state: RootState) => state.main.token;
export const selectUserAccess = (state: RootState) => state.main.step;
export const selectMenu = (state: RootState) => state.main.menu;
export const selectMenuItem = (state: RootState) => state.main.menuItem;
export const selLoading = (state: RootState) => state.main.loading;

export default mainSlice.reducer;
