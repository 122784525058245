import React, { MouseEventHandler, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectProfile } from "src/features/profile/storeSlice";
import { setLocalStorage } from "src/utils/localstorage";
import Modal from "./Modal";
type ConfirmModalProps = {
  close: MouseEventHandler<HTMLDivElement>;
  confirm: MouseEventHandler<HTMLDivElement>;
};
export const ConfirmPaymentModal = (props: ConfirmModalProps) => {
  const profile = useSelector(selectProfile);
  const ref: any = useRef();
  const { close, confirm } = props || {};
  const needCharge = profile?.bag_money < 1000;
  const handleChargeWallet = () => {};
  // useEffect(() => {
  // }, [ref.current.checked]);
  const handleOnChange = (i: any) => {
    setLocalStorage("isHidePaymentModal", i.target.checked);
  };
  return (
    <Modal close={close}>
      <div className="pt-2 relative overflow-y-auto h-fit w-h500">
        <div className="px-1 lg:px-2 mb-4">
          <h3 className="text-xl mx-0 font-medium text-center mt-10 mb-10 leading-10 ">
            جهت مشاهده فاکتور مشتری مبلغ 1000 تومان از کیف پول شما کسر می گردد.
          </h3>
          {needCharge && (
            <div className="text-red-600 text-center mt-10">
              کیف پول شما اعتبار کافی را ندارد .
            </div>
          )}
          <div className="mt-4 flex justify-between items-center ">
            <div
              className="border border-gray-500 text-gray-500 rounded w-full py-2 text-center cursor-pointer hover:bg-gray-500 hover:text-white"
              onClick={close}
            >
              انصراف
            </div>
            <div className="w-8"></div>
            <div
              className="bg-green-500 text-white rounded w-full py-2 text-center cursor-pointer hover:bg-green-800"
              onClick={needCharge ? handleChargeWallet : confirm}
            >
              {needCharge ? "شارژ کیف پول" : "تایید"}
            </div>
          </div>
          <div className="flex items-center mt-6">
            <input
              ref={ref}
              id="default-checkbox"
              type="checkbox"
              name="rememberPassword"
              defaultChecked={false}
              onChange={handleOnChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500  focus:ring-2"
            />
            <label
              htmlFor="default-checkbox"
              className="mx-2 text-sm font-medium text-gray-900 "
            >
              این پیام دوباره نمایش داده نشود.
            </label>
          </div>
        </div>
      </div>
    </Modal>
  );
};
