import axios from "./masterAPI";
import { serverError } from "./serverError";
// import axios from "axios";
// Use direct from redux store
// import { store } from "store/store";
// console.log(store.getState());

export const MasterApi = async ({ method, url, data }) => {
  method = method.toLowerCase();
  try {
    const res = await axios[method](url, data);
    return res.data || {};
  } catch (error) {
    serverError(error);
    return {};
  }
};
