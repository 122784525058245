import { MasterApi } from "src/api";
//{ lat: 35.68198003744061, lng: 51.33644946071224 }
export const postFinishedOrder =
  (invoice_uuid: any, callback: Function) =>
  async (dispatch: any, getState: any) => {
    const res = await MasterApi({
      method: "post",
      url: "/order/submit/delivered",
      data: { invoice_uuid },
    });
    if (res?.status?.code === 200) {
      callback(true);
    } else {
      callback(false);
    }
  };
