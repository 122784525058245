import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { AppRoutes } from "./routes/appRoutes";
import "./index.css";
// import "react-toastify/dist/ReactToastify.min.css";
import "react-toastify/dist/ReactToastify.css";
import RequireAuth from "./RequireAuth";
import SocketProvider from "./api/socketContext";
import mapboxgl from "mapbox-gl";

const container = document.getElementById("root")!;
const root = createRoot(container);
try {
  // mapboxgl.accessToken = "p1a8ac21f2cd70490e99eaee23936bb96334e6bd6f";
  mapboxgl?.setRTLTextPlugin(
    "https://cdn.parsimap.ir/third-party/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
    console.warn,
    true
  );
  mapboxgl.getRTLTextPluginStatus();
} catch (error) {
  console.error("😏 => error", error);
}
root.render(
  <Provider store={store}>
    <RequireAuth>
      <SocketProvider>
        <AppRoutes />
      </SocketProvider>
    </RequireAuth>
  </Provider>
);
