import moment, { unix } from "moment-jalaali";
type timeDate = "hh:mm" | "long" | "full" | "day";

export const toStrDatetime = (unixtime: any) => {
  if (unixtime) {
    const newUnix = Math.floor(unixtime / 1000);
    const date = moment.unix(newUnix);
    const months = [
      "فروردین",
      "اردبیهشت",
      "خرداد",
      "تیر",
      "مرداد",
      "شهریور",
      "مهر",
      "آبان",
      "آذر",
      "دی",
      "بهمن",
      "اسفند",
    ];
    const month = months[parseInt(date.format("jM")) - 1];

    const time = date.format("HH") + ":" + date.format("mm");
    return `${date.format("jD")} ${month} - ساعت ${time}`;
  }

  return "";
};
export const timeStampToHoure = (unixtime: any, type: timeDate) => {
  if (unixtime) {
    const newUnix = Math.floor(unixtime / 1000);
    const date = moment.unix(newUnix);
    const months = [
      "فروردین",
      "اردبیهشت",
      "خرداد",
      "تیر",
      "مرداد",
      "شهریور",
      "مهر",
      "آبان",
      "آذر",
      "دی",
      "بهمن",
      "اسفند",
    ];
    const month = months[parseInt(date.format("jM")) - 1];

    const time = date.format("HH") + ":" + date.format("mm");
    if (type === "hh:mm") {
      return `${time}`;
    } else if (type === "long") {
      return `${date.format("jD")} - ساعت ${time}`;
    } else if (type === "full") {
      return `${date.format("jD")} ${month} - ساعت ${time}`;
    } else if (type == "day") {
      return `${date.format("jD")} ${month}`;
    } else {
      return "";
    }
  }

  return "";
};
/**
 *
 * @param targetTimeStamp
 * @returns string
 */
export const countDownTimer = (targetTimeStamp: number) => {
  const now = new Date();
  const diff = targetTimeStamp - now.getTime();
  if (diff < 0) {
    return false;
  }
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000) || 0;
  return `${days ? `${days} روز و ` : ``} ${hours ? `${hours} ساعت و ` : ``} ${
    minutes ? `${minutes} دقیقه و ` : ``
  } ${seconds || seconds === 0 ? `${seconds} ثانیه` : ``} `;
};
/**
 * only return number object
 * @param targetTimeStamp
 * @returns { days , hours , minutes , seconds }
 */
export const countDownTimerPure = (targetTimeStamp: number): any => {
  const now = new Date();
  const diff = targetTimeStamp - now.getTime();
  if (diff < 0) {
    return {};
  }
  const days = Math.floor(diff / (1000 * 60 * 60 * 24)) || 0;
  const hours =
    Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) || 0;
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)) || 0;
  const seconds = Math.floor((diff % (1000 * 60)) / 1000) || 0;
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};
export const returnTimeColor = (props: any) => {
  const { days, hours, minutes, seconds } = props || {};
  if (days === 0 && hours === 0 && minutes < 10 && minutes >= 5) {
    return "#f57f17";
  } else if (days === 0 && hours === 0 && minutes < 5) {
    return "#f44336";
  } else {
    return "#616161";
  }
};
