import React, { useContext, useEffect, useRef, useState } from "react";
import CameraIMG from "src/assets/icons/camera-1.svg";
import {
  LogoutIcon,
  ActiveIcon,
  BusyIcon,
  InactiveIcon,
  InfoIconSVG,
} from "src/assets/icons";
import useOutsideClick from "src/hooks/useOutSideClick";
import MenuIMG from "src/assets/icons/menu.png";
import LogoIMG from "src/assets/images/logo.svg";
import DropdownWithIcon from "../dropdown/DropdownWithIcon";
import {
  getLocalStorage,
  removeAllLocalStorage,
  setLocalStorage,
} from "src/utils/localstorage";
import { useAppDispatch } from "src/app/hooks";
import {
  selectProfile,
  selectStoreIsOnlineStatus,
  setStoreIsOnlineStatus,
} from "src/features/profile/storeSlice";
import { useNavigate } from "react-router-dom";
import { SocketContext } from "src/api/socketContext";
import { useSelector } from "react-redux";
import { selectToken } from "src/features/main/mainSlice";
import { deleteCookie } from "src/utils";
import { ChargeWalletModal } from "../modals/ChargeWalletModal";
import { changeStoreStatus } from "src/controllers/myStoreSettingsController";

const STATUS_STORE = [
  { uuid: 1, name: "فروشگاه باز است", icon: <ActiveIcon color="#03B65A" /> },
  { uuid: 2, name: "فروشگاه مشغول است", icon: <BusyIcon color="#F29423" /> },
  {
    uuid: 3,
    name: "فروشگاه بسته است ",
    icon: <InactiveIcon color="#DE2B2B" />,
  },
  {
    uuid: 4,
    name: "تا اطلاع ثانوی بسته است",
    icon: <InfoIconSVG color="#0EA5E9" />,
  },
];
function Header() {
  const navigate = useNavigate();
  const [isDToken, setDToken] = useState();
  const [isOpenDrawer, setIsOpenDrawer] = useState();
  const token = useSelector(selectToken);
  const [isProfileMenu, setIsOpenProfileMenu] = useState(false);
  const [isOpenChargeWalletModal, setIsOpenChargeWalletModal] = useState(false);
  const [statusStore, setStatusStore] = useState(STATUS_STORE[0]);
  const [user, setUser] = useState({});
  const selectStatusuuid = useSelector(selectStoreIsOnlineStatus);
  const profile = useSelector(selectProfile);
  const dispatch = useAppDispatch();
  const ws = useContext(SocketContext);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsOpenProfileMenu(false));

  useEffect(() => {
    // set user online status
    if (selectStatusuuid) {
      setStatusStore(
        STATUS_STORE.find((i) => i.uuid === Number(selectStatusuuid))
      );
    }
  }, [selectStatusuuid]);

  const toggleDraweMenu = () => {
    setIsOpenDrawer((prev) => !prev);
  };
  const handleOpenProfileMenu = () => {
    setIsOpenProfileMenu((prev) => !prev);
  };

  const handleExit = async (e) => {
    deleteCookie("token", "/");
    await removeAllLocalStorage();
    document.location.href = process.env.REACT_APP_REDIRECT_URL;
    // window.location.origin;
    // window.push(process.env.REACT_APP_REDIRECT_URL);
  };
  const handleStoreStatus = (e) => {
    // change state
    setStatusStore(e);
    // update local storage
    setLocalStorage("STORE_STATUS", e.uuid);
    // send store status to server
    dispatch(changeStoreStatus(e.uuid));
    // update redux state
    dispatch(setStoreIsOnlineStatus(e.uuid));

    if (e.uuid === 1) {
      ws.initSocket(token);
    } else {
      ws.disconnect();
    }
  };

  return (
    <div className="bg-white select-none w-3/4 absolute left-0 top-0 h-16 flex items-center justify-between px-4 text-zinc-700 text-sm border-r-2 border-zinc-100">
      {/* <img
        className="w-40 h-11 cursor-pointer"
        src={LogoIMG}
        alt="menu icon"
        onClick={() => navigate.push("/")}
      ></img> */}
      <div className="p-1 flex items-center justify-start">
        <div className="">کیف پول:</div>
        <div className="text-green-500 m-8">
          {profile?.bag_money?.toLocaleString() || 0} تومان
        </div>
        <div
          onClick={() => setIsOpenChargeWalletModal(true)}
          className="flex border-blue-900 hover:border-blue-400  border justify-evenly items-center rounded-lg py-2 px-3 hover:bg-blue-100 cursor-pointer"
        >
          <img src={CameraIMG} alt="camera" />
          <div className="text-blue-900 mx-1 truncate">افزایش اعتبار</div>
        </div>
      </div>
      <div className="relative flex items-center">
        <div className="relative w-52 h-full mx-4 cursor-pointer">
          <DropdownWithIcon
            name="state"
            label={"وضعیت"}
            list={STATUS_STORE}
            select={statusStore}
            onclick={handleStoreStatus}
            className=""
            FooterComponent={null}
          />
        </div>
        <div className="flex items-center justify-center">
          <div
            className="flex items-center justify-center cursor-pointer"
            onClick={handleExit}
          >
            <LogoutIcon color={"#292D32"} />

            <div className="mx-1 cursor-pointer">خروج از حساب کاربری</div>
          </div>
        </div>
      </div>
      {isOpenChargeWalletModal && (
        <ChargeWalletModal close={() => setIsOpenChargeWalletModal(false)} />
      )}
    </div>
  );
}

export default Header;
