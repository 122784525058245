import { notify } from "src/components/notify";

export const alertAndRedirectToLogin = () => {
  if (window.confirm("لطفا مجددا وارد حساب شوید")) {
    window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}`);
    // document.location.href = process.env.REACT_APP_REDIRECT_URL;
  }
};

export const serverError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    switch (error.response.status) {
      case 400: {
        break;
      }
      case 401: {
        console.log("Unauthorized : ", error.response.status);
        alertAndRedirectToLogin();
        break;
      }
      case 402: {
        break;
      }
      case 403: {
        break;
      }
      default:
        console.error("other server error : ", error.response.status);
        break;
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    notify.error("پاسخی از سرور دریافت نشد");
  } else {
    // Something happened in setting up the request that triggered an Error
    notify.error(error.message);
  }
};
