import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { SocketContext } from "src/api/socketContext";
import { useAppDispatch } from "src/app/hooks";
import EmptyPage from "src/features/empty/EmptyPage";
import { Dashboard } from "src/features/main/dashboard/dashboard";
import DeliveryPage from "src/features/main/delivery/delivery";
import { selectToken } from "src/features/main/mainSlice";
import { Wallet } from "src/features/main/wallet/Wallet";
import { MyStore } from "src/features/myStore/MyStore";
import Orders from "src/features/orders/order";
import { Profile } from "src/features/profile";
import { setStoreIsOnlineStatus } from "src/features/profile/storeSlice";
import RefererBank from "src/features/refererBank/RefererBank";
import { getLocalStorage, setLocalStorage } from "src/utils/localstorage";

export const AppRoutes = () => {
  const ws: any = useContext(SocketContext);
  const token: string = useSelector(selectToken);
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function initSock() {
      const selectStatusuuid: number = await getLocalStorage("STORE_STATUS");
      dispatch(setStoreIsOnlineStatus(selectStatusuuid || 1));
      if (!selectStatusuuid) {
        setLocalStorage("STORE_STATUS", 1);
      }

      if (!ws) return;
      if (!selectStatusuuid || selectStatusuuid === 1) {
        ws.initSocket(token);
      } else {
        ws.disconnect();
      }
    }
    initSock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Suspense fallback={<div className="loading">Loading ...</div>}>
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/delivery" element={<DeliveryPage />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/myStore" element={<MyStore />} />
          <Route path="/orders/:pageName" element={<Orders />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/login" element={<EmptyPage label="Login Page" />} />
          <Route path="/refererbank" element={<RefererBank />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="*" element={<EmptyPage label="404 Page" />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </React.Suspense>
  );
};
