import React, { useEffect, useState } from "react";
import { ClockIconSVG } from "src/assets/icons";
import { countDownTimerPure, returnTimeColor } from "src/utils/time";
import Loading from "../loading/Loading";

export const TimerCountDown = ({ expire_at, close, className = "" }: any) => {
  const [textColor, setTextColor] = useState<string>("text-gray-700");
  const [time, setTime] = useState<any>({
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
  });
  useEffect(() => {
    var intervalHandle = setInterval(() => {
      const { days, hours, minutes, seconds } = countDownTimerPure(
        new Date(expire_at).getTime()
      );
      if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        close();
      } else {
        setTime({
          days,
          hours,
          minutes,
          seconds,
        });
        setTextColor(
          returnTimeColor({
            days,
            hours,
            minutes,
            seconds,
          })
        );
      }
    }, 1000);
    return () => clearInterval(intervalHandle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isWorkTimer = time.days || time.hours || time.minutes || time.seconds;
  return (
    <>
      {isWorkTimer ? (
        <div
          className={`py-1 px-4 text-sm flex items-center truncate ${
            className || ""
          }`}
        >
          <ClockIconSVG color={textColor || "#444"} size="16" />
          <div className={`mx-2 text-xs w-28`} style={{ color: textColor }}>
            <div>{`${time.days ? `${time.days} روز و ` : ``} ${
              time.hours ? `${time.hours} ساعت و ` : ``
            } ${time.minutes ? `${time.minutes} دقیقه و ` : ``} ${
              time.seconds || time.seconds === 0 ? `${time.seconds} ثانیه` : ``
            } `}</div>
          </div>
        </div>
      ) : (
        <>{/* <Loading color="#ddd" /> */}</>
      )}
    </>
  );
};
