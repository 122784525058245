import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/hooks";
import { ClockIconSVG, LocationIconSVG, PhoneIconSVG } from "src/assets/icons";
import {
  resetProductInFactor,
  selProductInFactor,
} from "src/features/orders/ordersSlice";
import { selectProfile } from "src/features/profile/storeSlice";
import {
  removePreCodeInNumber,
  toNumberDatetime,
  getStoreStatus,
} from "src/utils";
import { toStrDatetime } from "src/utils/time";
import Loading from "../loading/Loading";
import Modal from "./Modal";

export const ReadOnlyFactorModal = (props: any) => {
  const { close, data = {} } = props || {};
  const dispatch = useAppDispatch();
  const profile = useSelector(selectProfile);
  const { order } = data || {};
  const { order_addresses = {}, consumer = {} } = order || {};
  const user = {
    user_consumer: { ...consumer },
    phone: consumer.telephone || "",
  };
  const dataInFactor = useSelector(selProductInFactor);
  const { invoice_changes, products } = dataInFactor || {};
  const productInFactor = invoice_changes || [];
  const { user_consumer, phone } = user || {};
  const { first_name, last_name } = user_consumer || {};

  const totlaPayment = productInFactor.reduce(
    (sum: number, li: any) => sum + li.price * li.quantity,
    0
  );
  const isChangeProduct = productInFactor?.find(
    (i: any) => i.new_product !== null
  );
  const isChangePriceProduct = productInFactor?.find(
    (i: any = {}) => i.price !== i.changed_product?.price
  );
  const expireTime = toNumberDatetime(
    new Date(data?.change_expire_at).getTime()
  );
  const factorStatus = getStoreStatus(data?.status);
  const handleClose = () => {
    dispatch(resetProductInFactor());
    close();
  };
  return (
    <Modal close={handleClose}>
      <div className="text-xs">
        <div className="text-blue text-base font-bold text-center w-full">
          پیش فاکتور فروش
        </div>
        <div className="text-center w-full mt-2">{data?.store?.shop_name}</div>
        <div className="bg-gray-100 rounded-lg p-3 grid grid-cols-3 grid-rows-3 gap-3 mt-4">
          <div className="flex justify-start items-center">
            <div className="text-gray-600">شماره فاکتور:</div>
            <div className="mx-1 truncate w-16">{data?.id}</div>
          </div>
          <div className="flex justify-start items-center">
            {/* <div className="text-gray-600">کاربر:</div>
            <div className="mx-1">{data?.store.id}</div> */}
          </div>
          <div className="flex justify-start items-center">
            <div className="text-gray-600">زمان:</div>
            <div className="mx-1">
              {toStrDatetime(new Date(data?.created_at).getTime())}
            </div>
          </div>
          <div className="flex justify-start items-center">
            <div className="text-gray-600">مشتری:</div>
            <div className="mx-1">
              {first_name || "-"} {last_name || "-"}
            </div>
          </div>
          <div className="flex justify-start items-center">
            {/* <div className="text-gray-600">شماره اشتراک:</div>
            <div className="mx-1">---</div> */}
          </div>
          <div className="flex justify-start items-center">
            <div className="text-gray-600">تلفن:</div>
            <div className="mx-1">{removePreCodeInNumber(phone) || "---"}</div>
          </div>
          <div className="flex justify-start items-center col-span-3">
            <div className="text-gray-600">آدرس:</div>
            <div className="mx-1">{order_addresses?.description || "-"}</div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className={`flex py-2 px-1`}>
            <div>وضعیت فاکتور : </div>
            <div className={`px-1 ${factorStatus?.color} rounded-xl`}>
              {factorStatus?.name}
            </div>
          </div>
          <div className="flex items-center justify-end my-2">
            {isChangeProduct && (
              <div className="bg-red-50 text-red-700 rounded-2xl mx-0.5 text-center py-1 px-3">
                تعویض کالا
              </div>
            )}
            {isChangePriceProduct && (
              <div className="bg-sky-50 text-sky-700 rounded-2xl mx-0.5 text-center py-1 px-3">
                اصلاح قیمت
              </div>
            )}
          </div>
        </div>
        <section>
          <div className="bg-gray-100 rounded-lg p-2 w-h550 text-xs">
            <div className="grid grid-cols-12 text-gray-700 text-center py-2">
              <div className="col-span-1">#</div>
              <div className="col-span-2">شناسه</div>
              <div className="col-span-5 text-start">عنوان محصول</div>
              <div className="col-span-1">قیمت</div>
              <div className="col-span-1">مقدار</div>
              <div className="col-span-2">مبلغ کل (تومان)</div>
            </div>
            <div className="h-h350 max-h-h350 overflow-y-auto overflow-x-hidden">
              {productInFactor.length ? (
                [...productInFactor].map((item = {}, index) => {
                  const { changed_product, new_product, quantity, price } =
                    item || {};
                  const {
                    name,
                    description,
                    code,
                    alias,
                    weight,
                    weight_unit,
                    category,
                    brand,
                    package_type,
                    counting_unit,
                    image,
                    uuid,
                  } = new_product || changed_product;
                  const changePrice =
                    Number(price) !== Number(changed_product.price);

                  const changeQuantity = quantity !== changed_product.quantity;
                  return (
                    <div
                      key={`item-list-${index}`}
                      className={`grid grid-cols-12 text-center rounded-lg border-b-4 bg-white border-gray-100 text-black-text my-1 ${
                        new_product
                          ? "bg-rose-200"
                          : changePrice
                          ? "bg-sky-100"
                          : changeQuantity
                          ? "bg-zinc-50"
                          : ""
                      }  `}
                    >
                      <div className="text-center m-auto">{++index}</div>
                      <div className="col-span-2 px-2 w-full">
                        <div className="text-center m-auto relative font-medium text-gray-900 whitespace-nowrap">
                          <img
                            className="pt-2 rounded-lg object-contain bg-contain"
                            style={{
                              width: 85,
                              height: 48,
                            }}
                            src={image}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = "/assets/images/image404.svg";
                            }}
                            alt={""}
                          />
                          <div className="w-full truncate text-micro text-zinc-500 text-center">
                            {`شناسه : ${code}`}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-5 self-center text-start">
                        <div className="mb-1 truncate text-ellipsis w-36 text-black">
                          {name}
                        </div>
                        <div className="mt-1 text-micro flex items-center justify-start">
                          {brand && (
                            <div className="mx-1 px-2 h-5 text-center bg-gray-100 text-gray-900 rounded-xl">
                              {brand}
                            </div>
                          )}
                          {category && (
                            <div className="mx-1 px-2 h-5 text-center bg-sky-100 text-sky-900 rounded-xl">
                              {category}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="text-center m-auto">
                        {changePrice ? (
                          <div className="line-through text-gray-400">
                            {changed_product.price?.toLocaleString()}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div>{price?.toLocaleString()}</div>
                      </div>
                      <div className="text-center m-auto">
                        {changeQuantity ? (
                          <div className="line-through text-gray-400">
                            {changed_product.quantity}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div>{quantity}</div>
                      </div>
                      <div className="col-span-2 text-center m-auto">
                        {(quantity * price).toLocaleString()}
                      </div>
                    </div>
                  );
                })
              ) : (
                <Loading color="fill-green-500" />
              )}
            </div>
          </div>
        </section>
        <div className="border-gray-100 border-2 rounded-md p-3 flex justify-between items-center my-2">
          <div className="flex items-center">
            <div className="text-gray-500 ">جمع کل:</div>
            <div className="mx-1">{totlaPayment?.toLocaleString()} تومان</div>
          </div>

          <div className="flex items-center">
            <div className="text-gray-500 ">تخفیف:</div>
            <div className="mx-1">0 تومان</div>
          </div>

          <div className="flex items-center">
            <div className="text-gray-500 ">قابل پرداخت:</div>
            <div className="mx-1">{totlaPayment?.toLocaleString()} تومان</div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="flex items-center justify-center text-micro text-gray-700 mt-2 mx-2">
          <PhoneIconSVG color="#6B7280" size={16} />
          <div className="mx-1">
            {profile?.phone || ""} , {profile?.mobile || ""}
          </div>
        </div>
        <div className="flex items-center justify-center text-micro text-gray-700 mt-2 mx-2">
          <ClockIconSVG color="#6B7280" size={16} />
          <div className="mx-1">ساعت کاری: 09:00 - 23:30</div>
        </div>
      </div>
      <div className="flex items-center justify-center text-micro text-gray-700 mt-2">
        <LocationIconSVG color="#6B7280" size={16} />
        <div className="mx-1">{profile?.address || "---"}</div>
      </div>
      <div
        onClick={handleClose}
        className="w-full mt-3 text-xs  rounded-lg py-2 cursor-pointer bg-sky-500 hover:bg-sky-700 text-white text-center"
      >
        بستن
      </div>
    </Modal>
  );
};
