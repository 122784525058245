import useOutsideClick from "src/hooks/useOutSideClick";
import React, { useEffect, useRef, useState } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "src/assets/icons";
function Dropdown(props) {
  let {
    name = "",
    label = "",
    list = [],
    selectId = null,
    onChange = Function,
    className = "",
  } = props || {};
  const [isShow, setIsShow] = useState(false);
  const [selectItem, setSelectItem] = useState({});
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsShow(false));

  useEffect(() => {
    const defItem = list.find((i) => i.uuid === selectId);
    defItem && setSelectItem(defItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectId]);

  const handleSetData = (item) => {
    onChange({ target: { value: item.uuid } });
    setSelectItem(item);
    setIsShow(false);
  };

  if (selectId) {
    const item = list?.find((i) => i.uuid === selectId);
    if (item?.name) label = item?.name;
  }
  return (
    <>
      <div
        className={`w-full h-full rounded-lg inline-flex items-center justify-between cursor-pointer whitespace-nowrap ${className}`}
        onClick={() => setIsShow((prev) => !prev)}
      >
        {selectItem.name || label}
        {isShow && list.length ? (
          <ArrowUpIcon className="w-3 h-3" color={"#1E3A8A"} />
        ) : (
          <ArrowDownIcon className="w-3 h-3" color={"#1E3A8A"} />
        )}
      </div>

      {list.length ? (
        <div
          ref={wrapperRef}
          className={`${
            isShow ? "" : "hidden"
          } absolute z-40 top-4 w-24 min-w-fit bg-white rounded divide-y divide-gray-100 shadow-xl mt-2 -mr-2`}
        >
          <ul className="text-sm text-gray-500 mt-1 cursor-pointer max-h-40 overflow-y-auto overflow-x-hidden">
            {list?.map((item) => {
              const isSelect = item.uuid === selectItem.uuid;
              return (
                <li
                  key={`dropdown-list-item-${name}-${item.uuid}`}
                  className={`py-3 px-2 pl-10 hover:bg-gray-100 rounded w-full ${
                    isSelect ? "bg-gray-200" : "bg-white"
                  }`}
                  onClick={() => handleSetData(item)}
                >
                  <div className="block text-right text-xs truncate min-w-max">
                    {item.name}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Dropdown;
