import React, { useEffect, useRef, useState } from "react";
import { Tabs } from "src/components/tabs/Tab";
import MainLayout from "../mainLayout";
import { useAppDispatch } from "src/app/hooks";
import { useSelector } from "react-redux";
import {
  selBagMoneyList,
  selBagMoneyMeta,
  selTransactionsList,
  selTransactionsMeta,
} from "./transactionsSlice";
import {
  getBagMoneyLog,
  getTransactions,
} from "src/controllers/transactionsController";
import { toStrDatetime } from "src/utils/time";
import { generatePaymentType } from "src/utils";
import { EmptyComponent } from "src/components/empty/emptyComponent";

const MenuData = [
  {
    id: 1,
    label: "واریزها",
    notif: 0,
    color: "#F29423",
    isShow: true,
    type: "xxs",
  },
  {
    id: 2,
    label: "برداشت‌ها",
    notif: 0,
    color: "#F29423",
    isShow: true,
    type: "xxa",
  },
  {
    id: 3,
    label: "تراکنش‌ها",
    notif: 0,
    color: "#F29423",
    isShow: false, //process.env.NODE_ENV === "development",
    type: "xxa",
  },
];

export const Wallet = () => {
  const [selectTab, setSelectTab] = useState<any>(MenuData[0]);
  const [tabData, setTabData] = useState(MenuData);
  const handleClickTab = (item: any) => {
    setSelectTab(item);
  };

  return (
    <MainLayout>
      <Tabs
        data={tabData.filter((i) => i.isShow)}
        selectItem={selectTab}
        onClick={handleClickTab}
      />
      <div
        className={`mt-2 w-full rounded p-4 pb-8 relative overflow-y-scroll h-full  ${
          true ? "bg-white" : "bg-gray-50"
        }`}
      >
        {selectTab.id === 1 ? <PaymentList /> : <></>}
        {selectTab.id === 2 ? <WithdrawingList /> : <></>}
        {selectTab.id === 3 ? <TransactionsList /> : <></>}
      </div>
    </MainLayout>
  );
};

export const WithdrawingList = () => {
  const dispatch = useAppDispatch();
  const list = useSelector(selBagMoneyList);
  const meta: any = useSelector(selBagMoneyMeta) || {};
  const listInnerRef: any = useRef();

  useEffect(() => {
    dispatch(getBagMoneyLog({ direction: "withdrawal", page: 1 }));
    // dispatch(getAuthorityTransaction());
    // dispatch(getPaymentTransactions('3b5bb216-0b55-45e0-9f25-159de568c21e'));
  }, []);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (meta.has_next_page) {
          dispatch(
            getBagMoneyLog({
              direction: "withdrawal",
              page: meta.total_pages + 1,
            })
          );
        }
      }
    }
  };

  return (
    <>
      <div className="text-blue-900 py-4 px-2 text-xl font-bold">
        برداشت از کیف پول
      </div>
      <div className="w-full py-4 grid grid-cols-12 text-center font-bold">
        <div className="col-span-1 text-right pr-2">ردیف</div>
        <div className="col-span-3">مبلغ برداشت</div>
        <div className="col-span-3">موجودی کیف‌پول</div>
        <div className="col-span-2">تاریخ</div>
        <div className="col-span-3">بابت پرداخت</div>
      </div>
      <div
        className="max-h-h600 md:max-h-h5500 relative overflow-auto "
        onScroll={onScroll}
        ref={listInnerRef}
      >
        {list?.length ? (
          list.map((item: any = {}, index) => {
            const isOdd = index % 2 === 0;
            const { typeLabel, typeColor } = generatePaymentType(item.type);

            return (
              <div
                key={`item-${item.uuid}`}
                className={`w-full grid grid-cols-12 text-center text-sm font-normal items-center py-4 border-t border-gray-300 ${
                  isOdd ? "bg-gray-50" : "bg-white"
                }`}
              >
                <div className="col-span-1 text-right pr-4">{index + 1}</div>
                <div className="col-span-3">
                  {item?.amount?.toLocaleString()}
                </div>
                <div className="col-span-3">{item.after_total_amount}</div>
                <div className="col-span-2">
                  {toStrDatetime(new Date(item?.created_at))}
                </div>
                <div
                  className={`col-span-3 py-0.5 px-4 rounded-xl text-center w-fit m-auto ${typeColor}`}
                >
                  {typeLabel}
                </div>
              </div>
            );
          })
        ) : (
          <EmptyComponent title="نتیجه ای یافت نشد" />
        )}
      </div>
    </>
  );
};
export const PaymentList = () => {
  const dispatch = useAppDispatch();
  const list = useSelector(selBagMoneyList);
  const meta: any = useSelector(selBagMoneyMeta) || {};
  const listInnerRef: any = useRef();

  useEffect(() => {
    dispatch(getBagMoneyLog({ direction: "deposit", page: 1 }));
    // dispatch(getAuthorityTransaction());
    // dispatch(getPaymentTransactions('3b5bb216-0b55-45e0-9f25-159de568c21e'));
  }, []);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (meta.has_next_page) {
          dispatch(
            getBagMoneyLog({
              direction: "deposit",
              page: meta.total_pages + 1,
            })
          );
        }
      }
    }
  };
  return (
    <>
      <div className="text-blue-900 py-4 px-2 text-xl font-bold">
        واریز به کیف پول
      </div>
      <div className="w-full py-4 grid grid-cols-12 text-center font-bold">
        <div className="col-span-1 text-right pr-2">ردیف</div>
        <div className="col-span-3">مبلغ واریز</div>
        <div className="col-span-3">موجودی کیف‌پول</div>
        <div className="col-span-2">شناسه پیگیری</div>
        <div className="col-span-3">نوع پرداخت</div>
      </div>
      <div
        className="max-h-h600 md:max-h-h5500 relative overflow-auto "
        onScroll={onScroll}
        ref={listInnerRef}
      >
        {list?.length ? (
          list.map((item: any = {}, index) => {
            const isOdd = index % 2 === 0;
            const { typeLabel, typeColor } = generatePaymentType(item.type);

            return (
              <div
                key={`item-${item.uuid}`}
                className={`w-full grid grid-cols-12 text-center text-sm font-normal items-center py-4 border-t border-gray-300 ${
                  isOdd ? "bg-gray-50" : "bg-white"
                }`}
              >
                <div className="col-span-1 text-right pr-4">{index + 1}</div>
                <div className="col-span-3">
                  {item?.amount?.toLocaleString()}
                </div>
                <div className="col-span-3">{item.after_total_amount}</div>
                <div className="col-span-2">{item?.tracking_code}</div>
                <div
                  className={`col-span-3 py-0.5 px-4 rounded-xl text-center w-fit m-auto ${typeColor}`}
                >
                  {typeLabel}
                </div>
              </div>
            );
          })
        ) : (
          <EmptyComponent title="نتیجه ای یافت نشد" />
        )}
      </div>
    </>
  );
};
export const TransactionsList = () => {
  const dispatch = useAppDispatch();

  const list = useSelector(selTransactionsList);
  const meta = useSelector(selTransactionsMeta);

  useEffect(() => {
    dispatch(getTransactions());
    // dispatch(getAuthorityTransaction());
    // dispatch(getPaymentTransactions('3b5bb216-0b55-45e0-9f25-159de568c21e'));
  }, []);

  return (
    <>
      <div className="text-blue-900 py-4 px-2 text-xl font-bold">
        همه تراکنش‌ها
      </div>
      <div className="w-full py-4 grid grid-cols-6 text-center font-bold">
        <div className="text-right pr-2">ردیف</div>
        <div>شناسه پیگیری</div>
        <div className="col-span-2">زمان </div>
        <div>مبلغ ( تومان)</div>
        <div>وضعیت</div>
      </div>
      {list?.length ? (
        list.map((item: any = {}, index) => {
          const isOdd = index % 2 === 0;
          const statusLabel =
            item.status === "2"
              ? "پرداخت موفق"
              : item.status === "1"
              ? "در انتظار پرداخت"
              : "ناموفق";
          const statusColor =
            item.status === "2"
              ? "bg-green-100 text-green-800"
              : item.status === "1"
              ? "bg-yellow-100 text-yellow-800"
              : "bg-red-100 text-red-800";
          return (
            <div
              key={`item-${item.uuid}`}
              className={`w-full grid grid-cols-6 text-center text-sm font-normal items-center py-4 border-t border-gray-300 ${
                isOdd ? "bg-gray-50" : "bg-white"
              }`}
            >
              <div className="text-right pr-4">{index + 1}</div>
              <div>{"به زودی"}</div>
              <div className="col-span-2">
                {toStrDatetime(new Date(item?.created_at))}
              </div>
              <div>{item.amount.toLocaleString()}</div>
              <div
                className={`py-0.5 px-4 rounded-xl text-center w-fit m-auto ${statusColor}`}
              >
                {statusLabel}
              </div>
            </div>
          );
        })
      ) : (
        <EmptyComponent title="نتیجه ای یافت نشد" />
      )}
    </>
  );
};
