import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SocketContext } from "src/api/socketContext";
import {
  ClockIconSVG,
  CloseIconSVG,
  FactorSearchIconSVG,
  LocationIconSVG,
  MoneyIconSVG,
  PhoneIconSVG,
  ReplaceIconSVG,
} from "src/assets/icons";
import {
  putFactorInListOriginal,
  resetFactorInList,
  selectFaktorList,
  selectFaktorListOriginal,
  selProductInFactor,
} from "src/features/orders/ordersSlice";
import useOutsideClick from "src/hooks/useOutSideClick";
import { toStrDatetime } from "src/utils/time";
import { Counterbutton } from "../buttons/CounterButton";
import Loading from "../loading/Loading";
import { ReplaceProductSide } from "./ReplaceProductSide";
import { putFactorInList } from "src/features/orders/ordersSlice";
import { useAppDispatch } from "src/app/hooks";
import { addMinutes, removePreCodeInNumber, toNumberDatetime } from "src/utils";
import Dropdown from "../dropdown/Dropdown";
import Modal from "./Modal";
import { ReadOnlyFactorModal } from "./ReadOnlyFactorModal";
import { TimerCountDown } from "../timer/TimerCountDown";
import ChatModal from "./ChatModal";
// import "./styles.css";

export const FactoringModal = (props: any) => {
  const allList = useSelector(selectFaktorList);
  const allListOriginal = useSelector(selectFaktorListOriginal);
  const selProducInFactor = useSelector(selProductInFactor);
  const originalFactor = useSelector(putFactorInListOriginal);

  const dispatch = useAppDispatch();
  const { close, data } = props || {};
  const [list, setList] = useState([]);
  const [selectList, setSelectList] = useState<any>([]);
  const ws: any = useContext(SocketContext);
  const [isShowSideBar, setIsShowSideBar] = useState<any>({});
  const [isShowChatSide, setIsShowChatSide] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  const inputRef: any = useRef();
  const [loading, setLoading] = useState<boolean>(true);
  const [expireFactor, setExpireFactor] = useState<any>(0);
  const [deliveryTime, setDeliveryTime] = useState<any>(0);
  const [noteError, setNoteError] = useState<boolean>(false);
  const [isResetFactor, setIsResetFactor] = useState<boolean>(false);

  useOutsideClick(wrapperRef, close);

  useEffect(() => {
    setExpireFactor(selProducInFactor?.expired_time || 15); // اعتبار فاکتور
    setDeliveryTime(selProducInFactor?.prepared_time || 15); //زمان ارسال
  }, [selProducInFactor]);

  useEffect((): any => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  useEffect(() => {
    if (!data?.uuid) return;
    try {
      if (allList[data?.uuid]) setList(allList[data?.uuid]);
      setLoading(false);
    } catch (error) {}
  }, [allList, data]);

  const sumReducer = (sum: any, val: any) => {
    const { price = 0, quantity = 0 } = val;
    return sum + price * quantity;
  };
  var orginalTotalPrice: any = [];
  try {
    if (allListOriginal[data?.uuid]) {
      orginalTotalPrice = allListOriginal[data?.uuid];
    }
  } catch (error) {
    console.error(error);
  }
  const totalPrice: number = orginalTotalPrice?.reduce(sumReducer, 0);

  const storeSTotalPrice: number = list
    .filter((i: any) => selectList.find((j: string) => j === i.uuid))
    .reduce(sumReducer, 0);

  const handleReject = () => {
    ws.sendMessage("accept_or_reject_invoice", data?.uuid, 5);
    close();
  };
  const handleSubmit = () => {
    // اگر هیچ محصولی انتخاب نکرده بود
    if (selectList.length === 0) return;

    const newParams = allList[data?.uuid]?.map((i: any, index: number) => {
      //  محصول جابجا شده است
      const isRplaceProduct =
        allListOriginal[data?.uuid][index].uuid !== i.uuid;

      let uuid: string | null = null;
      if (isRplaceProduct) {
        uuid = i.uuid;
      }

      if (
        selectList.find((j: string) => {
          return j === i.uuid;
        })
      ) {
        return {
          changed_product_uuid: allListOriginal[data?.uuid][index].uuid,
          new_product_uuid: uuid,
          quantity: i.quantity,
          price: i.price,
        };
      }
    });

    const note =
      inputRef?.current?.value ||
      "از خرید شما متشکریم. لطفا اگر درخواستی دارید ما را از آن مطلع سازید.";

    const productsJson = newParams?.filter((i: any) => i !== undefined);
    const current_date = new Date();
    // current_date.setDate(current_date.getTime() + 7);
    //todo:check
    // ws.sendMessage(
    //   "change_product_of_invoice",
    //   data?.uuid,
    //   4,
    //   productsJson,
    //   note,
    //   expire_date
    // );

    ws.sendMessage(
      "accept_or_reject_invoice",
      data?.uuid,
      4,
      productsJson,
      note,
      // addMinutes(current_date, expireFactor),
      expireFactor,
      deliveryTime
    );
    //todo: move to ws top
    if (!note) {
      setNoteError(true);
      return;
    }
    close();
    // ws.sendMessage("accept_or_reject_invoice", data?.uuid, 4);
  };
  const handleOpenChatSide = () => {
    setIsShowSideBar({});
    setIsShowChatSide((prev) => !prev);
  };
  const handleOpenSideBar = (item: any) => {
    if (data?.status !== 1 && data?.status !== 2) return;
    if (item?.uuid === isShowSideBar?.uuid) {
      setIsShowSideBar({});
    } else {
      setIsShowSideBar(item);
      setIsShowChatSide(false);
    }
  };
  const resetTable = () => {
    dispatch(resetFactorInList());
    setIsResetFactor(false);
    //originalFactor
    console.log("😏 => originalFactor:", originalFactor);
    return;
    ws.sendMessage(
      "send_invoice_changes_to_customer",
      data.uuid,
      originalFactor
    );
  };
  const handleChangeQuantity = (item: any, changeNumber: number) => {
    const updateArr = allList[data?.uuid].map((i: any) => {
      if (i.uuid === item.uuid) {
        const maxNUmber = allListOriginal[data?.uuid].find(
          (f: any) => f.uuid === item.uuid
        ).quantity;
        let newQuantity = 0;
        if (
          i.quantity + changeNumber > 0 &&
          i.quantity + changeNumber <= maxNUmber
        )
          newQuantity = i.quantity + changeNumber;
        else if (i.quantity + changeNumber > maxNUmber) newQuantity = maxNUmber;

        return { ...i, quantity: newQuantity };
      } else {
        return { ...i };
      }
    });
    ws.sendMessage("send_invoice_changes_to_customer", data.uuid, updateArr);

    dispatch(putFactorInList({ [data?.uuid]: updateArr }));
    setIsResetFactor(true);
  };
  const handleOnChangeCheckbox = (uuid: string, isUnChange: boolean) => {
    if (isUnChange && selectList.find((i: string) => i === uuid)) {
      setSelectList((prev: any) => prev.filter((i: string) => i !== uuid));
    } else {
      setSelectList((prev: any) => {
        const uniq: any = new Set([...prev, uuid]);
        return [...uniq];
      });
    }
    setIsResetFactor(true);
  };
  const toggleSelectAll = (e: any) => {
    const { checked } = e.target;
    setSelectList(() => (checked ? list?.map((i: any) => i?.uuid) : []));
  };
  const handleReplacementProduct = (newItem: any) => {
    const updateList: any = allList[data?.uuid].map((i: any) => {
      if (i.uuid === isShowSideBar.uuid) {
        const newQuantity =
          allList[data?.uuid].find((j: any) => j.uuid === i.uuid)?.quantity ||
          1;
        return {
          ...newItem,
          quantity: newQuantity,
          changed_product_uuid: i.uuid,
        };
      } else {
        return { ...i };
      }
    });
    const arrayHashmap = updateList.reduce((obj: any, item: any) => {
      if (obj[item.uuid]) {
        // when add to latest product
        // obj[item.uuid].quantity += item.quantity;
      } else {
        obj[item.uuid] = { ...item };
      }
      return obj;
    }, {});
    const mergedArray = Object.values(arrayHashmap);
    ws.sendMessage("send_invoice_changes_to_customer", data.uuid, updateList);
    dispatch(putFactorInList({ [data?.uuid]: mergedArray }));
    // dispatch(putFactorInListOriginal({ [data?.uuid]: mergedArray }));
    setIsShowSideBar(newItem);
    // تغییر آی دی در لیست انتخاب شده ها
    setSelectList((prev: any) =>
      prev.map((k: string) => (k === isShowSideBar.uuid ? newItem.uuid : k))
    );
    setIsResetFactor(true);
  };
  const handleOnChangePrice = (item: any, value: string) => {
    const newList = allList[data?.uuid].map((i: any) => {
      if (i.uuid === item.uuid) {
        return { ...i, price: Number(value.replace(/\D/g, "")) || 0 };
      } else {
        return { ...i };
      }
    });
    ws.sendMessage("send_invoice_changes_to_customer", data.uuid, newList);

    dispatch(putFactorInList({ ...allList, [data?.uuid]: newList }));
    setIsResetFactor(true);
  };
  if (loading) return <Loading color="fill-red-700" />;

  const isReadOnly = data?.status !== 1 && data?.status !== 2;
  const { order, prepared_time, expired_time } = data || {};
  const { consumer, order_addresses, message } = order || {};
  const { first_name, last_name, telephone, mobile } = consumer || {};
  var { description } = order_addresses || {};
  if (!description) {
    description = selProducInFactor?.order_addresses?.description;
  }
  if (isReadOnly) {
    return <ReadOnlyFactorModal data={data} close={close} />;
  }
  return (
    <div className="backdrop-blur backdrop-brightness-50 overflow-hidden fixed top-0 left-0 bottom-0 right-0 z-50 md:inset-0">
      <div className="relative flex justify-center items-center h-screen p-4">
        {/* modal */}
        <div
          className="relative rounded-lg h-fit w-full flex bg-white py-6"
          ref={wrapperRef}
          style={{
            maxWidth: isShowSideBar?.uuid || isShowChatSide ? 1200 : 600,
          }}
        >
          <button
            type="button"
            className="absolute z-30 top-3 left-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-micro p-1.5 ml-auto inline-flex items-center"
            onClick={close}
          >
            {/* <span className="pb-1 ml-1">بستن</span> */}
            <CloseIconSVG />
          </button>
          <div className={`w-full transition-all duration-700 flex`}>
            {/* Replace Modal */}
            <div
              className={`${
                isShowSideBar?.uuid ? "w-1/2" : "w-0 hidden"
              } h-full relative`}
            >
              {isShowSideBar?.uuid && (
                <ReplaceProductSide
                  size={list.length || 0}
                  data={{ ...isShowSideBar, invoice_uuid: data?.uuid }}
                  onClickItem={handleReplacementProduct}
                />
              )}
            </div>
            {/* Chat Modal */}
            {isShowChatSide && (
              <div className="w-1/2">
                <ChatModal close={handleOpenChatSide} invoiceId={data.uuid} />
              </div>
            )}
            {/* Factor Modal */}
            <div
              className={`w-full h-full overflow-hidden relative ${
                isShowSideBar?.uuid ? "w-1/2" : ""
              }`}
            >
              <div className="text-sm mx-2 font-medium text-indigo-900">
                مشخصات مشتری
              </div>
              <div className="px-2 mt-3">
                <div className="grid gap-y-3 grid-cols-12 text-xs">
                  <div className="col-span-6 flex justify-start items-center">
                    <div className="ml-1 text-gray-500">شماره فاکتور:</div>
                    <div>{data?.id}</div>
                  </div>
                  <div className="col-span-6 flex justify-start items-center">
                    <div className="ml-1 text-gray-500">تاریخ سفارش:</div>
                    <div>
                      {toStrDatetime(new Date(data?.created_at).getTime())}
                    </div>
                  </div>
                  {/* <div className="flex justify-start items-center">
                    <div className="ml-1 text-gray-500">شماره اشتراک: </div>
                    <div>8585</div>
                  </div> */}
                  <div className="col-span-6 flex justify-start items-center">
                    <div className="ml-1 text-gray-500">مشتری:</div>
                    <div>
                      {first_name || "-"} {last_name || "-"}
                    </div>
                  </div>
                  <div className="col-span-6 flex justify-start items-center">
                    <div className="ml-1 text-gray-500">تلفن:</div>
                    <div>{mobile || telephone || "---"}</div>
                  </div>
                  <div className="col-span-12 flex justify-start items-center">
                    <div className="ml-1 text-gray-500">آدرس تحویل:</div>
                    <div>{description || "---"}</div>
                  </div>
                  <div className="col-span-3 flex items-center justify-start">
                    <div className="text-xs text-gray-500 truncate">
                      اعتبارفاکتور:
                    </div>
                    <div>
                      <div className="relative w-full h-7 px-1 mx-0 bg-sky-50 rounded-lg flex items-center justify-center">
                        <Dropdown
                          name="selectExpireTime"
                          label=""
                          list={[
                            { uuid: 15, name: "۱۵ دقیقه" },
                            { uuid: 30, name: "۳۰ دقیقه" },
                            { uuid: 45, name: "۴۵ دقیقه" },
                            { uuid: 60, name: "۱ ساعت" },
                            { uuid: 120, name: "۲ ساعت" },
                            { uuid: 300, name: "۵ ساعت" },
                            { uuid: 240, name: "۱۰ ساعت" },
                            { uuid: 1440, name: "۲۴ ساعت" },
                            { uuid: 2880, name: "۴۸ ساعت" },
                            { uuid: 4320, name: "۷۲ ساعت" },
                          ]}
                          selectId={expireFactor}
                          onChange={(e: any) =>
                            setExpireFactor(e?.target?.value)
                          }
                          className="text-xs w-16 text-sky-800"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3 flex items-center justify-start">
                    <div className="text-xs text-gray-500 truncate">
                      زمان‌ارسال :
                    </div>
                    <div>
                      <div className="relative w-full h-7 px-1 mx-0 bg-sky-50 rounded-lg flex items-center justify-center">
                        <Dropdown
                          name="selectDeliveryTime"
                          label=""
                          list={[
                            { uuid: 15, name: "۱۵ دقیقه" },
                            { uuid: 30, name: "۳۰ دقیقه" },
                            { uuid: 45, name: "۴۵ دقیقه" },
                            { uuid: 60, name: "۱ ساعت" },
                            { uuid: 90, name: "۱ ساعت و نیم" },
                            { uuid: 120, name: "۲ ساعت" },
                          ]}
                          selectId={deliveryTime}
                          onChange={(e: any) =>
                            setDeliveryTime(e?.target?.value)
                          }
                          className="text-xs w-16 text-sky-800"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3 w-fit flex justify-end px-2 items-center ">
                    {!isReadOnly && (
                      <TimerCountDown
                        close={close}
                        expire_at={data?.expire_at}
                        className={"h-7"}
                      />
                    )}
                  </div>
                  <div className="col-span-3 flex justify-end items-center w-full">
                    <div
                      onClick={resetTable}
                      className={` rounded-lg text-center px-3 h-7 pt-1 w-fit ${
                        isResetFactor
                          ? "bg-sky-50 text-sky-500 border border-sky-500 cursor-pointer"
                          : "bg-gray-50 text-gray-500 border border-gray-500 cursor-auto"
                      }`}
                    >
                      بازگردانی تغییرات
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-full w-full">
                <div className="relative sm:rounded-lg h-fit max-h-h600">
                  <div className="bg-gray-100 rounded-lg p-2 m-2 ">
                    <div className="grid grid-cols-12 grid-rows-1 text-xs text-center py-4">
                      <div className="col-span-1">#</div>
                      <div className="col-span-2">شناسه</div>
                      <div className="col-span-4">عنوان محصول</div>
                      <div className="col-span-1">جایگزینی</div>
                      <div className="col-span-2">قیمت (تومان)</div>
                      <div className="col-span-1 flex justify-center items-center h-full">
                        تعداد
                      </div>
                      <div className="col-span-1 flex justify-center items-center h-full">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all-search"
                            type="checkbox"
                            onChange={toggleSelectAll}
                            checked={selectList.length === list.length}
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                          />
                          <label
                            htmlFor="checkbox-all-search"
                            className="sr-only"
                          >
                            قابل ارائه
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="h-72 overflow-y-auto">
                      {list.map((item: any = {}, index: number) => {
                        // const image = `https://fishopping.ir/images/com_hikashop/upload/thumbnails/200x200f/${item.image}`;
                        const image = `${item.image}`;
                        const isSelectReplace =
                          item?.uuid === isShowSideBar?.uuid;

                        const isChecked = selectList.find(
                          (i: string) => i === item.uuid
                        );
                        return (
                          <div
                            key={`item-list-${index}`}
                            className={`${
                              isSelectReplace ? "bg-green-100" : "bg-white"
                            } rounded-lg my-1 text-center grid grid-cols-12 grid-rows-1 text-xs `}
                          >
                            <div className="pr-1 flex items-center justify-center h-full">
                              {++index}
                            </div>
                            <div className="col-span-2 text-center h-full relative font-medium text-gray-900 whitespace-nowrap">
                              <img
                                className="h-12 pt-2 w-full rounded-lg object-contain bg-contain"
                                src={image}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src =
                                    "/assets/images/image404.svg";
                                }}
                                alt=""
                              />
                              <div className="text-micro text-zinc-500 text-center">
                                {`شناسه : ${item.code}`}
                              </div>
                            </div>
                            <div className="col-span-4 h-full grid row-span-2">
                              <div className="flex items-end justify-start truncate text-ellipsis w-36 text-black">
                                {item?.name}
                              </div>
                              <div className="flex items-start justify-start pt-2 text-micro">
                                {/* <div className="truncate w-28">
                                    {item.alias}
                                  </div> */}
                                <div className="mx-2 px-2 text-center bg-sky-100 text-sky-900 rounded-xl">
                                  {item.category}
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-center items-center h-full">
                              <div
                                onClick={() => {
                                  handleOnChangeCheckbox(item.uuid, false);
                                  handleOpenSideBar(item);
                                }}
                                className="cursor-pointer flex items-center justify-center"
                              >
                                <ReplaceIconSVG color="#5ECD5D" />
                              </div>
                            </div>
                            <div className="col-span-2 flex justify-center items-center h-full">
                              <input
                                value={item?.price?.toLocaleString()}
                                onChange={(e: any) =>
                                  handleOnChangePrice(item, e.target.value)
                                }
                                className="bg-gray-50 rounded border border-gray-100 py-1 text-sm  w-20 text-center"
                              />
                            </div>
                            <div className="flex justify-center items-center h-full">
                              <Counterbutton
                                value={item?.quantity}
                                isLoading={false}
                                onChange={(changeNumber: number) =>
                                  handleChangeQuantity(item, changeNumber)
                                }
                              />
                              {/* {item?.quantity} */}
                            </div>
                            <div className="flex justify-center items-center h-full">
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() =>
                                    handleOnChangeCheckbox(item.uuid, true)
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="grid gap-y-3 grid-cols-5 text-xs m-4">
                    <div className="col-span-3">
                      <div className="flex items-center justify-start">
                        <FactorSearchIconSVG color="#A1A1AA" />
                        <div className="text-zinc-500 mx-2">اقلام فاکتور:</div>
                        <div className="text-blue-900">{list?.length} عدد</div>
                      </div>
                    </div>
                    <div className="col-span-2 flex items-center justify-start">
                      <FactorSearchIconSVG color="#A1A1AA" />
                      <div className="text-zinc-500 mx-2">اقلام انتخابی: </div>
                      <div className="text-blue-900">
                        {selectList?.length} عدد
                      </div>
                    </div>
                    <div className="col-span-3">
                      <div className="flex items-center justify-start">
                        <MoneyIconSVG color="#A1A1AA" />
                        <div className="text-zinc-500 mx-2">
                          مبلغ سفارش مشتری:
                        </div>
                        <div className="text-blue-900">
                          {totalPrice?.toLocaleString()} تومان
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 flex items-center justify-start">
                      <MoneyIconSVG color="#A1A1AA" />
                      <div className="text-zinc-500 mx-2">
                        مبلغ فاکتور فروشگاه:
                      </div>
                      <div className="text-blue-900">
                        {storeSTotalPrice?.toLocaleString()} تومان
                      </div>
                    </div>
                  </div>
                  <div className="grid gap-x-0 grid-cols-1 text-xs m-2">
                    <textarea
                      rows={2}
                      className="w-full leading-6 rounded-lg border py-2 px-3 resize-none text-sky-700 bg-sky-50 border-sky-500 placeholder-sky-700 select-none focus:outline-none"
                      placeholder="پیام مشتری"
                      readOnly
                      value={"پیام مشتری : \n" + (message || "")}
                    />
                    {/* <textarea
                      ref={inputRef}
                      readOnly={isReadOnly}
                      rows={3}
                      className={`w-full rounded-lg border  bg-gray-50 p-3 resize-none ${
                        noteError ? "border-red-400" : "border-gray-400"
                      }`}
                      placeholder="پیام فروشگاه: &#13;&#10;
                      از خرید شما متشکریم. لطفا اگر درخواستی دارید ما را از آن مطلع سازید."
                    /> */}
                  </div>
                  {isReadOnly ? (
                    <div
                      onClick={close}
                      className="m-auto border bg-blue-500 hover:bg-blue-700 text-white p-2 w-11/12 text-center rounded-lg cursor-pointer"
                    >
                      بستن
                    </div>
                  ) : (
                    <div className="flex mt-4">
                      <div
                        onClick={handleOpenChatSide}
                        className="m-auto border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white p-2 w-full text-center rounded-lg mx-2 cursor-pointer"
                      >
                        چت‌شاپینگ
                      </div>

                      <div
                        onClick={handleReject}
                        className="m-auto border border-red-500 text-red-500 hover:bg-red-500 hover:text-white p-2 w-full text-center rounded-lg mx-2 cursor-pointer"
                      >
                        نپذیرفتن فاکتور
                      </div>
                      <div
                        onClick={handleSubmit}
                        className={`m-auto border text-white p-2 w-full text-center rounded-lg mx-2 ${
                          selectList.length
                            ? "bg-green-500 hover:bg-green-700 cursor-pointer"
                            : "bg-gray-400 cursor-not-allowed"
                        }`}
                      >
                        قبول فاکتور و ادامه
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
