import {
  NegativeIconSVG,
  NeutralIconSVG,
  PositiveIconSVG,
} from "src/assets/icons";
import Modal from "./Modal";

export const RateModal = (props: any) => {
  const { close } = props || {};
  const handleSubmit = () => {
    close();
  };
  return (
    <Modal close={close} className="px-0 lg:px-0">
      <div className="text-right text-2xl text-blue-900 px-8 mt-4">
        امتیاز به فروشگاه
      </div>
      <div className="bg-gray-50 w-full py-4 px-6 mt-2 flex">
        <img
          className="h-12 w-12 rounded-lg object-contain bg-contain"
          src={"/assets/images/image404.svg"}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/assets/images/image404.svg";
          }}
          alt=""
        />
        <div className="text-sm px-4">
          <div>افق کوروش (کوکب)</div>
          <div className="flex items-center">
            <div className="flex my-3">
              <div className="text-gray-400">زمان ارسال:</div>
              <div className="mr-2">سه‌شنبه 06 آذر 1401 13:39</div>
            </div>
            <div className="flex mr-4">
              <div className="text-gray-400">مدت زمان تحویل:</div>
              <div className="mr-2">45 دقیقه</div>
            </div>
          </div>
          <div className="flex">
            <div className="text-gray-400">ارسال به:</div>
            <div className="mr-2">
              تهران ، استاد معین ، شهید لشکری ، گلبن پنجم ، پلاک 22 ، طبقه همکف
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 text-xl text-gray-900 mt-6">خریدتان چطور بود؟</div>
      <div className="px-6 text-base text-gray-700 mt-2">
        به فروشگاه امتیاز دهید.
      </div>
      <div className="px-6 flex justify-between items-center w-full mt-6">
        <div className="border rounded-lg w-full py-4 hover:bg-gray-50 cursor-pointer">
          <PositiveIconSVG size={35} />
          <div className="text-xl text-gray-600 text-center mt-1">خوب</div>
        </div>
        <div className="border rounded-lg w-full mx-4 py-4 hover:bg-gray-50 cursor-pointer">
          <NeutralIconSVG size={35} />
          <div className="text-xl text-gray-600 text-center mt-1">معمولی</div>
        </div>
        <div className="border rounded-lg w-full py-4 hover:bg-gray-50 cursor-pointer">
          <NegativeIconSVG size={35} />
          <div className="text-xl text-gray-600 text-center mt-1">منفی</div>
        </div>
      </div>
      <div className="w-full p-6">
        <textarea
          className="w-full rounded-lg border p-3 resize-none text-gray-700  border-gray-100 placeholder-gray-700 "
          rows={6}
          placeholder={"نوشتن نظرات درباره خرید"}
        ></textarea>
        <div
          onClick={handleSubmit}
          className="w-full bg-green-500 hover:bg-green-600 text-center text-white rounded-lg py-2 mt-6 text-lg cursor-pointer"
        >
          ثبت نظر
        </div>
        <div className="text-center text-gray-500 text-base mt-4">
          نظر شما درباره این فروشگاه محفوظ است.
        </div>
      </div>
    </Modal>
  );
};
