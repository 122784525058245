import { MasterApi } from "src/api";
import { setScoreAllInvoice } from "src/features/main/dashboard/dashboardSlice";

//
export const getAllScore = () => async (dispatch: any, getState: any) => {
  const res = await MasterApi({
    method: "get",
    url: "/score/invoice/seller/average",
    data: {},
  });
  if (res?.status?.code === 200) {
    // const FAKE = [
    //   {
    //     scores: 1,
    //     count: 9,
    //   },
    //   {
    //     scores: 2,
    //     count: 1,
    //   },
    //   {
    //     scores: 3,
    //     count: 2,
    //   },
    // ];
    // dispatch(setScoreAllInvoice(FAKE));
    dispatch(setScoreAllInvoice([...res?.data]));
  }
};

export const OrdersCount = (callback: any) => async (dispatch, getState) => {
  const res = await MasterApi({
    method: "get",
    url: "/order/store/count",
    data: {},
  });
  if (res?.status?.code === 200) {
    // callback && callback(res?.data?.orders);
    callback && callback(res?.data?.invoices);
  }
};
