import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export type tabName =
  | "sentToStore"
  | "seenByStore"
  | "changedByStore"
  | "acceptedByStore"
  | "rejectedByStore"
  | "acceptByUser"
  | "rejectedByUser"
  | "rejectedByUserBecauseOtherSeller"
  | "sending"
  | "report";
type MainProps = {
  orderActiveTab: tabName;
  orderList: any;
  factorsList: any;
  factorsListServer: any;
  productInFactor: any;
};
const initialState: MainProps = {
  orderActiveTab: "sentToStore",
  orderList: {
    current_page: 0,
    invoices: [],
    total_items: 0,
    total_pages: 0,
  },
  factorsList: {},
  factorsListServer: {},
  productInFactor: [],
};
interface PayloadState {
  payload: tabName;
}
export const ordersSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderActiveTab: (state, action: PayloadState) => {
      state.orderActiveTab = action.payload;
    },
    setOrderList: (state, action) => {
      state.orderList = action.payload;
    },
    updateOrderList: (state, action) => {
      const isNeedUpdate = state.orderList?.invoices?.find(
        (i: any) => i.uuid === action.payload.uuid
      );
      var updateList: any = [];
      if (isNeedUpdate) {
        // update list
        updateList = state.orderList?.invoices.map((i: any) => {
          if (i.uuid === action.payload.uuid) return { ...action.payload };
          else return { ...i };
        });
      } else {
        // add to list
        updateList = [action.payload, ...state.orderList?.invoices];
      }
      state.orderList = {
        ...state.orderList,
        invoices: updateList,
      };
    },
    deleteItemOrderList: (state, action) => {
      state.orderList = {
        ...state.orderList,
        invoices: state.orderList?.invoices.filter(
          (i: any) => i.uuid !== action.payload.uuid
        ),
      };
    },
    addFactorInList: (state, action) => {
      const keyId = Object.keys(action.payload)[0];
      if (state.factorsList[keyId] === undefined) {
        state.factorsList = { ...state.factorsList, ...action.payload };
      }
      state.factorsListServer = {
        ...state.factorsListServer,
        ...action.payload,
      };
      // state.factorsList = { ...action.payload };
    },
    setProductInFactor: (state, action) => {
      state.productInFactor = action.payload;
    },
    resetProductInFactor: (state) => {
      state.productInFactor = initialState.productInFactor;
    },
    putFactorInList: (state, action) => {
      state.factorsList = action.payload;
    },
    //  جابجایی محصول
    putFactorInListOriginal: (state, action) => {
      state.factorsListServer = action.payload;
    },
    resetFactorInList: (state) => {
      state.factorsList = state.factorsListServer;
    },
  },
});

export const {
  setOrderActiveTab,
  setOrderList,
  updateOrderList,
  deleteItemOrderList,
  addFactorInList,
  setProductInFactor,
  resetProductInFactor,
  putFactorInList,
  resetFactorInList,
  putFactorInListOriginal,
} = ordersSlice.actions;

export const selectOrderActiveTab = (state: RootState) =>
  state.order.orderActiveTab;
export const selectOrderList = (state: RootState) => state.order.orderList;
export const selectOrderInvoicesList = (state: RootState) =>
  state.order.orderList.invoices;
export const selectFaktorList = (state: RootState) => state.order.factorsList;
export const selProductInFactor = (state: RootState) =>
  state.order.productInFactor;
export const selectFaktorListOriginal = (state: RootState) =>
  state.order.factorsListServer;

export default ordersSlice.reducer;
