import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const initialState: any = {
  storeSetting: [],
};
export const similarSlice = createSlice({
  name: "storeSettings",
  initialState,
  reducers: {
    setMyStoreSettings: (state, action) => {
      state.storeSetting = action.payload;
    },
  },
});

export const { setMyStoreSettings } = similarSlice.actions;

export const selStoreSetting = (state: RootState) =>
  state.storeSettings.storeSetting;
export default similarSlice.reducer;
