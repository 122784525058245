import { useState } from "react";
import { useAppDispatch } from "src/app/hooks";
import DInput from "src/components/input/input";
import { notify } from "src/components/notify";
import { resetAccountPassword } from "src/controllers/myStoreSettingsController";

export const StoreChangePassword = () => {
  const [state, setState] = useState<any>();
  const [error, setError] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: any = {}) => {
    const { name, value } = e.target;
    setState((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    if (state.new_password !== state.confirm_new_password) {
      setError(true);
      notify.error("پسوردها یکسان نیست");
      return;
    }
    setError(false);
    const { old_password, new_password } = state || {};
    dispatch(resetAccountPassword({ old_password, new_password }));
  };

  return (
    <div className="pb-10">
      <div className="text-blue-900 py-4 px-2 text-xl font-bold">
        تغییر رمز عبور
      </div>
      <div className="w-96 mx-20">
        <DInput
          name={"old_password"}
          label={"رمز عبور قبلی"}
          color={"gray"}
          onChange={handleOnChange}
          type={"password"}
          className="mt-7"
        />
        <DInput
          name={"new_password"}
          label={"رمز عبور جدید"}
          color={"gray"}
          onChange={handleOnChange}
          type={"password"}
          className="mt-7"
        />
        <DInput
          name={"confirm_new_password"}
          label={"تکرار رمز عبور جدید"}
          color={"gray"}
          onChange={handleOnChange}
          type={"password"}
          className="mt-7"
        />
        <div className="flex mt-12">
          <div className="w-full text-center py-2 m-auto  text-gray-600 rounded-lg cursor-pointer hover:bg-gray-300 bg-gray-200">
            انصراف
          </div>
          <div className="w-4" />
          <div
            className="w-full text-center py-2 m-auto  text-white rounded-lg cursor-pointer hover:bg-blue-900 bg-blue-900"
            onClick={handleSubmit}
          >
            ثبت
          </div>
        </div>
      </div>
    </div>
  );
};
