import { useSelector } from "react-redux";
import { selectProfile, setProfile } from "../profile/storeSlice";
import { useAppDispatch } from "src/app/hooks";
import { useEffect, useRef, useState } from "react";
import useOutsideClick from "src/hooks/useOutSideClick";
import { useFormik } from "formik";
import * as Yup from "yup";
import { notify } from "src/components/notify";
import { getProfile, putProfile } from "src/controllers/ProfileController";
import DInput from "src/components/input/input";
import { CircleMap } from "src/components/maps/circleLocation";

export const StoreInfo = () => {
  const selProfile: any = useSelector(selectProfile);
  const dispatch: any = useAppDispatch();
  const [suggest, setSuggest] = useState([]);
  const [searchText, setSearchText] = useState("");
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setSuggest([]));
  const formik: any = useFormik({
    initialValues: {
      shop_name: "",
      register_id: "",
      mobile: "",
      phone: "",
      shop_manager_name: "",
      shop_owner_name: "",
      address: selProfile.address || "",
    },
    validationSchema: Yup.object().shape({
      shop_name: Yup.string().trim().required("وارد کردن نام فروشگاه الزامیست"),
      register_id: Yup.string().trim(),
      mobile: Yup.string()
        .trim()
        .required("وارد کردن این فیلد الزامیست")
        .min(10, "تعداد کاراکترها درست نیست")
        .max(13, "تعداد کاراکترها درست نیست")
        .matches(/^[0-9۰-۹]*$/, "لطفا از اعداد استفاده کنید"),
      shop_manager_name: Yup.string()
        .trim()
        .required("وارد کردن این فیلد الزامیست"),
      shop_owner_name: Yup.string()
        .trim()
        .required("وارد کردن این فیلد الزامیست"),
      address: Yup.string().trim().required("وارد کردن این فیلد الزامیست"),
      phone: Yup.string()
        .trim()
        .required("وارد کردن این فیلد الزامیست")
        .length(11, "تعداد کاراکترها درست نیست")
        .matches(/^[0-9۰-۹]*$/, "لطفا از اعداد استفاده کنید"),
      // .test(
      //   "no-leading-zero",
      //   "Leading zero is not allowed",
      //   (value, context: any) => {
      //     const { originalValue = "" } = context || {};
      //     return originalValue && !originalValue.startsWith("0");
      //   }
      // ),
    }),
    onSubmit: (values) => {
      const resetAndClose = (result: boolean) => {
        if (result) {
          notify.info("با موفقیت آپدیت شد");
          dispatch(getProfile(console.log));
          formik.resetForm();
        } else {
          notify.error("خطا در عملیات");
        }
      };
      dispatch(putProfile(values, resetAndClose));
    },
  });
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length > 1) {
        handleSearch();
      } else if (searchText.length === 0) {
        // setList([]);
      }
    }, 1700);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    Object.keys(selProfile).map((name) => {
      const value = selProfile[name];
      value && formik.setFieldValue(name, value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selProfile]);

  const handleCloseModal = () => {};

  const handleClickAddressList = async (item: any) => {
    setSuggest([]);
    formik.handleChange({
      target: { name: "address", value: item.description },
    });
    const { lat, lng } = item.geo_location.center;
    dispatch(setProfile({ lat, lng }));
  };
  const handleSearch = async () => {
    const res: any = await fetch(
      `https://api.parsimap.ir/geocode/forward?key=p1e35220f589044556a90a0971ec5a8fe14d45670d&search_text=${searchText
        .toString()
        .trim()}&district=10511133&only_in_district=true&subdivision=false&plate=false&request_id=false&search_precision=simple`
    ).then((rr: any) => rr.text());
    const objResult = await JSON.parse(res);
    //todo: set to redux
    setSuggest(objResult?.results || []);
  };

  return (
    <>
      <div className="text-blue-900 py-4 px-2 text-xl font-bold">
        فروشگاه من
      </div>
      <form
        className="w-4/5 grid grid-cols-2 gap-6"
        onSubmit={formik.handleSubmit}
      >
        <div className="col-span-1">
          <DInput
            name={"shop_name"}
            label={"نام فروشگاه"}
            color={formik.errors.shop_name ? "red" : "gray"}
            onChange={formik.handleChange}
            value={formik.values.shop_name}
          />
        </div>
        <div className="col-span-1">
          <DInput
            name={"register_id"}
            label={"شناسه ثبت"}
            color={"gray"}
            onChange={formik.handleChange}
            value={formik.values.register_id}
          />
        </div>
        <div className="col-span-1 mt-2">
          <DInput
            name={"mobile"}
            label={"تلفن همراه"}
            color={formik.errors.mobile ? "red" : "gray"}
            onChange={formik.handleChange}
            value={formik.values.mobile}
          />
        </div>
        <div className="col-span-1 mt-2">
          <DInput
            name={"phone"}
            label={"تلفن فروشگاه"}
            color={formik.errors.phone ? "red" : "gray"}
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
        </div>
        <div className="col-span-1 mt-2">
          <DInput
            name={"shop_manager_name"}
            label={"نام و نام خانوادگی مدیر فروشگاه"}
            color={formik.errors.shop_manager_name ? "red" : "gray"}
            onChange={formik.handleChange}
            value={formik.values.shop_manager_name}
          />
        </div>
        <div className="col-span-1 mt-2">
          <DInput
            name={"shop_owner_name"}
            label={"نام و نام خانوادگی مالک فروشگاه"}
            color={formik.errors.shop_owner_name ? "red" : "gray"}
            onChange={formik.handleChange}
            value={formik.values.shop_owner_name}
          />
        </div>
        <div className="col-span-2 mt-2">
          <DInput
            name={"address"}
            label={"آدرس دقیق"}
            color={formik.errors.address ? "red" : "gray"}
            onChange={(e) => {
              formik.handleChange(e);
              setSearchText(e.target.value);
            }}
            value={formik.values.address}
          />
        </div>
        {suggest.length ? (
          <div
            ref={wrapperRef}
            className="z-50 bg-white rounded-lg mt-2 shadow-lg fixed overflow-auto w-96 h-fit max-h-72 border border-zinc-300"
          >
            {suggest?.map((item: any) => {
              return (
                <div
                  onClick={() => handleClickAddressList(item)}
                  className="text-sm p-4 border-b border-zinc-300 cursor-pointer hover:bg-zinc-100"
                >
                  {item.description}
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
        <div className="col-span-2 mt-2">
          <div className="h-80 bg-gray-100 rounded-xl">
            <CircleMap />
          </div>
        </div>
        <div className="col-span-1 col-start-2 flex justify-end">
          <div className="w-44 bg-gray-200 hover:bg-gray-300 rounded-lg py-2 px-3 text-center mx-1 cursor-pointer">
            انصراف
          </div>
          <button
            type="submit"
            className="w-44 bg-blue-800 hover:bg-blue-900 text-white rounded-lg py-2 px-3 text-center mx-1 cursor-pointer"
          >
            ثبت اطلاعات
          </button>
        </div>
      </form>
    </>
  );
};
