import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SocketContext } from "src/api/socketContext";
import { useAppDispatch } from "src/app/hooks";
import { SearchIcon, SortIconSVG } from "src/assets/icons";
import { getSearch } from "src/controllers/searchProductController";
import {
  resetSimilarProducts,
  selectorSimilarCurrentPage,
  selectorSimilarProducts,
  selectorSimilarTotalItem,
} from "src/features/main/siimilarSlice";
import { ProductType } from "src/models";
import Loading from "../loading/Loading";
import { EmptyComponent } from "../empty/emptyComponent";
const getHeightStyle = (size: number) => {
  if (size < 2) return "h-h400";
  else if (size < 3) return "h-h500";
  else if (size < 4) return "h-h550";
  else if (size < 6) return "h-h600";
  else return "h-h650";
};
export const ReplaceProductSide = (props: any) => {
  const { data = {}, size, onClickItem } = props || {};
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const [list, setList] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const ws: any = useContext(SocketContext);
  const selProducts = useSelector(selectorSimilarProducts);
  const selCurrentPage = useSelector(selectorSimilarCurrentPage);
  const selTotalItem = useSelector(selectorSimilarTotalItem);
  const listInnerRef: any = useRef();
  const [prevPage, setPrevPage] = useState(0); // storing prev page number
  const [wasLastList, setWasLastList] = useState(false);

  // نسبت ارتفاع لیست به مدال کناری
  let sizeStyle = getHeightStyle(size);
  useEffect(() => {
    if (data?.uuid && data?.invoice_uuid) {
      // data?.category && setSearchText(data?.category);
      dispatch(resetSimilarProducts());
      ws.sendMessage(
        "get_similar_products",
        data?.uuid,
        data?.invoice_uuid,
        100,
        1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setLoading(false);
    const fetchData = async () => {
      if (selProducts.length === 0) {
        setWasLastList(true);
        return;
      }
      setPrevPage(selCurrentPage);
      setList([...selProducts]);
    };

    // if (!wasLastList && prevPage !== selCurrentPage && selProducts.length) {
    if (selCurrentPage && selProducts.length) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selProducts]);

  // useEffect(() => {
  //   setLoading(true);
  //   const delayDebounceFn = setTimeout(() => {
  //     setLoading(false);
  //     if (searchText.length > 1) {
  //       dispatch(getSearch(searchText, handleSimpleSearch));
  //     } else if (searchText.length === 0) {
  //       setList([]);
  //     }
  //   }, 2000);

  //   return () => clearTimeout(delayDebounceFn);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchText]);

  const handleSimpleSearch = (data: any) => {
    setList(data.products);
    // setPagination({
    //   total_items: data?.total_items,
    //   total_pages: data?.total_pages,
    //   current_page: data?.current_page,
    // });
  };

  //getSearch
  const handleSearch = (val: string) => {
    setSearchText(val);
    const newList = [...selProducts].filter((i: any) => i.name.includes(val));
    setList(val ? [...newList] : [...selProducts]);
  };

  const onKeyPress = (e: any) => {
    if (e.charCode === 13) {
      if (searchText.length > 1) {
        // dispatch(getSearch(searchText, () => handleSearch(searchText)));
      }
    }
  };

  const sortBy = (name: string) => {
    setLoading(true);
    var swapList = list.slice() || [];
    var sortList = swapList?.sort(function (a: any, b: any) {
      const returnItem = parseInt(a[name]) - parseInt(b[name]);
      return returnItem;
    });
    sortList && setList([...sortList]);
    setLoading(false);
  };
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
        return;
        ws.sendMessage(
          "get_similar_products",
          data?.uuid,
          data?.invoice_uuid,
          100,
          2
        ); // limit , page
      }
    }
  };

  return (
    <div className="bg-gray-50 rounded-md h-full mx-2 px-2 pt-4">
      <div
        className={`flex items-center justify-between box-border bg-white border-solid rounded-md border-2 h-10 px-4 w-full border-sky-300 `}
      >
        <input
          className="text-right flex-grow mr-1 outline-none font text-sm bg-white  "
          onKeyPress={onKeyPress}
          onChange={(e) => handleSearch(e.target.value)}
          value={searchText}
          placeholder={"محصول موجود"}
          type="text"
          autoFocus
        ></input>
        <SearchIcon color="#555" size={18} />
      </div>
      <div className={`${sizeStyle}`} onScroll={onScroll} ref={listInnerRef}>
        <div className="text-xs w-full text-gray-500 mt-4 relative">
          <div className="w-full h-10 grid grid-rows-1 grid-cols-12 text-gray-700 border-b-2 border-gray-300">
            <div className="col-span-1 flex items-center justify-center">#</div>
            <div className="col-span-1 flex items-center justify-center">
              شناسه
            </div>
            <div className="col-span-4 flex items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="mx-2">عنوان محصول</div>
                {/* <SortIconSVG /> */}
              </div>
            </div>
            <div
              className="col-span-2 flex items-center justify-center"
              onClick={() => sortBy("weight")}
            >
              <div className="flex items-center justify-center">
                <div className="mx-2">مقدار</div>
                <SortIconSVG />
              </div>
            </div>
            <div className="col-span-2 flex items-center justify-center">
              <div
                onClick={() => sortBy("price")}
                className="flex items-center justify-center"
              >
                <div className="mx-0">قیمت (تومان)</div>
                <SortIconSVG />
              </div>
            </div>
            <div className="col-span-2 flex items-center justify-center text-center flex-nowrap">
              عملیات
            </div>
          </div>

          <div className="w-full overflow-y-scroll h-h600">
            {list.length ? (
              [...list]?.map((item: ProductType, index) => {
                //
                const priceColor: any =
                  item.price > data.price
                    ? { icon: <>&#8593;</>, color: "text-red-500" }
                    : item.price < data.price
                    ? { icon: <>&#8595;</>, color: "text-green-500" }
                    : { icon: <></>, color: "text-gray-500" };
                //
                const weightColor: any =
                  Number(item.weight) < Number(data.weight)
                    ? { icon: <>&#8595;</>, color: "text-red-500" }
                    : Number(item.weight) > Number(data.weight)
                    ? { icon: <>&#8593;</>, color: "text-green-500" }
                    : { icon: <></>, color: "text-gray-500" };

                const isSelected = item.uuid === data.uuid;
                return (
                  <div
                    key={`${index}-${item.uuid}-${item.price}`}
                    id={`${index}-${item.uuid}-${item.price}`}
                    className={`${
                      isSelected ? "bg-gray-100" : "bg-white hover:bg-gray-50"
                    } grid grid-cols-12 grid-rows-1 w-full h-12 border-b-2 border-gray-100 text-center `}
                  >
                    <div className="flex items-center justify-center">
                      {index + 1}
                    </div>
                    <div className="flex items-center justify-center text-xs">
                      {item.code}
                    </div>
                    <div className="flex items-center justify-start pr-2 col-span-4 truncate text-ellipsis">
                      {item?.name}
                    </div>

                    <div
                      className={
                        "col-span-2 flex items-center justify-center " +
                        weightColor.color
                      }
                    >
                      {weightColor.icon} {Number(item?.weight || "0")}{" "}
                      {item?.weight_unit || ""}
                    </div>
                    <div
                      className={
                        "col-span-2 flex items-center justify-center " +
                        priceColor.color
                      }
                    >
                      {priceColor?.icon} {item?.price?.toLocaleString() || ""}
                    </div>
                    <div className="col-span-2 flex items-center justify-center">
                      <button
                        onClick={() => (isSelected ? null : onClickItem(item))}
                        className={`text-center p-2 rounded-md text-white w-fit truncate ${
                          isSelected
                            ? "bg-gray-400 cursor-not-allowed"
                            : "bg-green-500 hover:bg-green-700"
                        }`}
                      >
                        {isSelected ? "محصول انتخابی" : "تعویض محصول"}
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                {isLoading ? (
                  <Loading />
                ) : (
                  <EmptyComponent title="نتیجه ای یافت نشد" />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
