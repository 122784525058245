import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import { MasterApi } from "src/api";
import { setStep } from "src/features/main/mainSlice";
import { setProfile } from "src/features/profile/storeSlice";

export const putProfile =
  (data: any, callback: Function) => async (dispatch: any, getState: any) => {
    const { province_uuid, city_uuid, region_uuid, lat, lng } =
      getState().store.profile;
    const profile: any = {
      shop_name: data.shop_name,
      address: data.address,
      lat,
      lng,
      shop_manager_name: data.shop_manager_name,
      shop_owner_name: data.shop_owner_name,
      phone: data.phone,
    };
    const res = await MasterApi({
      method: "put",
      url: "/store",
      data: profile,
    });
    if (res?.status?.code === 200) {
      callback && callback(true);
    } else {
      callback && callback(false);
      //todo: show toast error
    }
  };

export const getProfile =
  (callback: Function) => async (dispatch: any, getState: any) => {
    const res = await MasterApi({
      method: "get",
      url: "/store",
      data: {},
    });
    if (res?.status?.code !== 200) return;

    if (res?.data?.uuid) {
      dispatch(setProfile({ ...res?.data }));
      dispatch(setStep("isValidateToken"));
      callback && callback(true);
    } else {
      // dispatch(setProfile({}));
      dispatch(setStep("needProfile"));
      callback && callback(false);
    }
  };

export const uplaodImage =
  (
    token: string,
    file: any,
    link: string,
    onUploadProgress: any,
    callback: any
  ) =>
  async (dispatch: any, getState: any) => {
    let formData = new FormData();
    formData.append("file", file);
    const res = await axios.post(link, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
      onUploadProgress,
    });
    if (res.data.status.code === 200) {
      callback && callback(res?.data?.data?.url);
    }
  };

// const _HEADERS = { "Content-Type": "application/json" };
// const _REQUEST_TIMEOUT = 8000;
// const _UPLOAD_TIMEOUT = 240000;

// const WebService = {
//   _tryParse(value: any, url: any, params: any, status: any, method: any) {
//     let result = {};
//     try {
//       result = JSON.parse(value);
//     } catch (ignored) {}
//     return result;
//   },

//   _requestSync(params: any, url, options: any, onProgress) {
//     options = {
//       timeout: options?.timeout || _REQUEST_TIMEOUT,
//       headers: options?.headers || _HEADERS,
//       method: options?.method || "POST",
//     };
//     const headerKeys = Object.keys(options?.headers);
//     const headerVals = Object.values(options?.headers);

//     if (options?.method === "GET") {
//       if (params && Object.keys(params).length > 0) {
//         url = `${url}?${Object.keys(params)
//           .map(
//             (k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
//           )
//           .join("&")}`;
//       }
//     }

//     return new Promise((resolve, reject) => {
//       const xhr: any = new XMLHttpRequest();

//       xhr.open(options?.method, url);
//       xhr.timeout = options?.timeout;

//       for (const index in headerKeys) {
//         xhr.setRequestHeader(headerKeys[index], headerVals[index]);
//       }

//       if (headerKeys["Content-Type"] === undefined) {
//         xhr.setRequestHeader("Content-Type", "application/json");
//       }

//       if (xhr.upload && onProgress) {
//         xhr.upload.onprogress = onProgress;
//       }

//       xhr.onload = (event) => {
//         resolve(
//           WebService._tryParse(
//             event.target.responseText,
//             url,
//             params,
//             xhr.status,
//             options?.method
//           )
//         );
//       };

//       xhr.onerror = (error) => {
//         reject(error);
//       };

//       xhr.onabort = () => {
//         reject("abort");
//       };

//       xhr.ontimeout = () => {
//         reject("timeout");
//       };

//       if (options?.method === "GET") {
//         xhr.send();
//       }

//       if (options?.method === "POST") {
//         xhr.send(JSON.stringify(params));
//       }

//       xhr.stop = xhr.abort;

//       setTimeout(() => {
//         if (xhr && !xhr._aborted) {
//           xhr.abort();
//           reject("abort");
//         }
//       }, options?.timeout);
//     });
//   },

//   _upload(params, url, options: any, onProgress) {
//     options = {
//       timeout: options?.timeout || _UPLOAD_TIMEOUT,
//       headers: options?.headers || _HEADERS,
//       method: "POST",
//     };

//     const headerKeys: any = Object.keys(options?.headers);
//     const headerVals: any = Object.values(options?.headers);

//     return new Promise((resolve, reject) => {
//       const xhr: any = new XMLHttpRequest();

//       xhr.open(options?.method, url);
//       xhr.timeout = options?.timeout;

//       for (const index in headerKeys) {
//         xhr.setRequestHeader(headerKeys[index], headerVals[index]);
//       }

//       if (xhr.upload && onProgress) {
//         xhr.upload.onprogress = onProgress;
//       }

//       xhr.onload = (event: any) => {
//         resolve(
//           WebService._tryParse(event.target.responseText, {}, {}, {}, {})
//         );
//       };

//       xhr.onerror = (error) => {
//         reject(error);
//       };

//       xhr.onabort = () => {
//         reject("abort");
//       };

//       xhr.ontimeout = () => {
//         reject("timeout");
//       };

//       xhr.send(params);

//       xhr.stop = xhr.abort;
//     });
//   },
// };
