import { createSlice } from "@reduxjs/toolkit";
import { deleteJsonItem } from "src/utils";
import { RootState } from "../../app/store";
type StateProps = {
  list: any;
};
const initialState: StateProps = {
  list: {},
};
export type NotificationNames = "new_invoice" | "all";
export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    updateNotificatoin: (state, action) => {
      var newList: any = { ...state.list };
      newList[action.payload.key] =
        Number(newList[action.payload.key] || 0) + Number(action.payload.value);
      state.list = newList;
    },
    deleteNotificatoin: (state, action) => {
      state.list = deleteJsonItem(state.list, action.payload);
    },
  },
});

export const { updateNotificatoin, deleteNotificatoin } =
  notificationSlice.actions;

export const selectNotifiication = (state: RootState) =>
  state.notifications.list;

export default notificationSlice.reducer;
