import { MasterApi } from "src/api";
import { notify } from "src/components/notify";
import {
  deleteDeliveryBikes,
  setDeliveryBikes,
} from "src/features/main/delivery/deliveryBikesSlice";

export const searchDeliveryBikes =
  (bike_identification: string, callback: Function) =>
  async (dispatch: any, getState: any) => {
    if (!bike_identification) return;
    const res: any = await MasterApi({
      method: "get",
      url: "/store/bikes/search?bike_identification=" + bike_identification,
      data: {},
    });
    if (res?.status?.code === 200) callback(res.data || []);
    else callback([]);
  };
export const getDeliveryBikes = () => async (dispatch: any, getState: any) => {
  const res: any = await MasterApi({
    method: "get",
    url: "/store/bikes",
    data: {},
  });
  if (res?.status?.code === 200) {
    dispatch(setDeliveryBikes(res?.data));
  }
};
export const setAddDeliveryBike =
  (bike_identification: string, callback: Function) =>
  async (dispatch: any, getState: any) => {
    const res: any = await MasterApi({
      method: "post",
      url: "/store/bikes",
      data: {
        bike_identification,
      },
    });
    if (res?.status?.code === 1035) {
      notify.info(res?.status?.message);
      dispatch(getDeliveryBikes());
      callback(true);
    } else {
      callback(false);
    }
    // dispatch(addDeliveryBike())
  };
export const deleteDeliveryBike =
  (bike_identification: string) => async (dispatch: any, getState: any) => {
    const res: any = await MasterApi({
      method: "delete",
      url: "/store/bikes",
      data: {
        params: { bike_identification },
      },
    });
    if (res?.status?.code !== 1036) return;
    dispatch(deleteDeliveryBikes(bike_identification));
  };

export const selectDeliveryBike =
  (data: any, callback: Function) => async (dispatch: any, getState: any) => {
    const res: any = await MasterApi({
      method: "post",
      url: "/invoice/delivery/bike",
      data: {
        estimated_delivery_time: 30,
        ...data,
      },
    });
    if (res?.status?.code === 200) callback(true);
    else notify.error(res?.status?.message);
  };
