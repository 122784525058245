import moment from "moment-jalaali";

/**
 * get query params with param name => queryParams.token
 */
export const queryParams: any = new Proxy(
  new URLSearchParams(window.location.search),
  {
    get: (searchParams, queryName: string) => searchParams.get(queryName),
  }
);
export const deleteAllQueryParams = () => {
  window.history.replaceState({}, document.title, "/");
};
export function deleteJsonItem(list: any, deleteKey: any) {
  // if (obj[prop] && !obj[prop].length) delete obj[prop];
  let newData: any = {};
  Object.keys(list).map((key) => {
    if (deleteKey !== key) {
      newData[key] = list[key];
    }
    return null;
  });
  return newData;
}
function radians(degrees: number) {
  return degrees * (Math.PI / 180);
}
export function randomGeo(lat: number, lng: number, radius: number) {
  var radiusInDegrees = radius / 111300;
  var u = 1; //Math.random();
  var v = 1; //Math.random();
  var w = radiusInDegrees * Math.sqrt(u);
  var t = 2 * Math.PI * v;
  var x = w * Math.cos(t);
  var y = w * Math.sin(t);
  var xp = x / radians(lat);
  var yp = y / radians(lng);
  return [
    [lat + xp * 1, lng + yp * 1],
    [lat + xp * 2, lng + yp * 2],
    [lat + xp * 3, lng + yp * 3],
    [lat + xp * 4, lng + yp * 4],
    [lat + xp * 5, lng + yp * 5],
  ];
}
export function getRandomLocation(
  latitude: any,
  longitude: any,
  radiusInMeters: any
) {
  var getRandomCoordinates = function (radius: any, uniform: any) {
    // Generate two random numbers
    var a = Math.random(),
      b = Math.random();

    // Flip for more uniformity.
    if (uniform) {
      if (b < a) {
        var c = b;
        b = a;
        a = c;
      }
    }

    // It's all triangles.
    return [
      b * radius * Math.cos((2 * Math.PI * a) / b),
      b * radius * Math.sin((2 * Math.PI * a) / b),
    ];
  };
  var randomCoordinates = getRandomCoordinates(radiusInMeters, true);
  // Earths radius in meters via WGS 84 model.
  var earth = 6378137;
  // Offsets in meters.
  var northOffset = randomCoordinates[0],
    eastOffset = randomCoordinates[1];
  // Offset coordinates in radians.
  var offsetLatitude = northOffset / earth,
    offsetLongitude =
      eastOffset / (earth * Math.cos(Math.PI * (latitude / 180)));
  // Offset position in decimal degrees.
  return {
    latitude: latitude + offsetLatitude * (180 / Math.PI),
    longitude: longitude + offsetLongitude * (180 / Math.PI),
  };
}

export function getNewGeoByKm(lat: number, lng: number, distance_km: number) {
  return (
    6371 *
    Math.acos(
      Math.cos(radians(lat)) *
        Math.cos(radians(lat)) *
        Math.cos(radians(lng) - radians(lng)) +
        Math.sin(radians(lat)) * Math.sin(radians(lat))
    )
  );
}
export function mergeListsBy(oldList: any, newList: any, key: string) {
  return [
    ...[...oldList, ...newList]
      .reduce(
        (acc, curr) => acc.set(curr[key], { ...acc.get(curr[key]), ...curr }),
        new Map()
      )
      .values(),
  ];
}
export function addMinutes(date: any, minutes: number) {
  return new Date(date.getTime() + minutes * 60000);
}
function get_cookie(name: string) {
  return document.cookie.split(";").some((c) => {
    return c.trim().startsWith(name + "=");
  });
}
export function deleteCookie(name: string, path: string, domain: string) {
  if (get_cookie(name)) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}
export function getStoreStatus(statusId: number) {
  switch (statusId) {
    case 1: {
      //ACTIVE_CART
      return {
        id: 1,
        name: "مشاهده نشده",
        color: "text-green-500 font-bold",
        btnTitle: "مشاهده فاکتور",
      };
    }
    case 2: {
      //AWAITING_INVOICE
      return {
        id: 2,
        name: "مشاهده شده",
        color: "text-sky-500 font-normal",
        btnTitle: "بررسی فاکتور",
      };
    }
    case 3: {
      //IN_PROGRESS
      return {
        id: 3,
        name: "فاکتور تغییر کرده",
        color: "text-amber-800 font-normal",
        btnTitle: "بررسی فاکتور",
      };
    }
    case 4: {
      //COMPLETED
      return {
        id: 4,
        name: "در انتظار تایید مشتری",
        btnTitle: "بررسی فاکتور",
        color: "text-blue-800",
      };
    }
    case 5: {
      //WISHLIST
      return {
        id: 5,
        name: "رد شده توسط خودم",
        btnTitle: "بررسی فاکتور",
        color: "text-red-700",
      };
    }
    case 6: {
      //FUTURE_CART
      return {
        id: 6,
        name: "تائید شده توسط مشتری",
        btnTitle: "تحویل به پیک",
        color: "text-lime-700",
      };
    }
    case 7: {
      //FUTURE_CART
      return {
        id: 7,
        name: "رد شده توسط مشتری",
        btnTitle: "بررسی فاکتور",
        color: "text-cyan-600",
      };
    }
    case 8: {
      //FUTURE_CART
      return {
        id: 8,
        name: "رد شده توسط رقبا",
        btnTitle: "بررسی فاکتور",
        color: "text-fuchsia-700",
      };
    }
    case 11: {
      return {
        id: 11,
        name: "در حال ارسال",
        btnTitle: "تحویل داده شد",
        color: "text-blue-500",
        bgBtnColor: "bg-green-500",
      };
    }
    case 9: {
      //FUTURE_CART
      return {
        id: 9,
        name: "اتمام فرایند",
        btnTitle: "تحویل دادم",
        color: "text-red-500",
        bgBtnColor: "bg-green-500",
      };
    }
    default:
      break;
  }
}

export const removePreCodeInNumber = (number2: any) => {
  var number: any = number2.trim();
  if (number.substring(3, 0) === "+98") {
    number = number.replace("+98", "0");
  } else if (number.substring(2, 0) == "98") {
    number = number.replace("98", "0");
  } else if (number.substring(1, 0) !== "0") {
    number = `0${number}`;
  }
  return number;
};
export const toNumberDatetime = (unixtime: any) => {
  var today: any = new Date();
  var diffMs = unixtime - today; // milliseconds between now & select date
  var diffDays = Math.floor(diffMs / 86400000); // days
  var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  const updateDays = diffDays > 0 ? `${diffDays}  روز, ` : "";
  const updateHrs = diffHrs > 0 ? `${diffHrs}  ساعت, ` : "";
  const updateMins = diffMins > 0 ? `${diffMins}  دقیقه` : "";
  if (updateDays || updateHrs || updateMins) {
    return updateDays + updateHrs + updateMins;
  }
  return null;
};
export const createGeoJSONCircle = (
  center: any,
  radiusInKm: any,
  points: any
) => {
  if (!points) points = 64;

  var coords: any = {
    latitude: center[1],
    longitude: center[0],
  };

  var km = radiusInKm;

  var ret: any = [];
  var distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  var distanceY = km / 110.574;

  var theta: any, x: any, y: any;
  for (var i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords?.longitude + x, coords?.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [ret],
          },
        },
      ],
    },
  };
};
export const generateID = function (length = 6) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
};

export const generatePaymentType = (type: number) => {
  let typeLabel = "";
  let typeColor = "";
  switch (type) {
    case 1: // 1: 'minus_by_system',
      typeLabel = "کسر موجودی توسط سیستم";
      typeColor = "bg-yellow-100 text-yellow-800";
      break;
    case 2: // 2: 'minus_by_manual',
      typeLabel = "کسر موجودی به صورت دستی";
      typeColor = "bg-emerald-100 text-emerald-800";
      break;
    case 3: // 3: 'add_by_gateway',
      typeLabel = "از طریق درگاه پرداخت";
      typeColor = "bg-orange-100 text-orange-800";
      break;
    case 4: // 4: 'add_by_manual',
      typeLabel = "افزایش موجودی به صورت دستی";
      typeColor = "bg-green-100 text-green-800";
      break;
    case 5: // 5: 'minus_by_seen_invoice'
      typeLabel = "مشاهده سفارش";
      typeColor = "bg-sky-100 text-sky-800";
      break;
    default:
      typeLabel = "سایر موارد";
      typeColor = "";
      break;
  }
  return { typeLabel, typeColor };
};
