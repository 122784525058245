import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const initialState: any = {
  list: [],
  meta: {},
  chatTemplates: [],
  isTypingId: "",
};

export const mainSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChatList: (state, action) => {
      state.list = action.payload;
    },
    addToChatList: (state, action) => {
      if (state.list.find((i: any) => i.uuid === action.payload.uuid)) {
        return;
      }
      state.list = [...state.list, action.payload];
    },
    setChatListMeta: (state, action) => {
      state.meta = action.payload;
    },
    setChatTemplateList: (state, action) => {
      state.chatTemplates = action.payload;
    },
    setIsTypingId: (state, action) => {
      state.isTypingId = action.payload;
    },
  },
});

export const {
  setChatList,
  addToChatList,
  setChatListMeta,
  setChatTemplateList,
  setIsTypingId,
} = mainSlice.actions;

export const selChatsList = (state: RootState) => state.chat.list;
export const selTemplatesList = (state: RootState) => state.chat.chatTemplates;

export default mainSlice.reducer;
