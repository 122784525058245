import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/app/hooks";
import {
  ArrowIcon,
  CircleInfoIcon,
  DeliveredIcon,
  NegativeIconSVG,
  NeutralIconSVG,
  PositiveIconSVG,
} from "src/assets/icons";
import { OrdersCount, getAllScore } from "src/controllers/dashboardController";
import MainLayout from "../mainLayout";
import { setStep } from "../mainSlice";
import { selScoreAllInvoice } from "./dashboardSlice";
import { SocketContext } from "src/api/socketContext";
import Modal from "src/components/modals/Modal";

const SCOER_DATA = [
  {
    id: 1,
    name: "Positive",
    title: "مثبت",
    color: "#5ECD5D",
    icon: <PositiveIconSVG size={24} />,
  },
  {
    id: 2,
    name: "Neutral",
    title: "معمولی",
    color: "#F89B2B",
    icon: <NeutralIconSVG size={24} />,
  },
  {
    id: 3,
    name: "Negative",
    title: "منفی",
    color: "#DE2B2B",
    icon: <NegativeIconSVG size={24} />,
  },
];
const FAKE_ORDERS = [
  {
    id: 1,
    name: "sent_to_store",
    title: "سفارش‌های دریافتی",
    icon: ({ color, size }) => <DeliveredIcon color={color} size={size} />,
    color: "#0EA5E9",
    count: 0,
  },
  {
    id: 2,
    name: "delivered",
    title: "سفارش‌های تحویل شده",
    icon: ({ color, size }) => <DeliveredIcon color={color} size={size} />,
    color: "#03B65A",
    count: 0,
  },
  {
    id: 3,
    name: "return_by_customer",
    title: "سفارش‌های مرجوع شده",
    icon: ({ color, size }) => <DeliveredIcon color={color} size={size} />,
    color: "#F29423",
    count: 0,
  },
  {
    id: 4,
    name: "rejected_by_customer",
    title: "سفارش‌های لغو شده",
    icon: ({ color, size }) => <DeliveredIcon color={color} size={size} />,
    color: "#DE2B2B",
    count: 0,
  },
];
export function Dashboard() {
  const navigate: any = useNavigate();
  const dispatch = useAppDispatch();
  const selScoreList = useSelector(selScoreAllInvoice);
  const [orderCount, setOrderCount] = useState<any>([]);

  useEffect(() => {
    dispatch(getAllScore());
    dispatch(OrdersCount(setOrderCount));
  }, []);
  const handleNavigate = (link: string) => {
    navigate(link);
  };
  const STATIC_STATUS_TEXT = {
    sent_to_store: 0,
    seen_by_store: 0,
    changed_by_store: 0,
    accepted_by_store: 0,
    rejected_by_store: 0,
    accepted_by_customer: 0,
    rejected_by_customer: 0,
    rejected_by_customer_because_other_seller: 0,
    return_by_store: 0,
    return_by_customer: 0,
    in_the_way: 0,
    delivered: 0,
  };

  orderCount?.map((item) => {
    STATIC_STATUS_TEXT[item.status_text] = item.count;
  });

  const allScore = selScoreList?.reduce(
    (sum: number, li: any) => sum + li.count,
    0
  );
  const allOrderCount = orderCount?.reduce((sum, li) => sum + li.count, 0);

  return (
    <MainLayout>
      <div>
        <div className="h-14 w-full bg-white rounded p-4 flex items-center justify-start relative">
          <CircleInfoIcon color={"#F29423"} />
          <div className="mr-3 text-sm truncate pl-24">
            برای امنیت و سهولت در خرید و فروش اطلاعات حساب کاربری را تکمیل
            نمایید.
          </div>
          <div
            className="flex items-center text-sm text-blue absolute left-4 cursor-pointer"
            onClick={() => dispatch(setStep("editProfile"))}
          >
            <div className="ml-2">تکمیل اطلاعات</div>
            <ArrowIcon />
          </div>
        </div>

        <div className="hidden">
          <div className="grid grid-cols-1 gap-3 mt-3 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 ">
            <div
              onClick={() => handleNavigate("/orders/main")}
              className="bg-white shadow-sm h-44 w-full rounded-2xl text-center flex flex-col justify-center items-center cursor-pointer hover:bg-gray-50"
            >
              <DeliveredIcon color="#0EA5E9" size={42} />
              <div className="text-sky-500 my-2"> جاری</div>
              <div className="text-base">{FAKE_ORDERS[0].count} سفارش</div>
            </div>
            <div
              onClick={() => handleNavigate("/orders/sending")}
              className="bg-white shadow-sm h-44 w-full rounded-2xl text-center flex flex-col justify-center items-center cursor-pointer hover:bg-gray-50"
            >
              <DeliveredIcon color="#03B65A" size={42} />
              <div className="text-green-500 my-2"> تحویل شده</div>
              <div className="text-base">{FAKE_ORDERS[1].count} سفارش</div>
            </div>
            <div
              onClick={() => handleNavigate("/orders/report")}
              className="bg-white shadow-sm h-44 w-full rounded-2xl text-center flex flex-col justify-center items-center cursor-pointer hover:bg-gray-50"
            >
              <DeliveredIcon color="#F29423" size={42} />
              <div className="text-amber-500 my-2"> مرجوع شده</div>
              <div className="text-base">{FAKE_ORDERS[2].count} سفارش</div>
            </div>
            <div
              onClick={() => handleNavigate("/orders/report")}
              className="bg-white shadow-sm h-44 w-full rounded-2xl text-center flex flex-col justify-center items-center cursor-pointer hover:bg-gray-50"
            >
              <DeliveredIcon color="#DE2B2B" size={42} />
              <div className="text-red-500 my-2"> لغو شده</div>
              <div className="text-base">{FAKE_ORDERS[3].count} سفارش</div>
            </div>
          </div>
        </div>
        <div className="w-full grid grid-cols-12 gap-4 p-0 mt-3">
          <div className="col-span-7 bg-white rounded-2xl px-6 pb-8">
            {FAKE_ORDERS.map((i) => {
              // const percentNumber = (i.count / allOrderCount) * 100 || 0;
              const percentNumber =
                (STATIC_STATUS_TEXT[i.name] / allOrderCount) * 100 || 0;
              return (
                <div key={i.id} className="">
                  <div className="flex justify-between py-3 mt-3">
                    <div className="flex justify-start">
                      <div>{i.icon({ color: i.color, size: 24 })}</div>
                      <div className="px-2">{i.title}</div>
                    </div>
                    <div className="flex justify-end">
                      <div className="px-1">
                        {STATIC_STATUS_TEXT[i.name] || 0}
                      </div>
                      <div>سفارش</div>
                    </div>
                  </div>
                  <div
                    className={`w-full h-2 rounded-xl`}
                    style={{
                      backgroundColor: i.color,
                      width: `${percentNumber || 0}%`,
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
          <div className="col-span-5 bg-white rounded-2xl p-6">
            <div className="font-bold">نظرات مشتریان</div>
            <div className="font-normal text-sm">
              بررسی نظرسنجی در بین مشتریان
            </div>
            <div className="h-28 flex w-full items-center">
              {allScore ? (
                SCOER_DATA.map((i) => {
                  const currentItem = selScoreList?.find(
                    (j: any) => j.scores === i.id
                  );
                  const percentNumber =
                    (currentItem.count / allScore) * 100 || 0;
                  return (
                    <div
                      key={`SCOER_DATA_ITEM_ID_${i.id}`}
                      style={{
                        width: `${percentNumber}%`,
                        backgroundColor: i.color,
                      }}
                      className="rounded-xl h-8 text-micro font-bold text-white flex justify-center items-center mx-0.5 truncate"
                    >
                      {`${percentNumber?.toFixed() || 0} %`}
                    </div>
                  );
                })
              ) : (
                <div className="text-gray-500 text-center text-sm w-full">
                  هیچ امتیازی ثبت نشده است
                </div>
              )}
            </div>
            <div className="px-6 flex justify-between items-center w-full mt-2">
              {SCOER_DATA.map((i) => {
                const currentItem = selScoreList?.find(
                  (j: any) => j.scores === i.id
                );
                return (
                  <div key={`SCOER_DATA_ICON_ID_${i.id}`} className="w-full">
                    <div className="text-sm text-gray-600 text-center font-bold">
                      {i.title}
                    </div>
                    <div className="flex items-center justify-center w-full my-3">
                      <div className="text-center text-sm mx-1">{`${
                        currentItem?.count?.toLocaleString() || "0"
                      }`}</div>
                      <div className="">{i.icon}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
