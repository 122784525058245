import React, { createContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { notify } from "src/components/notify";
import { setLoading } from "src/features/main/mainSlice";
import { updateNotificatoin } from "src/features/main/notificationSlice";
import {
  addSimilarCurrentPage,
  addSimilarProducts,
  addSimilarTotalItem,
  addSimilarTotalPage,
} from "src/features/main/siimilarSlice";
import {
  addFactorInList,
  setOrderList,
  setProductInFactor,
  updateOrderList,
} from "src/features/orders/ordersSlice";
import { setProfile } from "src/features/profile/storeSlice";
import { store } from "src/app/store";
import { setOrderActiveTab } from "src/features/orders/ordersSlice";
import Peer from "simple-peer";
import {
  addToChatList,
  setChatList,
  setChatListMeta,
  setIsTypingId,
} from "src/features/chat/chatSlice";

const SocketContext = createContext(null);
var socket: any = null;
const SERVER_URL = `${process.env.REACT_APP_SOCKET_URL}/`;

export function SocketProvider({ children }: any) {
  const dispatch = useDispatch();
  const [error, setError] = useState<string>("");
  //#region
  const [me, setMe] = useState("");
  const [call, setCall] = useState<any>({});
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [name, setName] = useState("");

  const myVoice: any = useRef();
  const userVoice: any = useRef();
  const connectionRef: any = useRef();
  //#endregion

  const getRecordPermission = () => {
    try {
      navigator.mediaDevices
        .getUserMedia({
          video: false,
          audio: true,
        })
        .then((currentStream: any) => {
          setStream(currentStream);
          if (myVoice?.current) {
            myVoice.current.srcObject = currentStream;
          }
        });
    } catch (error) {
      console.error("😏 => error 1341:", error);
    }
  };

  const init = (token: any) => {
    const params: any = { query: `token=${token}` };
    // dispatch(updateNotificatoin({ key: "order", value: 1 }));
    // dispatch(updateNotificatoin({ key: "main", value: 1 }));
    if (socket === null || socket.connected === false) {
      socket = io(SERVER_URL, params);
    }
    socket.on("connect", () => {
      console.log("😏 => socket.id:", socket.id);
      setMe(socket.id);
      sendMessage("get_invoice", 1, 10000);
    });
    // socket.on('callEnded', () => {
    //   leaveCall();
    // });
    socket.on("callUser", ({ from, name: callerName, signal }) => {
      setCall({ isReceivingCall: true, from, name: callerName, signal });
    });

    socket.on("disconnect", () => {});

    socket.on("message_delivered_ack", (res) => {
      console.log(res);
    });

    socket.on("new_invoice", function (data: any) {
      dispatch(updateNotificatoin({ key: "order", value: 1 }));
      dispatch(updateNotificatoin({ key: "main", value: 1 }));
      try {
        // console.log(store.getState());
        const { orderActiveTab } = store.getState().order || {};
        if (orderActiveTab === "sentToStore") {
          dispatch(updateOrderList(data));
        }
        dispatch(updateNotificatoin({ key: "sentToStore", value: 1 }));
      } catch (error) {
        console.error("😏 => error", error);
      }
    });
    socket.on("send_invoice", function (send_invoice: any) {
      dispatch(setLoading(false));

      if (send_invoice?.status?.code === 200) {
        dispatch(setOrderList(send_invoice?.data));
      }
    });
    socket.on("invoice_rejected_by_customer", function (res: any) {
      notify.warning("یکی ازسفارشها توسط مشتری لغو شده است");
      sendMessage("get_invoice", 1, 10000);
    });
    socket.on("logout", function (send_invoice: any) {
      notify.info("به دلیل اتصال دستگاه دیگر، ارتباط قطع شده است");
    });
    socket.on("message", (data: any) => {});

    socket.on("send_invoice_with_status_ack", (res: any) => {
      dispatch(setLoading(false));
      if (res?.status?.code === 200) {
        dispatch(setOrderList(res.data));
      }
    });

    socket.on("get_similar_products_ack", (res: any) => {
      if (res.status?.code === 200) {
        const {
          total_items,
          total_pages,
          current_page,
          products = [],
          selected_product = {},
        } = res.data || {};
        dispatch(addSimilarTotalItem(total_items));
        dispatch(addSimilarTotalPage(total_pages));
        dispatch(addSimilarCurrentPage(current_page));
        if (products.find((i: any) => i.uuid === selected_product.uuid)) {
          dispatch(addSimilarProducts([...products]));
        } else {
          dispatch(addSimilarProducts([selected_product, ...products]));
        }
      }
    });
    socket.on("change_product_of_invoice_ack", (res: any) => {
      if (res?.status.code !== 200) return;
      sendMessage("get_invoice", 1, 10000);
    });
    socket.on("typing_message", function (res: any) {
      dispatch(setIsTypingId(res.invoice_uuid));
    });
    socket.on("send_message", function (res: any) {
      if (res.status.code === 200) {
        dispatch(addToChatList(res?.data || {}));
      }
    });
    socket.on("get_messages_ack", function (res: any) {
      if (res?.status?.code === 200) {
        dispatch(setChatList(res?.data.data || []));
        dispatch(setChatListMeta(res?.data.meta || {}));
      }
    });
    socket.on("accepted_invoice_by_customer", (res: any) => {
      if (res?.status?.code === 200) {
        // dispatch(updateOrderList({ ...res.data }));
        if (res?.data?.status === 6) {
          dispatch(updateNotificatoin({ key: "acceptByUser", value: 1 }));
        } else if (res.data.status === 7) {
          dispatch(updateNotificatoin({ key: "rejectedByUser", value: 1 }));
        }
        if (store.getState().order.orderActiveTab === "acceptedByStore") {
          sendMessage("send_invoice_with_status", 1, 1000, 4);
        } else if (store.getState().order.orderActiveTab === "rejectedByUser") {
          sendMessage("send_invoice_with_status", 1, 1000, 7);
        }
      }
    });
    socket.on("reject_invoice_by_customer_ack", (res: any) => {});

    socket.on("send_minus_money_ack", (res: any) => {
      if (res?.data?.bag_money_amount !== undefined) {
        dispatch(setProfile({ bag_money: res?.data?.bag_money_amount }));
      }
      //  res?.data?.products
      // const newList = res?.data?.products.maps()
      dispatch(
        addFactorInList({ [res.invoice_uuid]: [...res?.data?.products] })
      );
      dispatch(setProductInFactor(res?.data || []));
      // sendMessage("get_invoice", 1, 10000);
    });
    socket.on("send_accept_or_reject_invoice_ack", (data: any) => {
      if (data?.status?.code !== 200) {
        return;
      }
      dispatch(updateNotificatoin({ key: "acceptedByStore", value: 1 }));

      // data?.status?.message && notify.error(data.status.message);

      // dispatch(updateNotificatoin({ key: "acceptedByStore", value: 1 }));
      sendMessage("get_invoice", 1, 10000);
    });
  };
  const disconnect = () => {
    socket.close();
  };
  const sendMessage = (...props: any) => {
    const callback = (onSuccess: any, onTimeout: any, timeout: any) => {
      // console.log("sendMessage in socket : ", onSuccess, onTimeout, timeout);
    };
    if (socket) {
      socket.emit(...props, callback);
    } else {
      console.error("اتصال سوکت برقرار نشده است");
    }
  };
  //#region

  const answerCall = () => {
    setCallAccepted(true);

    const peer = new Peer({ initiator: false, trickle: false, stream });

    peer.on("signal", (data) => {
      socket.emit("answerCall", { signal: data, to: call.from });
    });

    peer.on("stream", (currentStream) => {
      userVoice.current.srcObject = currentStream;
    });

    peer.signal(call.signal);

    connectionRef.current = peer;
  };

  const callUser = (id) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });

    peer.on("signal", (data) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name,
      });
    });

    peer.on("stream", (currentStream) => {
      userVoice.current.srcObject = currentStream;
    });

    socket.on("callAccepted", (signal) => {
      setCallAccepted(true);

      peer?.signal(signal);
    });

    connectionRef.current = peer;
  };

  const leaveCall = () => {
    try {
      setCallEnded(true);
      sendMessage("callEnded");
      connectionRef.current.destroy();
      myVoice.current.destroy();
      userVoice.current.destroy();
      // window.location.reload();
    } catch (error) {}
  };
  //#endregion
  const ws: any = {
    initSocket: init,
    disconnect,
    sendMessage,
    callback: error,
    answerCall,
    call,
    callAccepted,
    callEnded,
    leaveCall,
    myVoice,
    userVoice,
    getRecordPermission,
  };
  return <SocketContext.Provider value={ws}>{children}</SocketContext.Provider>;
}

export { SocketContext };
export default SocketProvider;
