import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { StoreState } from "../main/constants";

const initialState: StoreState = {
  profile: {
    shop_name: "",
    shop_owner_name: "",
    shop_manager_name: "",
    address: "",
    phone: "",
    mobile: "",
    lat: 35.6968923,
    lng: 51.3403079,
    bag_money: 0,
    province_name: "",
    city_name: "",
    region_name: "",
    logo: { url: "" },
  },
  storeIsOnlineStatus: 3, // 1 = open , 2 = busy , 3 = is close
};
export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = { ...state.profile, ...action.payload };
    },
    setStoreIsOnlineStatus: (state, action) => {
      state.storeIsOnlineStatus = action.payload;
    },
  },
});

export const { setProfile, setStoreIsOnlineStatus } = storeSlice.actions;

export const selectProfile = (state: RootState) => state.store.profile;
export const selectStoreIsOnlineStatus = (state: RootState) =>
  state.store.storeIsOnlineStatus;
export default storeSlice.reducer;
